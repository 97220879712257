import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay, Navigation } from "swiper";

import styles from './slider.module.css';

const Slider = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.slider}>
            <div className="container">
                <div className={styles.slider_top}>
                    <div className={styles.slider_top__title}>Лучшие направления для<br/> тренировок без ограничений</div>
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/arrow_icon.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/arrow_icon.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <Swiper
                // slidesPerView={5}
                // spaceBetween={46}
                centeredSlides={true}
                // freeMode={false}
                // grabCursor={true}
                speed={1200}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                pagination={{
                    type: "progressbar",
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    576: {
                        slidesPerView: 1,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        freeMode: true
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 46
                    },
                }}
                onSwiper={(swiper) => {
                    setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current
                    
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                    })
                }}
                modules={[FreeMode, Pagination, Autoplay, Navigation]}
                className={`swiper_gt ${styles.swiper}`}
            >
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Пилатес</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>4&nbsp;программы</div>
                        <div className={styles.slide_tag}>81&nbsp;тренировка</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>свобода и&nbsp;лёгкость движений</li>
                        <li>подтянутое тело с&nbsp;ровной осанкой и&nbsp;красивым рельефом</li>
                        <li>уменьшение объёма бёдер и&nbsp;живота</li>
                        <li>укрепление мышц тазового дна</li>
                        <li>прилив энергии и&nbsp;здоровый сон</li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Йога</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>5&nbsp;программ</div>
                        <div className={styles.slide_tag}>74&nbsp;тренировки</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>укрепление физического и&nbsp;ментального здоровья</li>
                        <li>снятие стресса</li>
                        <li>гармония, прилив сил и&nbsp;энергии</li>
                        <li>лёгкость в&nbsp;каждом движении</li>
                        <li>повышение гибкости</li>
                        <li>улучшение гормонального фона и&nbsp;работы репродуктивной системы</li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Функциональные тренировки</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>6&nbsp;программ</div>
                        <div className={styles.slide_tag}>78&nbsp;тренировок</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>хорошее самочувствие и&nbsp;прилив энергии</li>
                        <li>подтянутое тело с&nbsp;крепкими мышцами спины и&nbsp;пресса</li>
                        <li>удовольствие от&nbsp;каждого движения</li>
                        <li>привычка тренироваться</li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Mind-body (женское&nbsp;здоровье)</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>9&nbsp;программ</div>
                        <div className={styles.slide_tag}>119&nbsp;тренировок</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>снятие зажимов, напряжения и&nbsp;болезненных симптомов в&nbsp;области шеи, спины и&nbsp;ног</li>
                        <li>улучшение кровоснабжения и&nbsp;оттока лимфы</li>
                        <li>гибкость и&nbsp;лёгкость в&nbsp;теле</li>
                        <li>здоровые суставы и&nbsp;позвоночник</li>
                        <li>свободное дыхание</li>
                        <li>улучшение гормонального фона</li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Силовые тренировки</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>6&nbsp;программ</div>
                        <div className={styles.slide_tag}>106&nbsp;тренировок</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>отличная выносливость</li>
                        <li>укрепление мышечного коорсета</li>
                        <li>глубокая проработка мышц всего тела</li>
                        <li>красивый рельеф</li>
                        <li>улучшение координации и&nbsp;баланса</li>
                        <li>снижение веса</li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Кардиотренировки</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>5&nbsp;программ</div>
                        <div className={styles.slide_tag}>78&nbsp;тренировок</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>стройное и&nbsp;подтянутое тело</li>
                        <li>быстрое похудение укрепление сердечно-сосудистой и&nbsp;дыхательной систем</li>
                        <li>улучшение метаболизма</li>
                        <li>повышение выносливости</li>
                    </ul>
                </SwiperSlide>
                <SwiperSlide className={styles.swiper_slide}>
                    <div className={styles.slide_title}>Beauty-фитнес</div>
                    <div className={styles.slide_tags}>
                        <div className={styles.slide_tag}>2&nbsp;программы</div>
                        <div className={styles.slide_tag}>31&nbsp;тренировка</div>
                    </div>
                    <div className={styles.slide_res}>Результат:</div>
                    <ul>
                        <li>уменьшение признаков старение</li>
                        <li>сокращение неглубоких морщин</li>
                        <li>уменьшение отёчности</li>
                        <li>улучшение тонуса мышц лица и&nbsp;кровообращения</li>
                    </ul>
                </SwiperSlide>
            </Swiper>

        </div>
    );
};

export default Slider;