import { SubscriptionWidget, SalesWidget } from "@com.marathonium/web2-ui";
import styles from './subscribe.module.css';

const Subscribe = () => {
    return (
        <div className={`sub_anchor ${styles.subscribe}`}>
            <div className="container">
                <div className={styles.subscribe_title}>88% людей жалуются<br/> на&nbsp;постоянную усталость</div>
                <div className={styles.subscribe_subtitle}>Но вы уже&nbsp;сегодня можете избавиться от&nbsp;нехватки энергии.</div>
                <div className={styles.subscribe_choose}>Осталось только выбрать свой вариант подписки:</div>
                <div className="gymteamsale_subscribe">
                    {/* <SubscriptionWidget activeIdx={1} /> */}
                    <SalesWidget id={window.lps_settings.forms.saleswidget_id} />
                </div>
                <div className={styles.subscribe_credit}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/credit_icon.svg" alt="" />
                    <div className={styles.subscribe_credit__title}>Подписка на&nbsp;1&nbsp;год в&nbsp;рассрочку без&nbsp;переплат</div>
                    <div className={styles.subscribe_credit__text}>
                        <p>Вы можете оформить подписку на&nbsp;1&nbsp;год в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только для&nbsp;граждан&nbsp;РФ).</p>
                        <p><strong>Первый взнос через&nbsp;30&nbsp;дней</strong></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;