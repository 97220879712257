import styles from './reviews2.module.css';

const Reviews2 = () => {
    return (
        <div className={styles.reviews}>
            <div className="container">
                <div className={styles.t1}><span>Отзывы</span> доказывают<br/> эффективность<br/> тренировок GymTeam</div>
                <div className={styles.b1}>
                    <div className={styles.i1}>
                        <div className={styles.i1_title}>Кожебекова Татьяна</div>
                        <div className={styles.i1_info}>
                            <div className={styles.i1_stars}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                            </div>
                            <p>20&nbsp;февр.&nbsp;2024&nbsp;г.</p>
                        </div>
                        <div className={styles.i1_text}>Это что-то нереальное, как&nbsp;же это приятно, понятно, эффективно, тренер замечательный. Спасибо вам большое!</div>
                    </div>
                    <div className={styles.i1}>
                        <div className={styles.i1_title}>Наталья</div>
                        <div className={styles.i1_info}>
                            <div className={styles.i1_stars}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                            </div>
                            <p>2&nbsp;марта.&nbsp;2024&nbsp;г.</p>
                        </div>
                        <div className={styles.i1_text}>🔥 Восторг! впервые приобрела тренировки онлайн, не&nbsp;ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                    </div>
                    <div className={styles.i1}>
                        <div className={styles.i1_title}>Аделина Кочеткова</div>
                        <div className={styles.i1_info}>
                            <div className={styles.i1_stars}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                            </div>
                            <p>2&nbsp;марта.&nbsp;2024&nbsp;г.</p>
                        </div>
                        <div className={styles.i1_text}>Разнообразие упражнений, а&nbsp;также интерфейс приложения крайне приятны. Мне действительно нравится открывать это приложение каждый день. За&nbsp;две недели я&nbsp;сделала больше вариаций упражнений, чем за&nbsp;целый год с&nbsp;другим популярным фитнес-приложением.</div>
                    </div>
                    <div className={styles.i1}>
                        <div className={styles.i1_title}>Маргарита Егорова</div>
                        <div className={styles.i1_info}>
                            <div className={styles.i1_stars}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/star.svg" alt="" />
                            </div>
                            <p>5&nbsp;марта.&nbsp;2024&nbsp;г.</p>
                        </div>
                        <div className={styles.i1_text}>Я&nbsp;использовала и&nbsp;пробовала много приложений, но&nbsp;в&nbsp;этом есть все тренировки, которые мне нужны. Если вы&nbsp;никогда раньше не&nbsp;занимались и&nbsp;чувствуете себя неуверенно, это определенно отличный способ начать тренировки дома.</div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Reviews2;