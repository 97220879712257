import styles from './promo2.module.css';

const Promo2 = () => {
    return (
        <div className={styles.promo}>
            <div className={styles.shape_1}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_shape.svg" alt="" /></div>
            <div className={styles.shape_2}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_shape.svg" alt="" /></div>
            <div className={styles.promo_logo}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/logo_pink.svg" alt="" />
            </div>
            <div className={styles.promo_title}>Домашние тренировки<br/> экстра-класса</div>
            {/* <div className={styles.promo_text}>ТРЕНИРОВКИ ЭКСТРА-КЛАССА.<br/> <span>БЕЗЛИМИТНО</span></div> */}
        </div>
    )
}

export default Promo2;