import { useEffect, useState } from 'react';
import styles from './yourbody.module.css';

const Yourbody = () => {
    const [mobV, setMobV] = useState(false)

    useEffect(() => {
        window.innerWidth < 992 ? setMobV(true) : setMobV(false)
    }, [])

    return (
        <div className={styles.yourbody}>
            <div className="container">
                <div className={styles.yourbody_block}>
                    <div className={styles.yourbody_block__left}>
                        {!mobV
                        ? 
                        <div className={styles.yourbody_phone}></div>
                        : 
                        <div className={styles.yourbody_phone_mob}></div>
                        }
                    </div>
                    <div className={styles.yourbody_block__right}>
                        <div className={styles.yourbody_title}>Ваше тело с&nbsp;вами<br/> навсегда!</div>
                        <div className={styles.yourbody_subtitle}>Всего 10-50&nbsp;минут в&nbsp;день, чтобы&nbsp;избавиться<br/> от&nbsp;вечной усталости и&nbsp;наслаждаться каждым днём<br/> в&nbsp;здоровом и&nbsp;красивом теле.</div>
                        <ul>
                            <li><strong>Почти 40&nbsp;программ</strong> и&nbsp;сотни тренировок для&nbsp;женщин<br/> и&nbsp;мужчин</li>
                            <li>Занятия с инвентарём и без для&nbsp;любого уровня подготовки:<br/> <strong>от&nbsp;новичка до&nbsp;профи</strong></li>
                            <li><strong>Тренировки для&nbsp;похудения,</strong> поднятия тонуса, повышения<br/> выносливости, поддержания здоровья и&nbsp;фигуры</li>
                            <li><strong>Тренируйтесь где вам удобно:</strong> дома или&nbsp;в&nbsp;зале</li>
                            <li><strong>Вы найдёте безопасную программу,</strong> даже если&nbsp;у&nbsp;вас сколиоз,<br/> остеохондроз, варикозное расширение вен и&nbsp;другие<br/> заболевания</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Yourbody;