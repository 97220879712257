// import Countdown from 'react-countdown';
import styles from './block1.module.css';

const Block1 = ({renderer}) => {
    return (
        <div className={styles.block1}>
            <div className="container">
                {/* <div className={styles.top}>
                    <div className={styles.top_left}>
                        <div className={styles.t1}>Присоединись<br/> в&nbsp;течение 15&nbsp;минут<br/> <span>и&nbsp;получи 2&nbsp;ценных бонуса</span></div>
                        <div className={styles.st1}>При оплате в&nbsp;течение 15&nbsp;минут ты&nbsp;получишь доступ к&nbsp;чату<br/> с&nbsp;куратором и&nbsp;3&nbsp;полезных гайда</div>
                    </div>
                    <div className={styles.top_right}>
                        <div className={styles.timer1}>
                            <Countdown date={Date.now() + 900000} renderer={renderer} />
                        </div>
                    </div>
                </div> */}
                
                
                <div className={styles.block}>
                    <div className={styles.block_left}>
                        <div className={styles.block_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/gift.png" alt="" />
                        </div>
                        <div className={styles.block_pretitle}>Бонус! </div>
                        <div className={styles.block_title}>Получай ответы на&nbsp;свои вопросы и&nbsp;поддержку в&nbsp;течение 1&nbsp;месяца</div>
                        <div className={styles.block_text}>При покупке курса для зала в&nbsp;течение 15&nbsp;минут ты&nbsp;получишь доступ в&nbsp;телеграм-чат с&nbsp;куратором и&nbsp;другими участницами потока на&nbsp;1&nbsp;месяц</div>
                    </div>
                    <div className={styles.block_right}>
                        <div className={styles.block_phone}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/phone2.png" alt="" />
                            <div className={styles.block_phone_1}>
                                <div>1</div>
                                <div>Ответы на&nbsp;любые<br/> вопросы по&nbsp;тренировкам</div>
                            </div>
                            <div className={styles.block_phone_2}>
                                <div>2</div>
                                <div>Поддержка<br/> и&nbsp;мотивация</div>
                            </div>
                            <div className={styles.block_phone_3}>
                                <div>3</div>
                                <div>Общение<br/> с&nbsp;единомышленницами</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Block1;
