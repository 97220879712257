import styles from './subscribe.module.css';

const Subscribe = () => {
    return (
        <div className={styles.subscribe}>
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.t1}>Полный доступ<br/> от&nbsp;790&nbsp;₽ в&nbsp;месяц</div>
                    {/* <div className={styles.st1}><p>2&nbsp;475&nbsp;₽</p> <span>Скидка&nbsp;60%</span></div> */}
                    {/* <div className={styles.price}>990&nbsp;₽</div> */}
                    <div className={styles.info}>
                        <ul>
                            <li><b>безлимитные тренировки</b> экстра-класса</li>
                            <li>79&nbsp;программ</li>
                            <li>&gt; 3000 тренировок</li>
                        </ul>
                        <ul>
                            <li>новые программы каждую неделю</li>
                            <li>приложение для Android, iOS и&nbsp;Smart&nbsp;TV</li>
                        </ul>
                    </div>
                    <a href="/l/price-new/" target='_blank' className={styles.btn} onClick={()=>{window.yaCounter70253455.reachGoal('do-it_4_оформить_подписку'); return true;}}>оформить подписку</a>
                </div>
            </div>
        </div>
    )
}

export default Subscribe;