import { motion } from "framer-motion";
import styles from './promo.module.css';

const Promo = ({scroll, scrollg}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 0.6}}>
                        <div className={styles.promo_title}>ЭНЕРГИЯ ВНУТРИ</div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, x: -1400 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 0.6}}>
                    <div className={styles.promo_subtitle}>Тренировки экстра-класса.<br/> Безлимитно.</div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, x: -1600 }} animate={{ opacity: 1, x: 0 }} transition={{duration: 0.6}}>
                        <div className={styles.promo_text}><strong>Я создала GymTeam с&nbsp;лучшими тренерами,</strong> чтобы&nbsp;у&nbsp;вас<br/> была возможность открывать для&nbsp;себя что-то&nbsp;новое,<br/> выбирая направления под&nbsp;свои цели и&nbsp;уровень<br/> подготовки. Возможность улучшать результаты<br/> и&nbsp;не&nbsp;терять мотивацию.</div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: 1000 }} animate={{ opacity: 1, y: 0 }} transition={{duration: 0.6}}>
                        <div className={styles.promo_btns}>
                            <div className={styles.promo_btn} onClick={scroll}>ОФОРМИТЬ ПОДПИСКУ</div>
                            <div className={styles.promo_btn__more} onClick={scrollg}>БОЛЬШЕ О GYMTEAM</div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Promo;