import { useEffect, useState } from 'react';
import styles from './header.module.css';

const Header = ({scrollh, isActive, setIsActive}) => {

  const scroll = (name) => {
    const section = document.querySelector( name ).offsetTop;
    window.scrollTo({top: section - 60, behavior: "smooth"});
    setIsActive(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsActive(false)
    }
    if (isActive) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isActive])

    return (
        <header className={`${isActive && styles.active}`}>
          <div className="container">
            <div className={styles.header}>
                <div className={styles.header__logo}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/gtv_logo.svg" alt="" />
                </div>
                <div className={styles.header__menu}>
                    <div className={styles.header__menu_item} onClick={() => scroll('.joinus_anchor')}>Преимущества</div>
                    <div className={styles.header__menu_item} onClick={() => scroll('.programs_anchor')}>Программы</div>
                    <div className={styles.header__menu_item} onClick={() => scroll('.target_block')}>Обзор</div>
                    <div className={styles.header__menu_item} onClick={() => scroll('.faq_anchor')}>Вопросы-ответы</div>
                    <div className={`${styles.hidden_item} ${styles.header__menu_item} ${styles.header__login_reg}`} onClick={scrollh}>Оформить подписку</div>
                </div>
                <div className={styles.header__login}>
                  <div className={styles.header__login_reg} onClick={scrollh}>Оформить подписку</div>
                </div>
                <div className={styles.header_btn} onClick={() => setIsActive(!isActive)}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
            </div>
          </div>
        </header>
    );
};

export default Header;