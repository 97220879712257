import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                
                <div className={styles.author_block}>
                    <div className={styles.author_info}>
                        <div className={styles.author_title}>Доверь свои тренировки<br/> чемпионке фитнес-бикини<br/> Кате Усмановой</div>
                        {/* <div className={styles.author_imgmob}><img src="https://gymteam.kinescopecdn.net/img/l/2023/decembergym/author_bg_mob.jpg" alt="" /></div> */}
                        <ul className={styles.author_list}>
                            <li><b>Создатель GymTeam</b>&nbsp;— платформы с&nbsp;безлимитными тренировками экстра-класса от&nbsp;практикующих экспертов</li>
                            {/* <li><b>Чемпионка России</b> и&nbsp;мира по&nbsp;жиму лёжа</li> */}
                            <li><b>Вице-чемпионка мира</b> и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                            <li><b>Профессиональный фитнес-тренер</b> с&nbsp;опытом более&nbsp;15&nbsp;лет</li>
                            <li>Мама 2-х&nbsp;детей. Всего за&nbsp;100&nbsp;дней после первых родов <b>похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю форму</b></li>
                            <li><b>Автор первых в&nbsp;России</b> масштабных марафонов стройности</li>
                        </ul>
                    </div>
                    <div className={styles.author_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/auth_1.png" alt="" />
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/auth_2.png" alt="" />
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/auth_3.png" alt="" />
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/auth_4.png" alt="" />
                    </div>
                </div>
                <div className={styles.author_preims}>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>>8&nbsp;ЛЕТ</div>
                                <div className={styles.author_item_text}>развиваем индустрию онлайн-фитнеса</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>>13</div>
                                <div className={styles.author_item_text}>авторских программ для&nbsp;занятий дома и&nbsp;в&nbsp;зале</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>9 из&nbsp;10</div>
                                <div className={styles.author_item_text}>участниц получили желаемый результат</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>>4&nbsp;000</div>
                                <div className={styles.author_item_text}>учениц со&nbsp;всего мира тренируются с&nbsp;нами каждый день</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>>285&nbsp;000</div>
                                <div className={styles.author_item_text}>историй успеха, которые начались с&nbsp;мечты о&nbsp;шикарном теле</div>
                            </div>
                        </div>
                {/* <div className={styles.author_imgs}>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_1.jpg" alt="" />
                        <p>2015</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_2.jpg" alt="" />
                        <p>2016</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_3.jpg" alt="" />
                        <p>2017</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_4.jpg" alt="" />
                        <p>2018</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_5.jpg" alt="" />
                        <p>2019</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_6.jpg" alt="" />
                        <p>2020</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_7.jpg" alt="" />
                        <p>2021</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_8.jpg" alt="" />
                        <p>2022</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_9.jpg" alt="" />
                        <p>2023</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default Author;