import { useEffect, useRef, useState } from 'react';
import styles from './faq.module.css';

const Faq = ({scroll}) => {
  const faqs = [
    {question: 'Тренировки проходят в какое-то определенное время?', answer: <div><p>Нет, все тренировки заранее записаны. Удобное время для занятия вы выбираете сами.</p></div>},
    {question: 'Я никогда не занималась. Подойдет ли мне GymTeam?', answer: <div><p>Да! Все тренировки проходят в формате «повторяй за мной» – тренер подробно объясняет каждое упражнение, а затем выполняет его вместе с вами. Во всех программах нагрузка будет увеличиваться постепенно.</p></div>},
    {question: 'Какой инвентарь нужен для тренировок?', answer: <div><p>Если у вас есть коврик, этого достаточно для большинства тренировок. Если у вас есть дополнительное оборудование, вы также найдете подходящие тренировки.</p></div>},


    {question: 'Можно ли вернуть деньги, если не смогу или передумаю участвовать?', answer: <div><p>Да, вы можете вернуть деньги обратившись в поддержку <a href="mailto:support@gymteam.ru" target='_blank'>support@gymteam.ru</a></p></div>}
];
    
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }

    const ref = useRef(null)

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    function scrollHandler() {
        const scolling = window.scrollY;
        const block1 = document.querySelector('.new__b1').offsetHeight;
        const block2 = document.querySelector('.new__b2').offsetHeight;
        const block3 = document.querySelector('.new__b3').offsetHeight;
        const block4 = document.querySelector('.new__b4').offsetHeight;
        const block5 = document.querySelector('.new__b5').offsetHeight;
        const block6 = document.querySelector('.new__b6').offsetHeight;
        const block7 = document.querySelector('.new__b7').offsetHeight;
        const fullSize = block1 + block2 + block3 + block4 + block5 + block6 + block7;
        const res = scolling - fullSize;
        if(Math.sign(res) == -1) {
            ref.current.style.transform = `translateY(${Math.abs(res)/6}px)`
        } else {
            ref.current.style.transform = `translateY(-${Math.abs(res)/5}px)`
        }
    }

    return(
        <div className={styles.faq}>
          <div className="container">
            <div className={styles.faq_block}>
              <div className={styles.faq_left}>
                <div className={styles.faq_titles}>
                  <div className={styles.faq_title}>ВОПРОСЫ И&nbsp;ОТВЕТЫ</div>
                </div>
              </div>
              <div className={styles.faq_right}>
                <div className={styles.accordion}>
                  {faqs.map((faq, index) => (
                    <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                      <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                      <p className={styles.answer}>{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Faq;