import './App.css';
import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import { RedirectPrograms } from '../components/redirect/RedirectProgram';
import Aboutyou from './components/Aboutyou';
import Contraindications from './components/Contraindications';
import Faq from './components/Faq';
import Healthymama from './components/Healthymama';
import Howcover from './components/Howcover';
import Mainnote from './components/Mainnote';
import Makeyouself from './components/Makeyouself';
import Nowyoumama from './components/Nowyoumama';
import Promoblock from './components/Promo';
import Readyform from './components/Readyform';
import Threecomps from './components/Threecomps';
import Wegift from './components/Wegift';
import Whaitmore from './components/Whaitmore';
import Whobirth from './components/Whobirth';
import Platform from '../components/platform/Platform';
import Footer from '../components/Footer';

export const L2022_11_Afterpregnancy = () => {

  const scroll = () => {
    const section = document.querySelector( '.readyform' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  let nextMidnight = new Date();
  nextMidnight.setHours(24, 0, 0, 0);
  const now = new Date();
  const date = nextMidnight.getTime() - now.getTime();
    
  const renderer = ({hours, minutes, seconds, completed}) => {
    return (
        <div className='countdown'>
            <div>{hours} <span>часа</span></div>
            <div>{minutes} <span>минут</span></div>
            <div>{seconds} <span>секунд</span></div>
        </div>
    );
  };

  return (
    <>
      <RedirectPrograms />
      <DocumentTitle value='Восстановление после родов — курс Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='Курс «Восстановление после родов» в GymTeam — программа Екатерины Усмановой для молодых мам. Включает 30 специальных тренировок и рекомендации по питанию — всё, чтобы вернуться в форму после родов.' />
        <div className='afterpregnancy'>
          <Promoblock date={date} renderer={renderer} scroll={scroll} />
          <Aboutyou />
          <Howcover scroll={scroll} />
          <Whobirth scroll={scroll} />
          <Nowyoumama />
          <Mainnote />
          <Contraindications />
          <Makeyouself />
          <Threecomps />
          <Healthymama scroll={scroll} />
          <Platform />
          <Whaitmore />
          <Wegift />
          <Readyform date={date} renderer={renderer} />
          <Faq />
          <Footer />
        </div>
      </>
  );
}