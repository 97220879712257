import { useEffect, useState } from "react";
import { Quiz } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import StartScreen from "./components/StartScreen";
import { AuthWidget } from "@com.marathonium/web2-ui";
import "./style.css";
import styles from './style.module.css';
import Paywall from "./components/Paywall";

function getInitScreenIndex() {
  const query = new URLSearchParams(window.location.search);
  if (Number.isInteger(parseInt(query.get("q")))) {
    return 2;
  }

  return 1;
}

export const L2024_08_QuizTest3 = () => {
  const [widget] = useInstance();

  const [startScreen, setStartScreen] = useState(true);
  const [fatAnswer, setFatAnswer] = useState(null);

  const [activeScreen, setActiveScreen] = useState(getInitScreenIndex());
  // const [activeScreen, setActiveScreen] = useState(5);

  const [weight, setWeight] = useState(null)
  const [height, setHeight] = useState(null)
  const [imt, setImt] = useState(null)
  const [age, setAge] = useState(null)

  const [pushups, setPushups] = useState(null)
  const [squats, setSquats] = useState(null)
  const [climbingStairs, setClimbingStairs] = useState(null)
  const [phys, setPhys] = useState(null)

  const [qmaintarget, setQmaintarget] = useState(null)
  const [qhowmany, setQhowmany] = useState(null)
  const [qhowlong, setQhowlong] = useState(null)
  const [qtrainings, setQtrainings] = useState(null)

  const [showSummary, setShowSummary] = useState(false)
  
  for(let i = 0; i < 3; i++) {
    document.documentElement.style.cssText += `--number${i+1}: ${(Math.random() * 2) + 3}s;`;
  }

  useEffect(()=>{
    setPhys(pushups + squats + climbingStairs)
  }, [pushups, squats, climbingStairs])

  useEffect(()=>{
    setImt((weight / Math.pow(height/100, 2)).toFixed(1))
  }, [weight, height])

  useEffect(()=>{
    if(activeScreen == 7) {
      setTimeout(()=>{
        document.querySelector('.vbcb').click()
        document.querySelector('.vbcb2').click()
      }, 1000)
    }
  }, [activeScreen])

  useEffect(()=>{
    const query = new URLSearchParams(window.location.search);
    
    setTimeout(()=>{
      getAnswers()
    }, 400)

    if(parseInt(query.get("q")) == 11) {
      setShowSummary(true)
    }

    // window.addEventListener('click', function(){
    //   console.log(document.querySelectorAll('.quiztest_2024_auth > div > div > div').length)
    //   if(document.querySelectorAll('.quiztest_2024_auth > div > div > div').length < 3) {
    //     document.querySelector('.quiztest_2024_auth').classList.remove('simple')
    //   } else {
    //     document.querySelector('.quiztest_2024_auth').classList.add('simple')
    //   }
    // })

    const targetNode = document.querySelector(".quiztest_2024_auth");

    if(targetNode) {
    
const config = { attributes: true, childList: true, subtree: true };


const callback = (mutationList, observer) => {
  for (const mutation of mutationList) {
    if (mutation.type === "childList") {
      // console.log(document.querySelectorAll('.quiztest_2024_auth > div > div > div').length)
      if(document.querySelectorAll('.quiztest_2024_auth > div > div > div').length < 3) {
        document.querySelector('.quiztest_2024_auth').classList.remove('simple')
      } else {
        document.querySelector('.quiztest_2024_auth').classList.add('simple')
      }
    } else if (mutation.type === "attributes") {
      // console.log(document.querySelectorAll('.quiztest_2024_auth > div > div > div').length)
    }
  }
};

const observer = new MutationObserver(callback);

observer.observe(targetNode, config);

// observer.disconnect();
    }

  }, [])

  const getAnswers = () => {
    setHeight(widget.getSelectedAnswerFor('height').value)
            
            setWeight(widget.getSelectedAnswerFor('weight-current').value)

            widget.getSelectedAnswerFor('age').value == 1 ? setAge('18-29 лет')
            : widget.getSelectedAnswerFor('age').value == 2 ? setAge('30-39 лет')
            : widget.getSelectedAnswerFor('age').value == 3 ? setAge('40-49 лет')
            : setAge('> 50 лет')

            widget.getSelectedAnswerFor('main-target').value == 1 ? setQmaintarget('Сбросить 1-10 кг навсегда')
              : widget.getSelectedAnswerFor('main-target').value == 2 ? setQmaintarget('Сбросить 11-20 кг навсегда')
              : widget.getSelectedAnswerFor('main-target').value == 3 ? setQmaintarget('Сбросить более 20 кг навсегда')
              : widget.getSelectedAnswerFor('main-target').value == 4 ? setQmaintarget('Поддерживать вес и быть в форме')
              : setQmaintarget('Пока не знаю')

              setFatAnswer(widget.getSelectedAnswerFor('body-fat').value)
              
                widget.getSelectedAnswerFor('push-ups').value == 1 || widget.getSelectedAnswerFor('push-ups').value == 4 ?
                setPushups(0)
                : widget.getSelectedAnswerFor('push-ups').value == 2 ?
                setPushups(1)
                :
                setPushups(2)
              
                  widget.getSelectedAnswerFor('squats').value == 1 || widget.getSelectedAnswerFor('squats').value == 4 ?
                  setSquats(0)
                  : widget.getSelectedAnswerFor('squats').value == 2 ?
                  setSquats(1)
                  :
                  setSquats(2)
            

                    widget.getSelectedAnswerFor('climbing-stairs').value == 1 ?
                    setClimbingStairs(0)
                    : widget.getSelectedAnswerFor('climbing-stairs').value == 2 ?
                    setClimbingStairs(1)
                    :
                    setClimbingStairs(2)


              widget.getSelectedAnswerFor('how-many').value == 1 ? setQhowmany('2-3 раза')
              : widget.getSelectedAnswerFor('how-many').value == 2 ? setQhowmany('4-6 раз')
              : widget.getSelectedAnswerFor('how-many').value == 3 ? setQhowmany('Каждый день')
              : setQhowmany('По-разному')
            
            
              widget.getSelectedAnswerFor('how-long').value == 1 ? setQhowlong('Короткие, до 20 минут')
              : widget.getSelectedAnswerFor('how-long').value == 2 ? setQhowlong('Средней продолжительности, 20-40 минут')
              : widget.getSelectedAnswerFor('how-long').value == 3 ? setQhowlong('Длительные, от 40 минут')
              : setQhowlong('Разнообразные')
            
            
              widget.getSelectedAnswerFor('trainings').value == 1 ? setQtrainings('Функциональные')
              : widget.getSelectedAnswerFor('trainings').value == 2 ? setQtrainings('Интенсивные')
              : widget.getSelectedAnswerFor('trainings').value == 3 ? setQtrainings('Расслабляющие')
              : widget.getSelectedAnswerFor('trainings').value == 4 ? setQtrainings('Йога')
              : widget.getSelectedAnswerFor('trainings').value == 5 ? setQtrainings('Силовые')
              : setQtrainings('Разнообразные')
  }

  return (
    <div className="quiztest_2024_body">
      <div className={`quiztest_2024 ${!startScreen && 'quiztest_2024_active'}`}>
        {activeScreen == 1 && <StartScreen setStartScreen={setStartScreen} setActiveScreen={setActiveScreen} /> }
        
        {activeScreen == 2 &&
          <div className='quiztest_2024'>
          <Quiz
            reloadable
            hook={widget}
            configUrl="https://gymteam.fit/quiz/001.json"
            customResultPage={() => null}
            // onLastComplete={(res) => {
            //   console.log("ответ на последний вопрос:", res);
            // }}
            onFinish={(res) => {
              console.log("квиз завершён:", res);
              setActiveScreen(3)
            }}
            onStepComplete={(res) => {
              // if(res.question.code == 'push-ups') {
              //   res.answer.value == 1 || res.answer.value == 4 ?
              //   setPushups(0)
              //   : res.answer.value == 2 ?
              //   setPushups(1)
              //   :
              //   setPushups(2)
              // }
              // if(res.question.code == 'squats') {
              //   res.answer.value == 1 || res.answer.value == 4 ?
              //   setSquats(0)
              //   : res.answer.value == 2 ?
              //   setSquats(1)
              //   :
              //   setSquats(2)
              // }
              // if(res.question.code == 'climbing-stairs') {
              //   res.answer.value == 1 ?
              //   setClimbingStairs(0)
              //   : res.answer.value == 2 ?
              //   setClimbingStairs(1)
              //   :
              //   setClimbingStairs(2)
              // }
              
              // if(res.question.code == 'body-fat') {
              //   setFatAnswer(res.answer.value)
              // }
              // if(res.question.code == 'main-target') {
              //   res.answer.value == 1 ? setQmaintarget('Сбросить 1-10 кг навсегда')
              //   : res.answer.value == 2 ? setQmaintarget('Сбросить 11-20 кг навсегда')
              //   : res.answer.value == 3 ? setQmaintarget('Сбросить более 20 кг навсегда')
              //   : res.answer.value == 4 ? setQmaintarget('Поддерживать вес и быть в форме')
              //   : setQmaintarget('Пока не знаю')
              // }
              // if(res.question.code == 'how-many') {
              //   res.answer.value == 1 ? setQhowmany('2-3 раза')
              //   : res.answer.value == 2 ? setQhowmany('4-6 раз')
              //   : res.answer.value == 3 ? setQhowmany('Каждый день')
              //   : setQhowmany('По-разному')
              // }
              // if(res.question.code == 'how-long') {
              //   res.answer.value == 1 ? setQhowlong('Короткие, до 20 минут')
              //   : res.answer.value == 2 ? setQhowlong('Средней продолжительности, 20-40 минут')
              //   : res.answer.value == 3 ? setQhowlong('Длительные, от 40 минут')
              //   : setQhowlong('Разнообразные')
              // }
              // if(res.question.code == 'trainings') {
              //   res.answer.value == 1 ? setQtrainings('Функциональные')
              //   : res.answer.value == 2 ? setQtrainings('Интенсивные')
              //   : res.answer.value == 3 ? setQtrainings('Расслабляющие')
              //   : res.answer.value == 4 ? setQtrainings('Йога')
              //   : res.answer.value == 5 ? setQtrainings('Силовые')
              //   : setQtrainings('Разнообразные')
              // }
              // if(res.question.code == 'weight-current') {
              //   setWeight(res.answer.value)
              // }
              // if(res.question.code == 'height') {
              //   setHeight(res.answer.value)
              // }
              // if(res.question.code == 'age') {
              //   res.answer.value == 1 ? setAge('18-29 лет')
              //   : res.answer.value == 2 ? setAge('30-39 лет')
              //   : res.answer.value == 3 ? setAge('40-49 лет')
              //   : setAge('> 50 лет')
              // }
              
              
              
              
              

              if(Array.isArray(res.answer)) {
                for(let i = 0; i<res.answer.length; i++) {
                  window.gtag('event', `Q${res.question.value}`, {
                    'question': res.question.code,
                    'answer': res.answer[i].code,
                  });
                }
              } else {
                window.gtag('event', `Q${res.question.value}`, {
                  'question': res.question.code,
                  'answer': res.answer.code,
                });
              }

              console.log("данные выполненного шага:", res);
              
            }}
          />

          {/* <div onClick={() => {document.querySelector('.questionBody button').click()}}>Далее</div> */}
           
            <div className={`${styles.sum} ${showSummary && styles.sum_active}`}>
              <div className={styles.sum_all}>
              <div className={styles.sum_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" /></div>
              <div className={styles.sum_title}>Ваш профиль<br/> физического состояния</div>
              <div className={styles.sum_b1}>
                <div className={styles.sum_b1_title}>Индекс массы тела (ИМТ)</div>
                <div className={styles.sum_b1_numbers}>
                  <span>15</span>
                  <span>40</span>
                </div>
                <div className={styles.sum_b1_line}>
                  <span style={{marginLeft: `calc(3.9% * ${imt < 15 ? 0 : imt > 40 ? 40 - 15 : imt - 15})`}}>{imt}</span>
                </div>
                <div className={styles.sum_b1_sub}>
                  <span>Норма</span>
                </div>
                <div className={styles.sum_b1_info}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/i.svg" alt="" />
                  <p><b>{imt}</b>&nbsp;&mdash; {imt < 16 ? 'дефицит массы тела' : imt < 18.5 ? 'недостаточная масса тела' : imt < 25 ? 'нормальная масса тела' : imt < 30 ? 'предожирение' : imt < 35 ? 'ожирение 1-й степени' : imt < 40 ? 'ожирение 2-й степени' : 'ожирение 3-й степени'}</p>
                </div>
              </div>
              <div className={styles.sum_b2}>
                <div className={styles.sum_b2_item}>
                  <p>Фитнес-цель</p>
                  <span>{qmaintarget}</span>
                </div>
                <div className={styles.sum_b2_item}>
                  <p>Процент жира в&nbsp;организме</p>
                  <span>{fatAnswer == 1 ? '10-19%' : fatAnswer == 2 ? '20-29%' : fatAnswer == 3 ? '30-39%' : '>40%'}</span>
                </div>
                <div className={styles.sum_b2_item}>
                  <p>Возраст</p>
                  <span>{age}</span>
                </div>
              </div>
              <div className={styles.sum_b3}>Зная вашу цель и&nbsp;ИМТ, мы&nbsp;сможем подобрать правильную программу тренировок</div>
              <div className={styles.sum_btn} onClick={()=>setShowSummary(false)}>Далее</div>
              </div>
            </div>
          
          </div>
}
{activeScreen == 3 && 
            <div className={styles.phys}>
              <div className={styles.phys_logo}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
              </div>
              <div className={styles.phys_title}>Ваш уровень физической подготовки</div>
              <div className={styles.phys_b1}>
                <div className={styles.phys_b1_title}>{phys < 2 ? 'начальный' : phys > 4 ? 'нормальный' : 'средний'}</div>
                <div className={styles.phys_b1_icons}>
                  {phys < 2 ? 
                  <>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star2.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  </>
                  : phys > 4 ? 
                  <>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star2.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star2.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star2.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  </>
                  : 
                  <>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star2.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star2.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/star1.svg" alt="" />
                  </>
                  }
                </div>
              </div>
              <div className={styles.phys_b2}>
                <p>Мы&nbsp;подберём программу <b>с&nbsp;постепенно возрастающей нагрузкой.</b></p>
                <p>Вы&nbsp;<b>точно не&nbsp;сдадитесь</b> в&nbsp;первый день, мы&nbsp;сделали <b>мягкий процесс привыкания</b> к&nbsp;тренировкам</p>
              </div>
              <div className={styles.phys_btn} onClick={()=>setActiveScreen(4)}>Получить план тренировок</div>
            </div>
          }
{activeScreen == 4 && 
  <div className={styles.prep}>
  <div className={styles.prep_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" /></div>
  <div className={styles.prep_title}>
    <p>Готовим ваш персональный<br/> план тренировок</p>
    <p>Ваш персональный план тренировок готов</p>
  </div>
  <div className={styles.prep_imgs}>
      <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/prep1_2.png" alt="" />
      <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/prep2.png" alt="" />
      <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/prep3.png" alt="" />
  </div>
  <div className={styles.prep_items}>
  <div className={`${styles.prep_item} ${styles.prep_item1}`}>
      <div className={styles.prep_item_info}>
          <div className={styles.prep_item_title}>Предпочтения...</div>
          <div className={styles.prep_item_count}><span></span>%</div>
      </div>
      <div className={styles.prep_item_line}>
      </div>
  </div>
  <div className={`${styles.prep_item} ${styles.prep_item2}`}>
      <div className={styles.prep_item_info}>
          <div className={styles.prep_item_title}>Анализ тела...</div>
          <div className={styles.prep_item_count}><span></span>%</div>
      </div>
      <div className={styles.prep_item_line}>
      </div>
  </div>
  <div className={`${styles.prep_item} ${styles.prep_item3}`}>
      <div className={styles.prep_item_info}>
          <div className={styles.prep_item_title}>Уровень активности...</div>
          <div className={styles.prep_item_count}><span></span>%</div>
      </div>
      <div className={styles.prep_item_line}>
      </div>
  </div>
  </div>
  <div className={styles.prep_btn} onClick={()=>setActiveScreen(5)}>Продолжить</div>
  </div>
}
{activeScreen == 5 &&
          <div className={styles.aha}>
            <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
            <div className={styles.aha_t1}>Ваш персональный план<br/> тренировок готов!</div>
            <div className={styles.aha_b1}>
              <div className={styles.aha_b1_item}>
                <div className={styles.aha_b1_item_t1}>Сейчас</div>
                <img src={fatAnswer == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 2 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : fatAnswer == 3 ? 'https://gymteam.fit/quiz/pics/w-3.jpg' : 'https://gymteam.fit/quiz/pics/w-4.jpg'} alt="" />
                <div className={styles.aha_b1_item_t2}>Процент жира <span>{fatAnswer == 1 ? '10-19%' : fatAnswer == 2 ? '20-29%' : fatAnswer == 3 ? '30-39%' : '>40%'}</span></div>
              </div>
              <div className={styles.aha_b1_item}>
                <div className={styles.aha_b1_item_t1}>После программы</div>
                <img src={fatAnswer == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 2 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 3 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : 'https://gymteam.fit/quiz/pics/w-2.jpg'} alt="" />
                <div className={styles.aha_b1_item_t2}>Процент жира <span>{fatAnswer == 1 ? '10-19%' : fatAnswer == 2 ? '10-19%' : fatAnswer == 3 ? '20-29%' : '20-29%'}</span></div>
              </div>
            </div>
            <div className={styles.aha_b2}>
              <div className={styles.aha_b2_title}>Ваш график снижения веса</div>
              <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/graph3.jpg" alt="" />
            </div>
            <div className={styles.aha_b3}>
              <div className={styles.aha_b3_item}>
                <div className={styles.aha_b3_item_q}>основная цель</div>
                <div className={styles.aha_b3_item_a}>{qmaintarget}</div>
              </div>
              <div className={styles.aha_b3_item}>
                <div className={styles.aha_b3_item_q}>уровень<br/> подготовки</div>
                <div className={styles.aha_b3_item_a}>{phys < 2 ? 'Начальный' : phys > 4 ? 'Нормальный' : 'Средний'}</div>
              </div>
              <div className={styles.aha_b3_item}>
                <div className={styles.aha_b3_item_q}>количество тренировок<br/> в&nbsp;неделю</div>
                <div className={styles.aha_b3_item_a}>{qhowmany}</div>
              </div>
              <div className={styles.aha_b3_item}>
                <div className={styles.aha_b3_item_q}>продолжительность<br/> тренировки</div>
                <div className={styles.aha_b3_item_a}>{qhowlong}</div>
              </div>
              <div className={styles.aha_b3_item}>
                <div className={styles.aha_b3_item_q}>предпочтения<br/> по&nbsp;тренировкам</div>
                <div className={styles.aha_b3_item_a}>{qtrainings}</div>
              </div>
            </div>
            <div className={styles.aha_btn} onClick={()=>{setActiveScreen(6); window.yaCounter70253455.reachGoal('quiz-to-the-registration-form'); return true;}}>Получить программу</div>
          </div> 
 }
{activeScreen == 6 && 
              <div className={styles.email}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
                <div className={styles.email_t1}>Укажите почту, пожалуйста, чтобы получить персональный план тренировок</div>
                <div className={styles.email_t2}>Также мы&nbsp;создадим вам аккаунт в&nbsp;приложении&nbsp;&mdash; вы&nbsp;сможете войти по&nbsp;этой почте</div>
                <div className="quiztest_2024_auth">
                  <AuthWidget onSuccess={()=>{document.querySelector('.quiztest_2024_auth').classList.add('hide');setActiveScreen(7);window.yaCounter70253455.reachGoal('quiz-registration-success'); return true;}} />
                </div>
                
                {/* <div onClick={()=>setActiveScreen(7)}>ТУТ ДОЛЖНА БЫТЬ ФОРМА АВТОРИЗАЦИИ ПЕРЕХОД НА СЛЕД ЭКРАН</div> */}
              </div> 
            }
            {activeScreen == 7 && 
              <Paywall fatAnswer={fatAnswer} qmaintarget={qmaintarget} qhowlong={qhowlong} qhowmany={qhowmany} qtrainings={qtrainings} phys={phys} />
            }
        
      </div>
    </div>
  );
};
