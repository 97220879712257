import styles from './programs.module.css';

const Programs = () => {
    return (
        <div className={`programs_anchor ${styles.programs}`}>
            <div className={styles.programs_title}>Получите доступ<br/> к&nbsp;37&nbsp;программам уже&nbsp;сегодня</div>
            <div className={styles.programs_subtitle}>Пробуйте новое&nbsp;&mdash; не&nbsp;теряйте мотивацию</div>
            <div className={`drag ${styles.programs_row + ' ' + styles.programs_row1}`}>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program1.jpg" alt="" />
                    <p>Укрепляем тазовое дно</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_2.jpg" alt="" />
                    <p>Восстановительная гимнастика</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_3.jpg" alt="" />
                    <p>Пилатес с&nbsp;оборудованием</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_4.jpg" alt="" />
                    <p>Силовые тренировки для&nbsp;начинающих</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_5.jpg" alt="" />
                    <p>Здоровая спина</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_6.jpg" alt="" />
                    <p>Тренировки по&nbsp;йоге</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_7.jpg" alt="" />
                    <p>Силовые тренировки</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_8.jpg" alt="" />
                    <p>Миофасциальное расслабление</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_9.jpg" alt="" />
                    <p>Здоровые стопы</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_10.jpg" alt="" />
                    <p>Power Yoga</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_11.jpg" alt="" />
                    <p>Офисные комплексы упражнений</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_12.jpg" alt="" />
                    <p>Кроссфит</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_13.jpg" alt="" />
                    <p>Йога для&nbsp;начинающих</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_14.jpg" alt="" />
                    <p>Тренировки по&nbsp;барре</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_15.jpg" alt="" />
                    <p>Интервальный тренинг HIIT</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_16.jpg" alt="" />
                    <p>Фейсфитнес на&nbsp;каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_17.jpg" alt="" />
                    <p>Короткие кроссфит-тренировки</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_18.jpg" alt="" />
                    <p>Функциональные тренировки</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_19.jpg" alt="" />
                    <p>Пилатес. Продвинутый уровень</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program1.jpg" alt="" />
                    <p>Укрепляем тазовое дно</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_2.jpg" alt="" />
                    <p>Восстановительная гимнастика</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_3.jpg" alt="" />
                    <p>Пилатес с&nbsp;оборудованием</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_4.jpg" alt="" />
                    <p>Силовые тренировки для&nbsp;начинающих</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_5.jpg" alt="" />
                    <p>Здоровая спина</p>
                </div>
            </div>
            <div className={`drag ${styles.programs_row + ' ' + styles.programs_row2}`}>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_20.jpg" alt="" />
                    <p>Функциональные тренировки каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_21.jpg" alt="" />
                    <p>Силовые тренировки для&nbsp;продвинутых</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_22.jpg" alt="" />
                    <p>HIIT на&nbsp;каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_23.jpg" alt="" />
                    <p>Фейсфитнес</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_24.jpg" alt="" />
                    <p>Йога на&nbsp;каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_25.jpg" alt="" />
                    <p>Основы пилатеса</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_26.jpg" alt="" />
                    <p>Фитнес-тренировки барре</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_27.jpg" alt="" />
                    <p>Основы Тайцзицюань (Тай-чи)</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_28.jpg" alt="" />
                    <p>Растяжка в&nbsp;удовольствие</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_29.jpg" alt="" />
                    <p>Пилатес на&nbsp;каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_30.jpg" alt="" />
                    <p>Утренняя зарядка</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_31.jpg" alt="" />
                    <p>Стретчинг-растяжка</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_32.jpg" alt="" />
                    <p>Табата на&nbsp;каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_33.jpg" alt="" />
                    <p>Высокоинтенсивные тренировки табата</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_34.jpg" alt="" />
                    <p>Силовые</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_35.jpg" alt="" />
                    <p>Фитнес-бокс</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_36.jpg" alt="" />
                    <p>Кундалинни-йога</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_37.jpg" alt="" />
                    <p>Растяжка</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_20.jpg" alt="" />
                    <p>Функциональные тренировки каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_21.jpg" alt="" />
                    <p>Силовые тренировки для&nbsp;продвинутых</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_22.jpg" alt="" />
                    <p>HIIT на&nbsp;каждый день</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_23.jpg" alt="" />
                    <p>Фейсфитнес</p>
                </div>
                <div className={styles.programs_item}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_24.jpg" alt="" />
                    <p>Йога на&nbsp;каждый день</p>
                </div>
            </div>
        </div>
    );
};

export default Programs;