import { useEffect, useRef, useState, lazy } from 'react';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import { useDisclosure } from "@chakra-ui/react";
import { AuthWidget } from "@com.marathonium/web2-ui";
import './style.css';
import Logo from './components/Logo';

const Header = lazy(() => import('./components/Header'));
const Promo = lazy(() => import('./components/Promo'));
const Trainers2 = lazy(() => import('./components/Trainers2'));
const Programs = lazy(() => import('./components/Programs'));
const Preims = lazy(() => import('./components/Preims'));
const Videoblock2 = lazy(() => import('./components/Videoblock2'));
const Other = lazy(() => import('./components/Other'));
const Gtvmodal = lazy(() => import('./components/Modal'));

export const Landing = () => {
    const [isFixed, setIsFixed] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isModalActive, setIsModalActive] = useState(false)
    const someclass = useRef(null)

    const { isOpen, onClose, onOpen } = useDisclosure();

    useEffect(() => {
      isActive ? document.querySelector('body').classList.add('gtv-fixed') : document.querySelector('body').classList.remove('gtv-fixed')
    }, [isActive])

    useEffect(() => {
      const onPageLoad = () => {
        setTimeout(() => {
            setIsLoading(false)
        }, 100)
      };
      
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
      
      if (document.readyState === 'complete') {
          onPageLoad();
      } else {
          window.addEventListener('load', onPageLoad, false);
          return () => window.removeEventListener('load', onPageLoad);
      }
    }, []);

    // function scrollHandlerG(e) {
    //   if (e.target.scrollTop % e.target.offsetHeight === 0) {
    //       setTimeout(()=>{
    //           document.querySelector('body').classList.remove('disabled')
    //       }, 500)
    //   }
    // }
function preventDefault(e){
    e.preventDefault();
}

function disableScroll(){
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
}
function enableScroll(){
    document.body.removeEventListener('touchmove', preventDefault);
}
  useEffect(() => {
    if(!isLoading) {
      document.querySelector('.gtv').classList.add('disabled')
      document.querySelector('body').classList.add('disabled')
      document.querySelector('html').classList.add('disabled')
      disableScroll()
      
      // someclass.current.addEventListener('scroll', scrollHandlerG);

      someclass.current.addEventListener('scroll', scrollHandler);
      return () => {
        // someclass.current.removeEventListener('scroll', scrollHandlerG);
        someclass.current.removeEventListener('scroll', scrollHandler);
      }
  }
  }, [isLoading]);

  function scrollHandler(e) {
    if(e.target.scrollTop >= window.innerHeight) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
    
  }

  function scrollmove(num) {
    someclass.current.scrollTo({top: window.innerHeight*num, behavior: "smooth"});
    if(num == 0) {
      document.querySelector('.gtv-promo').scrollTo({top: 0, behavior: "smooth"});
    }
    if(num == 1) {
      document.querySelector('.gtv-video_titles').scrollTo({top: 0, behavior: "smooth"});
    }
    if(num == 2) {
      document.querySelector('.trainers2').scrollTo({top: 0, behavior: "smooth"});
    }
    if(num == 3) {
      document.querySelector('.gtv-programs').scrollTo({top: 0, behavior: "smooth"});
    }
    if(num == 4) {
      document.querySelector('.preims_titles').scrollTo({top: 0, behavior: "smooth"});
    }
    if(num == 5) {
      someclass.current.scrollTo({top: window.innerHeight*(num-1), behavior: "smooth"});
      document.querySelector('.preims_titles').scrollTo({top: window.innerHeight*5, behavior: "smooth"});
    }
    if(num == 6) {
      const some_trainers = document.querySelector('.trainers2').offsetTop;
      document.querySelector('.someclass').scrollTo({top: some_trainers - 60, behavior: "smooth"});
    }
    if(num == 7) {
      const some_trainers = document.querySelector('.gtv-programs').offsetTop;
      document.querySelector('.someclass').scrollTo({top: some_trainers, behavior: "smooth"});
    }
    if(num == 8) {
      const some_trainers = document.querySelector('.gtv-preims2').offsetTop;
      document.querySelector('.someclass').scrollTo({top: some_trainers, behavior: "smooth"});
    }
    setIsActive(false)
  }
    
    return (
      <div>
      <DocumentTitle value='GymTeam — Тренировки экстра-класса. Безлимитно.'/>
      <DocumentDescription value='Тренируйтесь, растягивайтесь, практикуйте йогу и расслабляйтесь от 10 минут в день для создания нового качества жизни! Самые обычные приседания с любимыми тренерами становятся — необычными.' />
      {!isLoading ?
      null
      :
      <div className='gtv2 gtv-preloader'>
        <div style={{width: 240}}>
          <Logo color={'#fb4f54'} />
        </div>
        <div className="gtv-ring"><div></div><div></div><div></div><div></div></div>
      </div>
      }
        <div className='gtv gtv2'>
            <Header onOpen={onOpen} scrollmove={scrollmove} isFixed={isFixed} isActive={isActive} setIsActive={setIsActive} isModalActive={isModalActive} setIsModalActive={setIsModalActive} />
            <div className='someclass' ref={someclass}>
              <Promo setIsModalActive={setIsModalActive} isLoading={isLoading} onOpen={onOpen} enableScroll={enableScroll} />
              {window.innerWidth > 575 && <Videoblock2/>}
              <Trainers2/>
              <Programs/>
              <Preims/>
              <Other/>
            </div>
            {/* <Gtvmodal isModalActive={isModalActive} setIsModalActive={setIsModalActive} /> */}
            <AuthWidget isOpen={isOpen} onClose={onClose} modal={true} />
        </div>
      </div>
    );
};