import styles from './reviews.module.css';

const Reviews = () => {
    return (
        <div className={styles.reviews}>
            <div className="container">
                {/* <div className={styles.t1}>«Прилив энергии,<br/> вдохновение, ощущение<br/> расслабленности»</div> */}
                {/* <div className={styles.st1}>Отзывы доказывают эффективность тренировок GymTeam</div> */}

                <div className={styles.t1}><span>Отзывы</span> доказывают<br/> эффективность тренировок<br/> GymTeam</div>
                
                <div className={styles.b1}>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Оценка<br/> приложения<br/> <b>4,9 из&nbsp;5</b></p>
                    </div>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Более<br/> <b>5&nbsp;тысяч</b> отзывов</p>
                    </div>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Более<br/> <b>56&nbsp;тысяч</b> клиентов</p>
                    </div>
                </div>
            </div>
            
            <div className={styles.block}>
                <div className={styles.line}>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Неожиданно не&nbsp;только понравилась физическая активность, но&nbsp;и&nbsp;эффект от&nbsp;тренировки сродни психотерапии</div>
                        <div className={styles.line_item_name}>Наталья Удалова</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>лёгкость в&nbsp;теле и&nbsp;в&nbsp;глазах, расслабление, взгляд стал ровнее и&nbsp;плавнее буду чаще делать этот комплекс</div>
                        <div className={styles.line_item_name}>Irina</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Это что-то нереальное, как&nbsp;же это приятно, понятно, эффективно, тренер замечательный. Спасибо вам большое!</div>
                        <div className={styles.line_item_name}>Кожебекова Татьяна</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>шикарно! очень нравится, что упражнения не&nbsp;сложные, но&nbsp;достаточно интенсивные! и&nbsp;без прыжков и&nbsp;насилия над собой)))</div>
                        <div className={styles.line_item_name}>Kate</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>мне так хорошо, чувствую спокойствие в&nbsp;душе и&nbsp;хорошую работу в&nbsp;теле, без насилия</div>
                        <div className={styles.line_item_name}>Светлана</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>👍Мне очень нравятся такие короткие занятия йогой, чувствую после них себя бодро весь день</div>
                        <div className={styles.line_item_name}>Елизавета</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Неожиданно не&nbsp;только понравилась физическая активность, но&nbsp;и&nbsp;эффект от&nbsp;тренировки сродни психотерапии</div>
                        <div className={styles.line_item_name}>Наталья Удалова</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>лёгкость в&nbsp;теле и&nbsp;в&nbsp;глазах, расслабление, взгляд стал ровнее и&nbsp;плавнее буду чаще делать этот комплекс</div>
                        <div className={styles.line_item_name}>Irina</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Это что-то нереальное, как&nbsp;же это приятно, понятно, эффективно, тренер замечательный. Спасибо вам большое!</div>
                        <div className={styles.line_item_name}>Кожебекова Татьяна</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>шикарно! очень нравится, что упражнения не&nbsp;сложные, но&nbsp;достаточно интенсивные! и&nbsp;без прыжков и&nbsp;насилия над собой)))</div>
                        <div className={styles.line_item_name}>Kate</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>мне так хорошо, чувствую спокойствие в&nbsp;душе и&nbsp;хорошую работу в&nbsp;теле, без насилия</div>
                        <div className={styles.line_item_name}>Светлана</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>👍Мне очень нравятся такие короткие занятия йогой, чувствую после них себя бодро весь день</div>
                        <div className={styles.line_item_name}>Елизавета</div>
                    </div>
                </div>

                <div className={styles.line}>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>новые интересные упражнения! раньше таких нигде не&nbsp;встречала👍</div>
                        <div className={styles.line_item_name}>Светлана Борзова</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>прекрасное занятия!Так&nbsp;же как и&nbsp;все которые ведёт этот тренер!</div>
                        <div className={styles.line_item_name}>Юлия</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Спасибо! Классные упражнения! Мне все понравилось в&nbsp;курсе</div>
                        <div className={styles.line_item_name}>Анастасия Хренова</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Легкость и&nbsp;расслабление в&nbsp;теле. Эмоции успокоились.</div>
                        <div className={styles.line_item_name}>Анна</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Я&nbsp;получила классные ощущения от&nbsp;упражнений, супер!!! и&nbsp;инструктор понятно интересно объясняет!!!🔥</div>
                        <div className={styles.line_item_name}>Svetlana</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Отличные упражнения. эффект виден с&nbsp;первого урока. )</div>
                        <div className={styles.line_item_name}>Oksana</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>новые интересные упражнения! раньше таких нигде не&nbsp;встречала👍</div>
                        <div className={styles.line_item_name}>Светлана Борзова</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>прекрасное занятия!Так&nbsp;же как и&nbsp;все которые ведёт этот тренер!</div>
                        <div className={styles.line_item_name}>Юлия</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Спасибо! Классные упражнения! Мне все понравилось в&nbsp;курсе</div>
                        <div className={styles.line_item_name}>Анастасия Хренова</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Легкость и&nbsp;расслабление в&nbsp;теле. Эмоции успокоились.</div>
                        <div className={styles.line_item_name}>Анна</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Я&nbsp;получила классные ощущения от&nbsp;упражнений, супер!!! и&nbsp;инструктор понятно интересно объясняет!!!🔥</div>
                        <div className={styles.line_item_name}>Svetlana</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Отличные упражнения. эффект виден с&nbsp;первого урока. )</div>
                        <div className={styles.line_item_name}>Oksana</div>
                    </div>
                </div>

                <div className={styles.line}>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>🔥Восторг! впервые приобрела тренировки онлайн, не&nbsp;ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                        <div className={styles.line_item_name}>Наталья</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на&nbsp;него смотреть, в&nbsp;хорошей форме, и&nbsp;хорошо выглядит.</div>
                        <div className={styles.line_item_name}>Николай</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Супер! Все понравилось :) Заботливые комментарии и&nbsp;постоянный контроль дыхания сделали эту тренировку идеальной.</div>
                        <div className={styles.line_item_name}>Дарина</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Тренер просто космический. Такая искренняя любовь ко&nbsp;всей планете. Вызывает добрую улыбку)</div>
                        <div className={styles.line_item_name}>Masha Lebed</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>прекрасное занятия!Так&nbsp;же как и&nbsp;все которые ведёт этот тренер!</div>
                        <div className={styles.line_item_name}>Юлия</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>грамотно пробудила тело после сна, ощущения отличные</div>
                        <div className={styles.line_item_name}>Ksu Ry</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>🔥Восторг! впервые приобрела тренировки онлайн, не&nbsp;ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                        <div className={styles.line_item_name}>Наталья</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на&nbsp;него смотреть, в&nbsp;хорошей форме, и&nbsp;хорошо выглядит.</div>
                        <div className={styles.line_item_name}>Николай</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Супер! Все понравилось :) Заботливые комментарии и&nbsp;постоянный контроль дыхания сделали эту тренировку идеальной.</div>
                        <div className={styles.line_item_name}>Дарина</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>Тренер просто космический. Такая искренняя любовь ко&nbsp;всей планете. Вызывает добрую улыбку)</div>
                        <div className={styles.line_item_name}>Masha Lebed</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>прекрасное занятия!Так&nbsp;же как и&nbsp;все которые ведёт этот тренер!</div>
                        <div className={styles.line_item_name}>Юлия</div>
                    </div>
                    <div className={styles.line_item}>
                        <div className={styles.line_item_text}>грамотно пробудила тело после сна, ощущения отличные</div>
                        <div className={styles.line_item_name}>Ksu Ry</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews;