import styles from './trainers.module.css';

const Trainers = () => {
    const trainers = [
        'Екатерина Усманова',
        'Анастасия Лобанова',
        'Елена Вьюгина',
        'Виктория Видякина',
        'Юлия Власова',
        'Мария Андреева',
        'Наталья Ниль',
        'Мария Филлипова',
        'Евгения Синицкая',
        'Ольга Мирохватова',
        'Евгения Дюпина',
        'Яна Передернина',
        'Анна Андронович',
        'Ксения Микичура',
        'Екатерина Кравцова',
        'Никита Москвин',
        'Юлия Махеева',
        'Татьяна Балашова ',
        'Юлия Усакова',
        'Екатерина Усманова',
        'Анастасия Лобанова',
        'Елена Вьюгина',
        'Виктория Видякина',
        'Юлия Власова',
        'Мария Андреева',
        'Наталья Ниль',
    ]

    return (
        <div className={styles.trainers}>
            <div className="container">
                <div className={styles.trainers_title}>ГОРДИМСЯ НАШИМИ ТРЕНЕРАМИ</div>
                <div className={styles.trainers_subtitle}>Тренерский состав&nbsp;— вот что&nbsp;делает любые тренировки<br/> особенными и&nbsp;запоминающимися. Самые обычные<br/> приседания с&nbsp;любимым тренером становятся&nbsp;— необычными.</div>
            </div>
            <div className={styles.trainers_row}>
                {trainers.map((item, index) => {
                    return(
                        <div className={styles.trainers_item} key={index}>
                            <div className={styles[`trainer_img__${index+1}`] + ' ' + styles[`trainers_item__img`]}></div>
                            <p>{item}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Trainers;