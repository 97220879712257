import './healthymama.css';

const Healthymama = ({scroll}) => {
    return (
        <div className="healthymama">
            <div className="container">
            <div className="healthymama_title">Стройная и здоровая мама =<br/> Счастливый малыш</div>
            <div className="healthymama_subtitle">Результаты на пользу вам, ребёнку и&nbsp;отношениям</div>
            <div className="healthymama_block">
                <div className="healthymama_item">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/mama_1.png' alt="" />
                <p>Красивое тело без растяжек, целлюлита и&nbsp;складок кожи</p>
                </div>
                <div className="healthymama_item">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/mama_2.png' alt="" />
                <p>Прекрасное самочувствие<br/> и настроение в&nbsp;условиях недосыпа</p>
                </div>
                <div className="healthymama_item">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/mama_3.png' alt="" />
                <p>Забота о здоровье, чтобы<br/> меньше обращаться к&nbsp;врачам</p>
                </div>
                <div className="healthymama_item">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/mama_4.png' alt="" />
                <p>Энергия и&nbsp;новые силы для себя<br/> и&nbsp;ухода за&nbsp;малышом</p>
                </div>
                <div className="healthymama_item">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/mama_5.png' alt="" />
                <p>Ощущение собственной привлекательности и&nbsp;сексуальности</p>
                </div>
                <div className="healthymama_item">
                <img src='https://gymteam.kinescopecdn.net/img/l/afterpregnancy/mama_6.png' alt="" />
                <p>Возвращение любви к своему телу, даже если роды прошли<br/> не&nbsp;лучшим образом</p>
                </div>
            </div>
            <button className='main_btn' onClick={scroll}>Записаться на&nbsp;курс со&nbsp;скидкой</button>
            </div>
        </div>
    )
}

export default Healthymama;