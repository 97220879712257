// import Video from '../../05-rasprozharka2/components/Video';
import styles from './second.module.css';

const Second = () => {
    return (
        <div className={styles.second}>
            <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 20%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                </div>
            </div>
        
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>15&nbsp;октября</div>
                        <div className={styles.item_t2}>старт тренировок</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>6&nbsp;месяцев</div>
                        <div className={styles.item_t2}>доступ к&nbsp;программе</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>чат с&nbsp;куратором</div>
                        <div className={styles.item_t2}>на&nbsp;1&nbsp;месяц</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>Дарим</div>
                        <div className={styles.item_t2}>6&nbsp;полезных гайдов<br/> при покупке</div>
                    </div>
                </div>

                {/* <Video />

                <div className={styles.block2}>
                    <div className={styles.block2_title}>Забирай любые марафоны или курсы под<br/> свою цель <span>с&nbsp;выгодой&nbsp;65%</span> и&nbsp;получи результат<br/> уже этим летом!</div>
                    <div className={styles.block2_subtitle}>Пора включать режим ускоренной подготовки, чтобы<br/> шикарно выглядеть к&nbsp;пляжному сезону</div>
                </div> */}
            </div>
        
        </div>
        
    )
}

export default Second;