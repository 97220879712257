import styles from './joinus.module.css';

const Joinus = ({scroll}) => {
    return (
        <div className={`joinus_anchor ${styles.joinus}`}>
            <div className="container">
                <div className={styles.joinus_title}>Присоединяйтесь И&nbsp;оцените все&nbsp;преимущества</div>
                <ul>
                    <li><strong>От 10 до 60 минут</strong> на тренировку</li>
                    <li><strong>Занимайтесь где захочется</strong>&nbsp;— не&nbsp;только дома или в&nbsp;спортзале</li>
                    <li>Занимаетесь <strong>в&nbsp;удобное время</strong> дня</li>
                    <li>Программы тренировок от&nbsp;<strong>специалистов международного<br/> класса</strong></li>
                    <li>Максимальная <strong>концентрация на&nbsp;себе и&nbsp;своих движениях,</strong><br/> чтобы&nbsp;наполниться энергией</li>
                    <li>Вам нужен <strong>минимальный набор инвентаря,</strong> который легко<br/> приобрести в&nbsp;любом магазине</li>
                    <li><strong>Получаете доступ ко&nbsp;всем&nbsp;новинкам БЕСПЛАТНО</strong></li>
                </ul>
                <div className={styles.joinus_btn} onClick={scroll}>ОФОРМИТЬ ПОДПИСКУ</div>
                <div className={styles.joinus_panel}>Почти <strong>620&nbsp;000 тренировок</strong> сделали пользователи GymTeam за&nbsp;последний год</div>
            </div>
        </div>
    );
};

export default Joinus;