import { useEffect, useState } from 'react';
import styles from './style.module.css';

export const L2023_06_Calculator = () => {
    const [screen, setScreen] = useState(1)
    const [years, setYears] = useState('')

    const [age, setAge] = useState()
    const [weight, setWeight] = useState()
    const [height, setHeight] = useState()
    const [activity, setActivity] = useState(1.2)
    const [point, setPoint] = useState(0.2)

    const [calories, setCalories] = useState(0)
    const [proteins, setProteins] = useState(0)
    const [fats, setFats] = useState(0)
    const [carbohydrates, setCarbohydrates] = useState(0)

    const calc = (resCalories) => {
        const jija = resCalories*(0.3/9)
        if(jija < 70) {
            setFats(jija)
            setCalories(resCalories)
            if(point === 0.2) {
                setProteins(resCalories*0.0875) 
                setCarbohydrates(resCalories*0.0875)
            }
            if(point === 0.15) {
                setProteins(resCalories*0.0625) 
                setCarbohydrates(resCalories*0.1125)
            }
            if(point === 0.1) {
                setProteins(resCalories*0.075) 
                setCarbohydrates(resCalories*0.1)
            }
        } else {
            setFats(70)
            const newResCalories = resCalories-(jija-70)*9
            setCalories(newResCalories)
            if(point === 0.2) {
                setProteins(newResCalories*0.0875) 
                setCarbohydrates(newResCalories*0.0875)
            }
            if(point === 0.15) {
                setProteins(newResCalories*0.0625) 
                setCarbohydrates(newResCalories*0.1125)
            }
            if(point === 0.1) {
                setProteins(newResCalories*0.075) 
                setCarbohydrates(newResCalories*0.1)
            }
        }
    }

    useEffect(()=>{
        if(point === 0.15) {
            const resCalories = (447.6+weight*9.2+height*3.1-age*4.3)*activity+(447.6+weight*9.2+height*3.1-age*4.3*activity)*point
            calc(resCalories)
        } else {
            const resCalories = (447.6+weight*9.2+height*3.1-age*4.3)*activity-(447.6+weight*9.2+height*3.1-age*4.3*activity)*point
            calc(resCalories)
        }
    }, [point, activity, height, weight, age])

    const clear = () => {
        setScreen(2)
        setAge()
        setWeight()
        setHeight()
        setActivity(1.2)
        setPoint(0.2)
    }

    useEffect(()=>{
        if(age) {
            if(age.length > 1) {
                if(age[0] == 1) {
                    setYears('лет')
                } else {
                    if(age[1] == 1) {setYears('год')}
                    if(age[1] == 2 || age[1] == 3 || age[1] == 4) {setYears('года')}
                    if(age[1] == 5 || age[1] == 6 || age[1] == 7 || age[1] == 8 || age[1] == 9) {setYears('лет')}
                }                
            } else {
                if(age == 1) {setYears('год')}
                if(age == 2 || age == 3 || age == 4) {setYears('года')}
                if(age == 5 || age == 6 || age == 7 || age == 8 || age == 9) {setYears('лет')}
            }
        }
    },[age])

    return (
        <div className={styles.calc}>

            <div className={styles.logo}><img src="https://gymteam.kinescopecdn.net/img/l/2023/calculator/logo.svg" alt="" /></div>
            <div className={styles.block}>

            {screen == 1 && 
                <div>
                    <div className={styles.title}>КАЛЬКУЛЯТОР<br/> КБЖУ ДЛЯ ЗАЛА </div>
                    <div className={styles.subtitle}>Заполните данные в калькуляторе,<br/> чтобы получить рекомендации по КБЖУ </div>
                    <div className={styles.item1}>
                        <div className={styles.btn} onClick={() => setScreen(2)}>начать</div>
                    </div>
                </div>
            }
            {screen == 2 && 
                <div className={styles.item}>
                    <div className={styles.name}>Введите количество<br/> полных лет</div>
                    <input type="number" placeholder="Возраст" value={age} className={styles.input} onChange={e => setAge(e.target.value)} />
                    <div className={`${styles.btn} ${!age && styles.inactive}`} onClick={() => setScreen(3)}>продолжить</div>
                </div>
            }
            {screen == 3 && 
                <div className={styles.item}>
                    <div className={styles.name}>Введите ваши текущие параметры</div>
                    <div className={`${styles.hasheight} ${height && styles.active}`}>
                        <input type="number" placeholder="Рост в сантиметрах" value={height} className={styles.input} onChange={e => setHeight(e.target.value)} />
                    </div>
                    <div className={`${styles.hasweight} ${weight && styles.active}`}>
                        <input type="number" placeholder="Вес в килограммах" value={weight} className={styles.input} onChange={e => setWeight(e.target.value)} />
                    </div>
                    <div className={`${styles.btn} ${!weight && styles.inactive} ${!height && styles.inactive}`} onClick={() => setScreen(4)}>продолжить</div>
                </div>
            }
            {screen == 4 && 
                <div className={styles.item}>
                    <div className={styles.name}>Выберите тип активности</div>
                    <input type="radio" name="activity" checked={activity==1.2&&'checked'} className={styles.hidden} id="act1" onChange={() => setActivity(1.2)} />
                    <label htmlFor="act1" className={styles.label}>
                        <div className={styles.label_title}>Минимальная</div>
                        <div className={styles.label_text}><p>Минимальная активность = ВОО*1,2</p>
                        <p>Низкий уровень физической активности, сидячая работа + тренировки 1-3 раза в неделю</p>
                        <p>(Руководители предприятий, менеджеры, инженеры, делопроизводители, педагоги, участковый врач и другие работники, чья деятельность не требует существенной физической активности и подвижности)</p></div>
                    </label>
                    <input type="radio" name="activity" checked={activity==1.375&&'checked'} className={styles.hidden} id="act2" onChange={() => setActivity(1.375)} />
                    <label htmlFor="act2" className={styles.label}>
                        <div className={styles.label_title}>Средняя</div>
                        <div className={styles.label_text}><p>Средняя активность = ВОО*1,375</p>
                        <p>Небольшая физ активность + тренировки 1-3 раза в неделю</p>
                        <p>(Инженерно-технические работники, чья деятельность связана с некоторыми физическими усилиями, зоотехники, ветеринарные работники, медсестры, санитарки, тренеры, продавцы, врач-хирург)</p></div>
                    </label>
                    <input type="radio" name="activity" checked={activity==1.55&&'checked'} className={styles.hidden} id="act3" onChange={() => setActivity(1.55)} />
                    <label htmlFor="act3" className={styles.label}>
                        <div className={styles.label_title}>Высокая</div>
                        <div className={styles.label_text}><p>Высокая активность = ВОО*1,55</p>
                        <p>Достаточно большая физическая активность + занятия спортом 3-5 раз в неделю</p>
                        <p>(Строительные и сельскохозяйственные рабочие, работники нефтяной, газовой, целлюлозно-бумажной промышленности, металлурги)</p></div>
                    </label>
                    <div className={`${styles.btn} ${!weight && styles.inactive} ${!height && styles.inactive}`} onClick={() => setScreen(5)}>продолжить</div>
                </div>
            }
            {screen == 5 && 
                <div className={styles.item}>
                    <div className={styles.name}>Выберите тип активности</div>
                    <input type="radio" name="point" checked={point==0.2&&'checked'} className={styles.hidden} id="point1" onChange={() => setPoint(0.2)} />
                    <label htmlFor="point1" className={styles.label}>
                        <div className={styles.label_title}>Похудеть</div>
                        <div className={styles.label_text}>Интенсивные упражнения, при которых задействуются много групп мышц (частота пульса 65-75% от максимальной)</div>
                    </label>
                    <input type="radio" name="point" checked={point==0.15&&'checked'} className={styles.hidden} id="point2" onChange={() => setPoint(0.15)} />
                    <label htmlFor="point2" className={styles.label}>
                        <div className={styles.label_title}>Набрать мышечную массу</div>
                        <div className={styles.label_text}>Многосуставные упражнения с разными весами, учитывающие принцип прогрессирующей нагрузки</div>
                    </label>
                    <input type="radio" name="point" checked={point==0.1&&'checked'} className={styles.hidden} id="point3" onChange={() => setPoint(0.1)} />
                    <label htmlFor="point3" className={styles.label}>
                        <div className={styles.label_title}>Поддержать вес</div>
                        <div className={styles.label_text}>Умеренные, разнообразные нагрузки помогающие привести в норму уровень кортизола и лептина</div>
                    </label>
                    <div className={`${styles.btn} ${!weight && styles.inactive} ${!height && styles.inactive}`} onClick={() => setScreen(6)}>продолжить</div>
                </div>
            }
            {screen == 6 && 
                <div className={styles.item}>
                    <div className={styles.name}>Результаты расчёта</div>
                    <div className={styles.calc_result}>
                        <div className={styles.res_item}>
                            <div>Калораж:</div> <div>{calories.toFixed()} ккал</div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Белки:</div> <div>{proteins.toFixed()} г</div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Жиры:</div> <div>{fats.toFixed()} г</div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Углеводы:</div> <div>{carbohydrates.toFixed()} г</div>
                        </div>
                    </div>
                    <div className={styles.result}>
                        <div className={styles.res_item}>
                            <div>Возраст:</div> <div>{age} {years}</div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Рост:</div> <div>{height} см</div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Вес:</div> <div>{weight} кг</div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Тип активности:</div>
                            <div>
                                {activity===1.2&&'Минимальная'}
                                {activity===1.375&&'Средняя'}
                                {activity===1.55&&'Высокая'}
                            </div>
                        </div>
                        <div className={styles.res_item}>
                            <div>Цель:</div>
                            <div>
                                {point===0.2&&'Похудеть'}
                                {point===0.15&&'Набрать мышечную массу'}
                                {point===0.1&&'Поддержать вес'}
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.btn} ${!weight && styles.inactive} ${!height && styles.inactive}`} onClick={clear}>новый расчёт</div>
                </div>
            }

            {screen > 1 && screen < 6 
            ?
            <div className={styles.item}>
                <div className={styles.progress}>
                    <div style={{width: `calc(${screen-1} * 20%)`}}></div>
                </div>
                <div className={styles.btn_back} onClick={() => setScreen(screen-1)}><img src='https://gymteam.kinescopecdn.net/img/l/2023/calculator/arrow.svg'/>вернуться на предыдущий шаг</div>  
            </div>
            :
            null
            }

            </div>
        </div>
    );
};