import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import Countdown from 'react-countdown';

import styles from './gifts.module.css';

const Gifts = ({scroll, renderer}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.gifts}>
            <div className="container">
                <div className={styles.gifts_title}>ПОДАРКИ, КОТОРЫЕ СДЕЛАЮТ<br/> ТЕБЯ СТРОЙНЕЕ!</div>
                <div className={styles.gifts_subtitle}>При покупке <b>в&nbsp;течение 15&nbsp;минут</b> ты&nbsp;гарантированно получаешь<br/> 6&nbsp;полезных гайдов&nbsp;&mdash; они помогут разобраться во&nbsp;всех нюансах<br/> на&nbsp;пути к&nbsp;стройности</div>

                {/* <div className={styles.block}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                        </div>

                        <Swiper
                            speed={800}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            slidesPerView={'auto'}
                            spaceBetween={20}
                            loop
                            onSwiper={(swiper) => {
                                setTimeout(() => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current
                                
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                })
                            }}
                            modules={[Navigation]}
                            className={`swiper_gt ${styles.swiper}`}
                        >
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/gift_1.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>путеводитель</span>
                                        <p>«Оборудование и&nbsp;инвентарь в&nbsp;тренажёрном зале»</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/gift_2.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>сборник</span>
                                        <p>«Продукты с&nbsp;высоким содержанием белка для набора мышечной массы»</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/gift_3.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>«Правила хорошего тона в&nbsp;тренажёрном зале»</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/gift_4.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>«Как правильно читать показатели умных весов»</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/gift_5.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>Инструкция</span>
                                        <p>«Одежда, обувь и&nbsp;аксессуары для фитнеса: как выбрать и&nbsp;ухаживать»</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/gift_6.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>«Дисциплина: как&nbsp;превратить спорт в&nbsp;привычку и&nbsp;не&nbsp;сдаться на&nbsp;третий день»</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                        </div>
                </div> */}

                <div className={styles.block2}>
                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/1.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>путеводитель</span>
                                        <p>«Оборудование и&nbsp;инвентарь в&nbsp;тренажёрном зале»</p>
                                    </div>
                                </div>
                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/2.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>сборник</span>
                                        <p>«Продукты с&nbsp;высоким содержанием белка для набора мышечной массы»</p>
                                    </div>
                                </div>
                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/3.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>«Правила хорошего тона в&nbsp;тренажёрном зале»</p>
                                    </div>
                                </div>
                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/4.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>«Как правильно читать показатели умных весов»</p>
                                    </div>
                                </div>
                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/5.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>Инструкция</span>
                                        <p>«Одежда, обувь и&nbsp;аксессуары для фитнеса: как выбрать и&nbsp;ухаживать»</p>
                                    </div>
                                </div>
                    <div className={styles.slide_block}>
                        <div className={styles.slide_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/6.png" alt="" />
                        </div>
                        <div className={styles.slide_info}>
                            <span>гайд</span>
                            <p>«Дисциплина: как&nbsp;превратить спорт в&nbsp;привычку и&nbsp;не&nbsp;сдаться на&nbsp;третий день»</p>
                        </div>
                    </div>
                </div>

                <div className={styles.btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Забрать программу<br/> и&nbsp;бонусы</p>
                    </div>
                <div className={styles.promo_countdown}>
                    <p>Успей забрать курс по&nbsp;самой выгодной цене<br/> и&nbsp;получить ВСЕ бонусы</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + 900000} renderer={renderer} />
                    </div>
                </div>
            </div>
                
        </div>
    )
}

export default Gifts;