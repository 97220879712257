import styles from './trainings.module.css';
import './trainings.css'
import { useEffect, useState } from 'react';

const programms = [
    {title: 'Укрепляем тазовое дно', num: 1},
    {title: 'Восстановительная гимнастика', num: 2},
    {title: 'Пилатес с оборудованием', num: 3},
    {title: 'Силовые тренировки для начинающих', num: 4},
    {title: 'Здоровая спина', num: 5},
    {title: 'Здоровые стопы', num: 6},
    {title: 'Power Yoga', num: 7},
    {title: 'Офисные комплексы упражнений', num: 8},
    {title: 'Кроссфит', num: 9},
    {title: 'Йога для начинающих', num: 10},
    {title: 'Силовые тренировки', num: 11},
    {title: 'Табата на каждый день', num: 12},
    {title: 'Короткие кроссфит-тренировки', num: 13},
    {title: 'Функциональные тренировки', num: 14},
    {title: 'Пилатес. Продвинутый уровень', num: 15},
    {title: 'Интервальный тренинг HIIT', num: 16},
    {title: 'Тренировки по йоге', num: 17},
    {title: 'Основы пилатеса', num: 18},
    {title: 'Фитнес-тренировки барре', num: 19},
    {title: 'Основы Тайцзицюань (Тай-чи)', num: 20},
    {title: 'Силовые тренировки для продвинутых', num: 21},
    {title: 'HIIT на каждый день', num: 22},
    {title: 'Тренировки по барре', num: 23},
    {title: 'Йога на каждый день', num: 24},
    {title: 'Высокоинтенсивные тренировки табата', num: 25},
    {title: 'Пилатес на каждый день', num: 26},
    {title: 'Утренняя зарядка', num: 27},
    {title: 'Стретчинг-растяжка', num: 28},
    {title: 'Миофасциальное расслабление', num: 29},
    {title: 'Кундалини-йога', num: 30},
    {title: 'Фитнес-бокс', num: 31},
    {title: 'Функциональные тренировки каждый день', num: 32},
    {title: 'Растяжка на каждый день', num: 33},
    {title: 'Силовые интервальные тренировки', num: 34},
    {title: 'Растяжка в удовольствие', num: 35},
]
const programms3 = [
    {title: 'Офисные комплексы упражнений', num: 8},
    {title: 'Кроссфит', num: 9},
    {title: 'Йога для начинающих', num: 10},
    {title: 'Силовые тренировки', num: 11},
    {title: 'Табата на каждый день', num: 12},
    {title: 'Короткие кроссфит-тренировки', num: 13},
    {title: 'Функциональные тренировки', num: 14},
    {title: 'Пилатес. Продвинутый уровень', num: 15},
    {title: 'Интервальный тренинг HIIT', num: 16},
    {title: 'Тренировки по йоге', num: 17},
    {title: 'Основы пилатеса', num: 18},
    {title: 'Фитнес-тренировки барре', num: 19},
    {title: 'Основы Тайцзицюань (Тай-чи)', num: 20},
    {title: 'Силовые тренировки для продвинутых', num: 21},
    {title: 'HIIT на каждый день', num: 22},
    {title: 'Тренировки по барре', num: 23},
    {title: 'Йога на каждый день', num: 24},
    {title: 'Высокоинтенсивные тренировки табата', num: 25},
    {title: 'Пилатес на каждый день', num: 26},
    {title: 'Утренняя зарядка', num: 27},
    {title: 'Стретчинг-растяжка', num: 28},
    {title: 'Миофасциальное расслабление', num: 29},
    {title: 'Кундалини-йога', num: 30},
    {title: 'Фитнес-бокс', num: 31},
    {title: 'Функциональные тренировки каждый день', num: 32},
    {title: 'Растяжка на каждый день', num: 33},
    {title: 'Силовые интервальные тренировки', num: 34},
    {title: 'Растяжка в удовольствие', num: 35},
    {title: 'Укрепляем тазовое дно', num: 1},
    {title: 'Восстановительная гимнастика', num: 2},
    {title: 'Пилатес с оборудованием', num: 3},
    {title: 'Силовые тренировки для начинающих', num: 4},
    {title: 'Здоровая спина', num: 5},
    {title: 'Здоровые стопы', num: 6},
    {title: 'Power Yoga', num: 7},
]
const programms4 = [
    {title: 'Пилатес. Продвинутый уровень', num: 15},
    {title: 'Интервальный тренинг HIIT', num: 16},
    {title: 'Тренировки по йоге', num: 17},
    {title: 'Основы пилатеса', num: 18},
    {title: 'Фитнес-тренировки барре', num: 19},
    {title: 'Основы Тайцзицюань (Тай-чи)', num: 20},
    {title: 'Силовые тренировки для продвинутых', num: 21},
    {title: 'HIIT на каждый день', num: 22},
    {title: 'Тренировки по барре', num: 23},
    {title: 'Йога на каждый день', num: 24},
    {title: 'Высокоинтенсивные тренировки табата', num: 25},
    {title: 'Пилатес на каждый день', num: 26},
    {title: 'Утренняя зарядка', num: 27},
    {title: 'Стретчинг-растяжка', num: 28},
    {title: 'Миофасциальное расслабление', num: 29},
    {title: 'Кундалини-йога', num: 30},
    {title: 'Фитнес-бокс', num: 31},
    {title: 'Функциональные тренировки каждый день', num: 32},
    {title: 'Растяжка на каждый день', num: 33},
    {title: 'Силовые интервальные тренировки', num: 34},
    {title: 'Растяжка в удовольствие', num: 35},
    {title: 'Укрепляем тазовое дно', num: 1},
    {title: 'Восстановительная гимнастика', num: 2},
    {title: 'Пилатес с оборудованием', num: 3},
    {title: 'Силовые тренировки для начинающих', num: 4},
    {title: 'Здоровая спина', num: 5},
    {title: 'Здоровые стопы', num: 6},
    {title: 'Power Yoga', num: 7},
    {title: 'Офисные комплексы упражнений', num: 8},
    {title: 'Кроссфит', num: 9},
    {title: 'Йога для начинающих', num: 10},
    {title: 'Силовые тренировки', num: 11},
    {title: 'Табата на каждый день', num: 12},
    {title: 'Короткие кроссфит-тренировки', num: 13},
    {title: 'Функциональные тренировки', num: 14},
]
const programms5 = [
    {title: 'HIIT на каждый день', num: 22},
    {title: 'Тренировки по барре', num: 23},
    {title: 'Йога на каждый день', num: 24},
    {title: 'Высокоинтенсивные тренировки табата', num: 25},
    {title: 'Пилатес на каждый день', num: 26},
    {title: 'Утренняя зарядка', num: 27},
    {title: 'Стретчинг-растяжка', num: 28},
    {title: 'Миофасциальное расслабление', num: 29},
    {title: 'Кундалини-йога', num: 30},
    {title: 'Фитнес-бокс', num: 31},
    {title: 'Функциональные тренировки каждый день', num: 32},
    {title: 'Растяжка на каждый день', num: 33},
    {title: 'Силовые интервальные тренировки', num: 34},
    {title: 'Растяжка в удовольствие', num: 35},
    {title: 'Укрепляем тазовое дно', num: 1},
    {title: 'Восстановительная гимнастика', num: 2},
    {title: 'Пилатес с оборудованием', num: 3},
    {title: 'Силовые тренировки для начинающих', num: 4},
    {title: 'Здоровая спина', num: 5},
    {title: 'Здоровые стопы', num: 6},
    {title: 'Power Yoga', num: 7},
    {title: 'Офисные комплексы упражнений', num: 8},
    {title: 'Кроссфит', num: 9},
    {title: 'Йога для начинающих', num: 10},
    {title: 'Силовые тренировки', num: 11},
    {title: 'Табата на каждый день', num: 12},
    {title: 'Короткие кроссфит-тренировки', num: 13},
    {title: 'Функциональные тренировки', num: 14},
    {title: 'Пилатес. Продвинутый уровень', num: 15},
    {title: 'Интервальный тренинг HIIT', num: 16},
    {title: 'Тренировки по йоге', num: 17},
    {title: 'Основы пилатеса', num: 18},
    {title: 'Фитнес-тренировки барре', num: 19},
    {title: 'Основы Тайцзицюань (Тай-чи)', num: 20},
    {title: 'Силовые тренировки для продвинутых', num: 21},
]
const programms_mob = [
    {title: 'Укрепляем тазовое дно', num: 1},
    {title: 'Восстановительная гимнастика', num: 2},
    {title: 'Пилатес с оборудованием', num: 3},
    {title: 'Силовые тренировки для начинающих', num: 4},
    {title: 'Здоровая спина', num: 5},
    {title: 'Здоровые стопы', num: 6},
    {title: 'Power Yoga', num: 7},
    {title: 'Офисные комплексы упражнений', num: 8},
    {title: 'Кроссфит', num: 9},
    {title: 'Йога для начинающих', num: 10},
    {title: 'Силовые тренировки', num: 11},
    {title: 'Табата на каждый день', num: 12},
    {title: 'Короткие кроссфит-тренировки', num: 13},
    {title: 'Функциональные тренировки', num: 14},
    {title: 'Пилатес. Продвинутый уровень', num: 15},
    {title: 'Интервальный тренинг HIIT', num: 16},
    {title: 'Тренировки по йоге', num: 17},
    {title: 'Основы пилатеса', num: 18},
    {title: 'Фитнес-тренировки барре', num: 19},
    {title: 'Основы Тайцзицюань (Тай-чи)', num: 20},
    {title: 'Силовые тренировки для продвинутых', num: 21},
    {title: 'HIIT на каждый день', num: 22},
    {title: 'Тренировки по барре', num: 23},
    {title: 'Йога на каждый день', num: 24},
    {title: 'Высокоинтенсивные тренировки табата', num: 25},
    {title: 'Пилатес на каждый день', num: 26},
    {title: 'Утренняя зарядка', num: 27},
    {title: 'Стретчинг-растяжка', num: 28},
    {title: 'Миофасциальное расслабление', num: 29},
    {title: 'Кундалини-йога', num: 30},
    {title: 'Фитнес-бокс', num: 31},
    {title: 'Функциональные тренировки каждый день', num: 32},
    {title: 'Растяжка на каждый день', num: 33},
    {title: 'Силовые интервальные тренировки', num: 34},
]

const Trainings = ({ver}) => {
    const [mob, setMob] = useState();
    const [check, setCheck] = useState(false);

    function isMob() {
        console.log(window.innerWidth)
        if(window.innerWidth > 575) {
            setMob(true)
        } else {
            setMob(false)
        }
    }

    useEffect(()=>{
        isMob()
        window.addEventListener('resize', function() {
            isMob()
        })
    }, [])    

    function showProgramms(arr) {
        return (
            arr.map((item, index) => {
                return (
                  <div className={`nprograms_item_${item.num} ${styles.programs_item}`} key={index}>
                    <p>{item.title}</p>
                  </div>
                )
            })
        )
    }

    return (
        <div className={styles.trainings}>
            <div className="container">
                <div className={styles.t1}>Бескрайние возможности<br/> тренировок для каждого</div>
                <div className={styles.b1}>
                    <div className={styles.b1_item}><span>79</span> программ</div>
                    <div className={styles.b1_item}><span>&gt; 3&nbsp;000</span> тренировок</div>
                    <div className={styles.b1_item}><span>28</span> тренеров</div>
                </div>
            </div>

            {/* <div className={styles.b2}>
                <div className={styles.line}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program1.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_2.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_3.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_4.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_5.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_6.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_7.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_8.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_9.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program1.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_2.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_3.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_4.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_5.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_6.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_7.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_8.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_9.jpg" alt="" />
                </div>
                <div className={styles.line}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_10.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_11.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_12.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_13.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_14.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_15.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_16.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_17.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_18.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_10.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_11.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_12.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_13.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_14.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_15.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_16.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_17.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_18.jpg" alt="" />
                </div>
                <div className={styles.line}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_19.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_20.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_21.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_22.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_23.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_24.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_25.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_26.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_27.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_19.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_20.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_21.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_22.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_23.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_24.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_25.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_26.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_27.jpg" alt="" />
                </div>
                <div className={styles.line}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_28.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_29.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_30.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_31.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_32.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_33.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_34.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_35.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_36.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_28.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_29.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_30.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_31.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_32.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_33.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_34.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_35.jpg" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gtv/programms/program_36.jpg" alt="" />
                </div>
            </div> */}

            {mob ? 
            <div className={styles.b2}>
                <div className={`${styles.programs_line}`}>
                    {showProgramms(programms4)}
                </div>
                <div className={styles.programs_line}>
                    {showProgramms(programms)}
                </div>
                <div className={styles.programs_line}>
                    {showProgramms(programms5)}
                </div>
                <div className={`${styles.programs_line}`}>
                    {showProgramms(programms3)}
                </div>
            </div>
            : 
            <div className={styles.mob}>
                <div className={`${styles.mob_block} ${check && styles.mob_block_active}`}>
                    {showProgramms(programms_mob)}
                </div>
                {/* <button className={styles.studentres_btn} onClick={showMore}><p>{count>27 ? 'Скрыть изображения' : 'Показать еще'}</p></button> */}
                <div className={styles.mob_btn} onClick={() => setCheck(prevCheck => !prevCheck)}>{check ? 'скрыть' : 'показать еще'}</div>
            </div>
            }
            

            <div className="container">
                <div className={styles.b3}>
                    <div className={styles.b3_title}>ТРЕНИРОВКИ ОТ&nbsp;10&nbsp;до&nbsp;60&nbsp;МИНУТ<br/> НА&nbsp;ВСЕ ГРУППЫ МЫШЦ</div>
                    <div className={styles.b3_block}>
                        <div className={styles.b3_block__left}>
                            <div className={styles.b3_block__left_title}>Новые тренировки<br/> <span>каждую</span> неделю</div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/hkj.png" alt="" />
                        </div>
                        <div className={styles.b3_block__right}>
                            <div className={styles.b3_block__item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/b3_1.svg" alt="" />
                                <div>От&nbsp;новичка до&nbsp;профи</div>
                                <p>Занятия для любого уровня<br/> подготовки</p>
                            </div>
                            <div className={styles.b3_block__item}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/b3_2.svg" alt="" />
                                <div>с&nbsp;инвентарём и&nbsp;без</div>
                                <p>Универсальные тренировки<br/> для любых условий</p>
                            </div>
                        </div>
                    </div>

                    {ver != 2 ? <a href="/l/price-new/" target='_blank' className={styles.btn} onClick={()=>{window.yaCounter70253455.reachGoal('do-it_2_начать_тренироваться'); return true;}}>оформить подписку</a> : null }
                </div>
            </div>
        </div>
    )
}

export default Trainings;