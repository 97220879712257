export const ASPECT_RATIO_2_05 = 2.05;

export const SPACING_5 = ["4px", "4px", "5px"];
export const SPACING_7 = ["6px", "6px", "7px"];
export const SPACING_10 = ["8px", "8px", "10px"];
export const SPACING_12 = ["10px", "10px", "12px"];
export const SPACING_15 = ["12px", "12px", "15px"];
export const SPACING_20 = ["15px", "15px", "20px"];
export const SPACING_25 = ["20px", "20px", "25px"];
export const SPACING_30 = ["24px", "24px", "30px"];
export const SPACING_32 = ["25px", "25px", "32px"];
export const SPACING_35 = ["28px", "28px", "35px"];
export const SPACING_40 = ["32px", "32px", "40px"];
export const SPACING_45 = ["35px", "35px", "45px"];
export const SPACING_50 = ["40px", "40px", "50px"];
export const SPACING_65 = ["52px", "52px", "65px"];
export const SPACING_72 = ["56px", "56px", "72px"];
export const SPACING_100 = ["77px", "77px", "100px"];
export const SPACING_150 = ["120px", "120px", "150px"];

export const BORDER_RADIUS_2 = ["2px", "2px", "2px"];
export const BORDER_RADIUS_5 = ["4px", "4px", "5px"];
export const BORDER_RADIUS_10 = ["8px", "8px", "10px"];
export const BORDER_RADIUS_20 = ["16px", "16px", "20px"];
export const BORDER_RADIUS_30 = ["16px", "24px", "30px"];
export const BORDER_RADIUS_40 = ["20px", "31px", "40px"];

export const FONT_SIZE_16 = ["13px", "13px", "16px"];
export const FONT_SIZE_18 = ["14px", "14px", "18px"];
export const FONT_SIZE_20 = ["16px", "16px", "20px"];
export const FONT_SIZE_24 = ["18px", "18px", "24px"];
export const FONT_SIZE_25 = ["20px", "20px", "25px"];
export const FONT_SIZE_28 = ["20px", "22px", "28px"];
export const FONT_SIZE_32 = ["24px", "24px", "32px"];
export const FONT_SIZE_36 = ["24px", "28px", "36px"];
export const FONT_SIZE_48 = ["28px", "38px", "48px"];
export const FONT_SIZE_64 = ["50px", "50px", "64px"];

export const ICON_FONT_SIZE_24 = ["24px", "18px", "24px"];
export const ICON_FONT_SIZE_32 = ["32px", "24px", "32px"];
export const ICON_FONT_SIZE_48 = ["48px", "38px", "48px"];
export const ICON_FONT_SIZE_64 = ["64px", "50px", "64px"];

export const COLOR_BLACK = "#000000";
export const COLOR_BLACK_OPACITY_01 = "rgba(0, 0, 0, 0.1)";
export const COLOR_BLACK_OPACITY_025 = "rgba(0, 0, 0, 0.25)";
export const COLOR_BLACK_OPACITY_05 = "rgba(0, 0, 0, 0.5)";
export const COLOR_BLACK_OPACITY_075 = "rgba(0, 0, 0, 0.75)";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_WHITE_OPACITY_001 = "rgba(255, 255, 255, 0.01)";
export const COLOR_WHITE_OPACITY_01 = "rgba(255, 255, 255, 0.1)";
export const COLOR_WHITE_OPACITY_025 = "rgba(255, 255, 255, 0.25)";
export const COLOR_WHITE_OPACITY_05 = "rgba(255, 255, 255, 0.5)";
export const COLOR_WHITE_OPACITY_075 = "rgba(255, 255, 255, 0.75)";

export const COLOR_PINK = "#EE4777";
export const COLOR_PINK_HOVER = "#FF759C";
export const COLOR_BG_PRIMARY_DARK = "#17171C";
export const COLOR_BG_PRIMARY_LIGHT = "#ECECEC";

export const BLUR_5 = "blur(5px)";
export const BLUR_10 = "blur(10px)";
export const BLUR_20 = "blur(20px)";
export const BLUR_25 = "blur(25px)";
export const BLUR_30 = "blur(30px)";
export const BLUR_50 = "blur(50px)";
export const BLUR_100 = "blur(100px)";

export const LOGIN_FORM_CONTAINER_WIDTH = ["320px", "365px", "470px"];
