import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";

import styles from './reviews.module.css';

const Reviews = ({scroll}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.reviews}>
            <div className="container">
                <div className={styles.reviews_title}>А&nbsp;вот что пишут наши<br/> ученицы о&nbsp;курсе</div>
                
                <div className={styles.block}>
                    <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                    </div>                        
                    <Swiper
                        speed={800}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        slidesPerView={1}
                        spaceBetween={20}
                        autoHeight={true}
                        loop={true}
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        modules={[Navigation]}
                        className={`${styles.swiper}`}
                    >
                        
                        {window.innerWidth > 576 ?
                        <>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_1.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_2.png" alt="" />
                        </SwiperSlide>
                        </>
                        :
                        <>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_3.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_4.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_5.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_6.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_7.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/rev_8.png" alt="" />
                        </SwiperSlide>
                        </>
                        }
                        
                        
                    </Swiper>
                    
                    <div ref={navigationNextRef} className={styles.slider_btn_next}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                    </div>
                </div>

                <div className={styles.btn} onClick={scroll}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Записаться на&nbsp;курс</p>
                </div>
            </div>
        </div>
    )
}

export default Reviews;