import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import styles from './paywall.module.css';


const Paywall = ({fatAnswer, qmaintarget, qhowlong, qhowmany, qtrainings, phys}) => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();
    const [widget2] = useInstance();
    const [date, setDate] = useState(Date.now() + 600000);
    const [count, setCount] = useState(3)

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const showMore = () => {
      setCount(count + 3)
  }

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere2' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    // let nextMidnight = new Date();
    // nextMidnight.setHours(24, 0, 0, 0);
    // const now = new Date();
    // const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                {/* <div>{hourzero}{hours} <span>:</span></div> */}
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <div className={styles.main}>
          <div className={styles.logo}>
            <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
          </div>

          <div className={styles.block}>

            <div className={styles.b1}>
              <div className={styles.i1}>
                <div className={styles.i1_title}>Сейчас</div>
                <img src={fatAnswer == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 2 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : fatAnswer == 3 ? 'https://gymteam.fit/quiz/pics/w-3.jpg' : 'https://gymteam.fit/quiz/pics/w-4.jpg'} alt="" />
                <div className={styles.st1}>Процент жира <span>{fatAnswer == 1 ? '10-19%' : fatAnswer == 2 ? '20-29%' : fatAnswer == 3 ? '30-39%' : '>40%'}</span></div>
              </div>
              <div className={styles.i1}>
                <div className={styles.i1_title}>После программы</div>
                <img src={fatAnswer == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 2 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 3 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : 'https://gymteam.fit/quiz/pics/w-2.jpg'} alt="" />
                <div className={styles.st1}>Процент жира <span>{fatAnswer == 1 ? '10-19%' : fatAnswer == 2 ? '10-19%' : fatAnswer == 3 ? '20-29%' : '20-29%'}</span></div>
              </div>
            </div>

            <div className={styles.b3}>
              <div className={styles.b3_left}>
                <div className={styles.countdown}>
                  <p>Вам доступна<br/> <span>скидка&nbsp;60%</span></p>
                  <div className={styles.countdown_timer}>
                        <p>в&nbsp;течение&nbsp;</p>
                        <Countdown date={date} renderer={renderer} />
                  </div>
                </div>
              </div>
              <div className={styles.b3_right}>
                <div className={styles.btn1} onClick={scroll}>Получить план</div>
              </div>
            </div>

            <div className={styles.aha}>
                <div className={styles.aha_t1}>Ваша цель и&nbsp;предпочтения</div>
                <div className={styles.aha_b3}>
                    <div className={styles.aha_b3_item}>
                        <div className={styles.aha_b3_item_q}>основная цель</div>
                        <div className={styles.aha_b3_item_a}>{qmaintarget}</div>
                    </div>
                    <div className={styles.aha_b3_item}>
                        <div className={styles.aha_b3_item_q}>уровень<br/> подготовки</div>
                        <div className={styles.aha_b3_item_a}>{phys < 2 ? 'Начальный' : phys > 4 ? 'Нормальный' : 'Средний'}</div>
                    </div>
                    <div className={styles.aha_b3_item}>
                        <div className={styles.aha_b3_item_q}>количество тренировок<br/> в&nbsp;неделю</div>
                        <div className={styles.aha_b3_item_a}>{qhowmany}</div>
                    </div>
                    <div className={styles.aha_b3_item}>
                        <div className={styles.aha_b3_item_q}>продолжительность<br/> тренировки</div>
                        <div className={styles.aha_b3_item_a}>{qhowlong}</div>
                    </div>
                    <div className={styles.aha_b3_item}>
                        <div className={styles.aha_b3_item_q}>предпочтения<br/> по&nbsp;тренировкам</div>
                        <div className={styles.aha_b3_item_a}>{qtrainings}</div>
                    </div>
                </div>
            </div>

            <div className={styles.pl}>
                <div className={styles.pl_t1}>Что вас ждет в&nbsp;подписке GymTeam</div>
                <div className={styles.pl_b1}>
                    <div className={styles.pl_b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/pl1.jpg" alt="" />
                        <p>Персональный план тренировок</p>
                    </div>
                    <div className={styles.pl_b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/pl2.jpg" alt="" />
                        <p>Утренние зарядки</p>
                    </div>
                    <div className={styles.pl_b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/pl3.jpg" alt="" />
                        <p>Тренировки на&nbsp;растяжку</p>
                    </div>
                    <div className={styles.pl_b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/pl4.jpg" alt="" />
                        <p>Фейсфитнес</p>
                    </div>
                    <div className={styles.pl_b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/pl5.jpg" alt="" />
                        <p>Новые тренировки каждую неделю</p>
                    </div>
                    <div className={styles.pl_b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/pl6.jpg" alt="" />
                        <p>Реальный прогресс</p>
                    </div>
                </div>
            </div>
            

            <div className={'scrollhere ' + styles.b6}>Выберите вариант подписки GymTeam</div>

            <div className={styles.b7}>
              <div className={`${styles.i7} + ${chose == 0 && styles.i7_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(0); setChose(0)}}>
                <div className={styles.i7_left}>
                  <p>1 неделя</p>
                  <span><em>748&nbsp;₽</em> 299&nbsp;₽/нед</span>
                </div>
                <div className={styles.i7_right}>
                  <p>43₽<span>/день</span></p>
                </div>
              </div>
              <div className={styles.i7_top}>САМЫЙ ПОПУЛЯРНЫЙ</div>
              <div className={`vbcb ${styles.i7} + ${chose == 1 && styles.i7_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(1); setChose(1)}}>
                <div className={styles.i7_left}>
                  <p>4 недели</p>
                  <span><em>2475&nbsp;₽</em> 990&nbsp;₽/мес</span>
                </div>
                <div className={styles.i7_right}>
                    <p>35₽<span>/день</span></p>
                </div>
              </div>
              <div className={`${styles.i7} + ${chose == 2 && styles.i7_active}`} onClick={()=>{widget.resetOrder(); widget.selectByIndex(2); setChose(2)}}>
                <div className={styles.i7_left}>
                  <p>12 недель</p>
                  <span><em>6475&nbsp;₽</em> 2590&nbsp;₽/3&nbsp;мес</span>
                </div>
                <div className={styles.i7_right}>
                    <p>31₽<span>/день</span></p>
                </div>
              </div>
            </div>

            <div className="quiz_start">
              <SalesWidget hook={widget} mandatoryFields={'only_phone'} id={window.lps_settings.forms.quiz_2_id} buttonText={chose == 0 ? 'Оплатить 299 ₽' : chose == 1 ? 'Оплатить 990 ₽' : 'Оплатить 2590 ₽'} onPaymentSelect={()=>{window.yaCounter70253455.reachGoal('onPaymentSelect'); return true; }}/>
            </div>

            <div className={styles.b8}>
              <ul>
                <li><b>Это подписка с&nbsp;автоматическим продлением.</b></li>
                <li>Сегодня GymTeam спишет с&nbsp;вас {chose == 0 ? '299' : chose == 1 ? '990' : '2590'} ₽.</li>
                <li>Вы&nbsp;можете отменить подписку в&nbsp;любое время через настройки сервиса GymTeam или обратившись в&nbsp;службу поддержки по&nbsp;адресу <a href="mailto:support@gymteam.ru">support@gymteam.ru</a>.</li>
              </ul>
            </div>

            <div className={styles.b10}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/gar.png" alt="" />
                <div>
                  <p><b>100% гарантия возврата денег</b></p>
                  <p>Мы&nbsp;абсолютно уверены в&nbsp;эффективности наших тренировок и&nbsp;гарантируем результат. Если вы&nbsp;не&nbsp;заметите прогресса за&nbsp;30&nbsp;дней, то&nbsp;мы&nbsp;вернем деньги.</p>
                </div>
            </div>

            <div className={styles.slider_block}>
              <div className={styles.slider_block_title}><span>91% пользователей</span> довольны результатами тренировок с&nbsp;GymTeam</div>
              <div className={styles.slider_block_subtitle}>Достижения наших пользователей</div>
            <Swiper
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              loop
              onSwiper={(swiper) => {
                setTimeout(() => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;

                  swiper.navigation.destroy();
                  swiper.navigation.init();
                  swiper.navigation.update();
                });
              }}
              slidesPerView={1}
              spaceBetween={20}
              modules={[Navigation]}
              className={styles.slider}
            >
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std1.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std2.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std3.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std4.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std5.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std6.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std7.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std8.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std9.jpg" alt="" /></SwiperSlide>
              <SwiperSlide className={styles.slide}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/std10.jpg" alt="" /></SwiperSlide>

              <div className={styles.slider_nav}>
                              <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/arrow.svg" alt="" />
                              </div>
                              <div ref={navigationNextRef} className={styles.slider_btn_next}>
                                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/arrow.svg" alt="" />
                              </div>
                          </div>
            </Swiper>
            </div>

            <div className={styles.b6}>Вот что говорят пользователи GymTeam:</div>

            <div className={styles.b9}>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w1.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Наталья</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>🔥Восторг! впервые приобрела тренировки онлайн, не&nbsp;ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w2.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Ольга Ж</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>это приложение просто спасение! оно заменило спорт зал и&nbsp;все эти групповые тренировки! не&nbsp;трачу время на&nbsp;дорогу и&nbsp;занимаюсь когда хочу 😎 супер! спасибо! 🙏</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w3.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Екатерина Кириллова</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>я&nbsp;попробовала оооочень много приложений но&nbsp;в&nbsp;етом прям есть всееее️ тренировки каторые надо! если вообще ниразу не&nbsp;занималась и&nbsp;страшно начинать это прям отличный способ тренировки дома начать точно👍</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>

              {count > 3 && 
              <>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w4.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Настя</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Блииин, это просто огонь 😍🤸‍♀️ столько упражнений и&nbsp;интерфейс крутой! каждый день открываю с&nbsp;удовольствием 💪💪 за&nbsp;2&nbsp;недели больше разных упражнений сделала чем за&nbsp;весь год с&nbsp;другим популярным приложением 😂👍</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w5.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Ксения Серикова</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Очень удобное приложение, много разных тренировок на&nbsp;любое настроение.</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w6.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Даша Хасьянова</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Давно пользуюсь приложением! Очень довольна! Много программ, много интересной и&nbsp;полезной информации, отличная обратная связь! Рекомендую</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              </>
              }
              {count > 6 && 
              <>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w7.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Яна Труфанова</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Очень классное приложение! Ничего не&nbsp;лагает, все супер работать, очень грамотно составлено!</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w8.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Марина</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Третий год занимаюсь на&nbsp;вашей платформе. Уже есть полюбившиеся тренера и&nbsp;тренировки. А&nbsp;теперь и&nbsp;каждую неделю новые, мне очень нравятся!</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w9.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Алёна</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Да&nbsp;мне нравится платформа и&nbsp;тренировки(Разнообразные, интересные) и&nbsp;я&nbsp;планирую пользоваться ей&nbsp;дальше. Рекомендую</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              </>
              }
              {count > 9 && 
              <>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w10.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Ксюша</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Очень довольна! Спасибо. Великолепные тренера!!! Классная запись!!!</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w11.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Анастасия Шкуратова</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Ура!!! спасибо!!! храни вас тренировочный боженька</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              <div className={styles.i9}>
                <div className={styles.i9_top}>
                  <div className={styles.i9_top_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/w12.jpg" alt="" />
                  </div>
                  <div className={styles.i9_top_text}>
                    <p>Татьяна Хрусталева</p>
                  </div>
                </div>
                <div className={styles.i9_middle}>Приложение удобное, тренировки от&nbsp;грамотных преподавателей. Мне даются легко и&nbsp;делаю с&nbsp;удовольствием. Раньше самостоятельно не&nbsp;могла заниматься, с&nbsp;приложением-легко. Составили индивидуальный план для меня, это очень ценно. Спасибо большое за&nbsp;ваш труд. Рекомендую всем. Действительно стоящая подписка!</div>
                <div className={styles.i9_bottom}>
                  <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/stars.png" alt="" />
                </div>
              </div>
              </>
              }

              {count < 12 && <div className={styles.i9_btn} onClick={()=>showMore()}>Показать еще</div>}
            

              <div className={styles.b3}>
              <div className={styles.b3_left}>
                <div className={styles.countdown}>
                  <p>Вам доступна<br/> <span>скидка&nbsp;60%</span></p>
                  <div className={styles.countdown_timer}>
                        <p>в&nbsp;течение&nbsp;</p>
                        <Countdown date={date} renderer={renderer} />
                  </div>
                </div>
              </div>
              <div className={styles.b3_right}>
                <div className={styles.btn1} onClick={scroll2}>Получить план</div>
              </div>
            </div>

            <div className={styles.b1}>
              <div className={styles.i1}>
                <img src={fatAnswer == 1 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 2 ? 'https://gymteam.fit/quiz/pics/w-1.jpg' : fatAnswer == 3 ? 'https://gymteam.fit/quiz/pics/w-2.jpg' : 'https://gymteam.fit/quiz/pics/w-2.jpg'} alt="" />
              </div>
              <div className={styles.i1_last}>Ваш результат через 4&nbsp;недели</div>
            </div>
            
            <div className={'scrollhere2 ' + styles.b6}>Выберите вариант подписки GymTeam</div>

            <div className={styles.b7}>
              <div className={`${styles.i7} + ${chose == 0 && styles.i7_active}`} onClick={()=>{widget2.resetOrder(); widget2.selectByIndex(0); setChose(0)}}>
                <div className={styles.i7_left}>
                  <p>1 неделя</p>
                  <span><em>748&nbsp;₽</em> 299&nbsp;₽/нед</span>
                </div>
                <div className={styles.i7_right}>
                  <p>43₽<span>/день</span></p>
                </div>
              </div>
              <div className={styles.i7_top}>САМЫЙ ПОПУЛЯРНЫЙ</div>
              <div className={`vbcb2 ${styles.i7} + ${chose == 1 && styles.i7_active}`} onClick={()=>{widget2.resetOrder(); widget2.selectByIndex(1); setChose(1)}}>
                <div className={styles.i7_left}>
                  <p>4 недели</p>
                  <span><em>2475&nbsp;₽</em> 990&nbsp;₽/мес</span>
                </div>
                <div className={styles.i7_right}>
                    <p>35₽<span>/день</span></p>
                </div>
              </div>
              <div className={`${styles.i7} + ${chose == 2 && styles.i7_active}`} onClick={()=>{widget2.resetOrder(); widget2.selectByIndex(2); setChose(2)}}>
                <div className={styles.i7_left}>
                  <p>12 недель</p>
                  <span><em>6475&nbsp;₽</em> 2590&nbsp;₽/3&nbsp;мес</span>
                </div>
                <div className={styles.i7_right}>
                    <p>31₽<span>/день</span></p>
                </div>
              </div>
            </div>

            <div className="quiz_start">
              <SalesWidget hook={widget2} mandatoryFields={'only_phone'} id={window.lps_settings.forms.quiz_2_id} buttonText={chose == 0 ? 'Оплатить 299 ₽' : chose == 1 ? 'Оплатить 990 ₽' : 'Оплатить 2590 ₽'} onPaymentSelect={()=>{window.yaCounter70253455.reachGoal('onPaymentSelect'); return true; }}/>
            </div>
              
            </div>
          
          </div>
        </div>
      </>
    );
}

export default Paywall;
