import styles from './newprograms.module.css';

const Newprograms = () => {
    return (
        <div className={styles.newprograms}>
            <div className="container">
                <div className={styles.newprograms_title}>9 новых программ БЕСПЛАТНО</div>
                <div className={styles.newprograms_subtitle}>Вы бы получили, если оформили подписку ещё в ОКТЯБРЕ</div>
                <div className={styles.newprograms_block}>
                    <div className={styles.newprograms_item}>
                        <div className={styles.newprograms_item__title}>Октябрь</div>
                        <ul>
                            <li>Power Yoga</li>
                            <li>Силовые тренировки для&nbsp;новичков</li>
                        </ul>
                    </div>
                    <div className={styles.newprograms_item}>
                        <div className={styles.newprograms_item__title}>Ноябрь</div>
                        <ul>
                            <li>Силовые тренировки для&nbsp;продвинутых</li>
                        </ul>
                    </div>
                    <div className={styles.newprograms_item}>
                        <div className={styles.newprograms_item__title}>Декабрь</div>
                        <ul>
                            <li>Табата на&nbsp;каждый день</li>
                            <li>HIIT на&nbsp;каждый день</li>
                            <li>Пилатес с&nbsp;оборудованием</li>
                        </ul>
                    </div>
                    <div className={styles.newprograms_item}>
                        <div className={styles.newprograms_item__title}>Январь</div>
                        <ul>
                            <li>Силовые интервальные тренировки</li>
                            <li>Растяжка на&nbsp;каждый день</li>
                            <li>Фитнес-бокс</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newprograms;