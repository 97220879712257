import { useState } from 'react';
import styles from './students2.module.css';

const Students2 = () => {
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/1_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/2_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/3_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/4_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/5_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/6_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/7_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/8_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/9_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/10_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/11_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/12_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/13_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/14_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/15_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/16_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/1_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/2_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/3_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/4_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/5_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/6_1.JPG'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/2/7_1.JPG'},
    ]);

    const [isModal, setIsModal] = useState(false)
    const [activeSlide, setActiveSlide] = useState(null)

    return (
        <div className={styles.preims}>
        
            <div className={styles.title2}>Тебе нужно лишь<br/> включить видео </div>
            <div className={styles.subtitle2}>с&nbsp;телефона, планшета<br/> или ноутбука и&nbsp;повторять за&nbsp;Катей</div>
            <div className={styles.block2}>
                <div className={styles.item2}>

                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/phone.png" alt="" />
                    
                </div>

                <div className={styles.item3}>
                    <p><span>Катя показывает<br/> технику упражнений с&nbsp;разных ракурсов&nbsp;&mdash;</span> ты&nbsp;точно разберёшься, как правильно двигаться</p>
                </div>
                <div className={styles.item3}>
                    {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/whom_clock.png" alt="" /> */}
                    <p><span>Длительность тренировок всего 40&ndash;60&nbsp;минут.</span><br/> Это оптимальное время, за&nbsp;которое ты&nbsp;детально проработаешь мышцы и&nbsp;не&nbsp;перетренируешься</p>
                </div>
                <div className={styles.item3}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/logo.svg" alt="" />
                    <p>Все тренировки проходят на&nbsp;удобной платформе GymTeam &mdash; ты можешь зайти с&nbsp;любого устройства</p>
                </div>
                <div className={styles.item3}>
                    <p><span>Подробные объяснения помогут не&nbsp;травмироваться</span> и&nbsp;научиться чувствовать нужные мышцы</p>
                </div>
            </div>
        </div>
    )
}

export default Students2;