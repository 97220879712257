import './readyform.css';
import Countdown from 'react-countdown';
import styles from './start.module.css';
import { SalesWidget } from "@com.marathonium/web2-ui";

const Readyform = ({date, renderer}) => {
    return (
        <div className="readyform">
            <div className="container">
            <div className="readyform_title">ГОТОВЫ ВЕРНУТЬСЯ<br/> В ДОРОДОВУЮ ФОРМУ</div>
            <div className="readyform_subtitle">и вновь полюбить своё тело за&nbsp;2&nbsp;месяца?</div>
            <div className="readyform_note">Забирайте курс «Фитнес-восстановление после родов» по спеццене</div>
            <div className="readyform_dates">
                <p>Доступ — <strong>1 год</strong></p>
                <p>Старт — <strong>{window.lps_settings.dates.mainlands}</strong></p>
            </div>
            <div className="readyform_countdown">
                <p>Предложение сгорит через:</p>
                <Countdown date={Date.now() + date} renderer={renderer} />
            </div>
            <SalesWidget id={window.lps_settings.forms.afterpregnancy_id} />
            <div className="readyform_start_info">
                <img src="https://gymteam.kinescopecdn.net/img/l/marafon_str2/tnkf_icon.svg" alt="" />
                <p><span>ОТ 1&nbsp;317&nbsp;₽ В МЕСЯЦ</span></p>
                <p>Этот марафон можно забрать в рассрочку. На 6&nbsp;месяцев без процентов и&nbsp;предварительного взноса, <strong>от 1&nbsp;317&nbsp;рублей в&nbsp;месяц</strong> (только для граждан РФ).<br/> Первый платёж через 30&nbsp;дней.</p>
            </div>
            </div>
        </div>
    )
}

export default Readyform;