import styles from './novelties.module.css';

const Novelties = () => {
    return (
        <div className={styles.novelties}>
            <div className="container">
                <div className={styles.novelties_title}>Уже в феврале<br/> вас ждут 3&nbsp;новинки</div>
                <div className={styles.novelties_block}>
                    <div className={styles.novelties_item}>
                        <div className={styles.novelties_info}>
                            <div className={styles.novelties_info__tag}>Продвинутый уровень</div>
                            <div className={styles.novelties_info__title}>Core</div>
                            <div className={styles.novelties_info__text}><strong>Цель:</strong> укрепить мышцы кора (прямая мышца живота, внешние внутренние косые, поперечная мышца, ягодицы, мышцы спины и&nbsp;мышцы тазового дна), создать красивый пресс.</div>
                            <div className={styles.novelties_info__time}>15&nbsp;тренировок на&nbsp;20-30&nbsp;минут</div>
                        </div>
                    </div>
                    <div className={styles.novelties_item}>
                        <div className={styles.novelties_info}>
                            <div className={styles.novelties_info__tag}>Начальный уровень</div>
                            <div className={styles.novelties_info__title}>Силовые тренировки на&nbsp;каждый день</div>
                            <div className={styles.novelties_info__text}><strong>Цель:</strong> улучшить выносливость и координацию движений, адаптировать тело к возрастающим нагрузкам.</div>
                            <div className={styles.novelties_info__time}>12&nbsp;тренировок на&nbsp;20-30&nbsp;минут</div>
                        </div>
                    </div>
                    <div className={styles.novelties_item}>
                        <div className={styles.novelties_info}>
                            <div className={styles.novelties_info__tag}>Продвинутый уровень</div>
                            <div className={styles.novelties_info__title}>Силовые тренировки</div>
                            <div className={styles.novelties_info__text}><strong>Цель:</strong> повысить восприимчивость тела к&nbsp;нагрузкам и&nbsp;общую выносливость, улучшить координацию движений.</div>
                            <div className={styles.novelties_info__time}>12&nbsp;тренировок на&nbsp;20-30&nbsp;минут</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Novelties;