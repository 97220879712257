import styles from './whom.module.css';

const Whom = () => {
    return (
        <div className={styles.whom}>
            <div className="container">
                <div className={styles.whom_title}>Сталкиваешься<br/> со&nbsp;следующими проблемами?</div>
                <div className={styles.whom_block}>
                    <div className={styles.whom_item}>
                        <div className={styles.whom_item_top}>
                            <div className={styles.whom_item_number}>01</div>
                            <div className={styles.whom_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/whom_1.svg" alt="" />
                            </div>
                        </div>
                        <div className={styles.whom_item_info}>
                            <div className={styles.whom_item_title}>Боишься получить травму или перекачаться</div>
                            <div className={styles.whom_item_text}>Думаешь, что от&nbsp;приседаний со&nbsp;штангой появятся грыжи, а&nbsp;от&nbsp;тяги руками нарастишь бицепсы, как у&nbsp;качка.</div>
                        </div>
                    </div>
                    <div className={styles.whom_item}>
                        <div className={styles.whom_item_top}>
                            <div className={styles.whom_item_number}>02</div>
                            <div className={styles.whom_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/whom_2.svg" alt="" />
                            </div>
                        </div>
                        <div className={styles.whom_item_info}>
                            <div className={styles.whom_item_title}>Медленный прогресс в&nbsp;домашних тренировках</div>
                            <div className={styles.whom_item_text}>Тебе не&nbsp;хватает нагрузки и&nbsp;ты&nbsp;хочешь быстрее нарастить мышечные объёмы.</div>
                        </div>
                    </div>
                    <div className={styles.whom_item}>
                        <div className={styles.whom_item_top}>
                            <div className={styles.whom_item_number}>03</div>
                            <div className={styles.whom_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/whom_3.svg" alt="" />
                            </div>
                        </div>
                        <div className={styles.whom_item_info}>
                            <div className={styles.whom_item_title}>Надоели однотипные тренировки и&nbsp;медленный прогресс в&nbsp;зале</div>
                            <div className={styles.whom_item_text}>Не&nbsp;знаешь, как разнообразить монотонные тренировки, перестаёшь замечать прогресс.</div>
                        </div>
                    </div>
                    <div className={styles.whom_item}>
                        <div className={styles.whom_item_top}>
                            <div className={styles.whom_item_number}>04</div>
                            <div className={styles.whom_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/whom_4.svg" alt="" />
                            </div>
                        </div>
                        <div className={styles.whom_item_info}>
                            <div className={styles.whom_item_title}>Стесняешься ходить в&nbsp;зал или не&nbsp;знаешь, что там делать</div>
                            <div className={styles.whom_item_text}>Боишься, что будешь чувствовать себя в&nbsp;зале не&nbsp;на&nbsp;своём месте: не&nbsp;знаешь, как обращаться с&nbsp;тренажёрами, стесняешься неправильно выполнить упражнения, боишься насмешек.</div>
                        </div>
                    </div>
                    <div className={styles.whom_item}>
                        <div className={styles.whom_item_top}>
                            <div className={styles.whom_item_number}>05</div>
                            <div className={styles.whom_item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/whom_5.svg" alt="" />
                            </div>
                        </div>
                        <div className={styles.whom_item_info}>
                            <div className={styles.whom_item_title}>Считаешь, что с&nbsp;помощью онлайн-тренировок невозможно получить результат</div>
                            <div className={styles.whom_item_text}>С&nbsp;курсом для зала вы&nbsp;получаете не&nbsp;только готовую пошаговую программу тренировок, но&nbsp;и&nbsp;личного тренера с&nbsp;15-летним опытом на&nbsp;экране своего телефона.</div>
                        </div>
                    </div>
                </div>

                <div className={styles.whom_text}>Мы&nbsp;часто слышали про подобные проблемы и&nbsp;страхи.<br/> И&nbsp;разработали курс, который комплексно их&nbsp;решит.</div>
                <div className={styles.whom_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymmarch/whom_line.png" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/line.png" alt="" />
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/arrow.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Whom;