import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from './start.module.css';
import { useState } from 'react';

const Start = ({renderer}) => {
    const [date, setDate] = useState(Date.now() + 900000);
    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

                <div className={styles.start_block}>
                    <div className={styles.start_block_title}>Успей записаться на&nbsp;курс для зала<br/> <span>по&nbsp;самой выгодной цене</span></div>
                    <div className={styles.start_block_subtitle}>Попади в&nbsp;число 100&nbsp;счастливиц, которые успеют<br/> воспользоваться наибольшей <b>выгодой в&nbsp;20%</b></div>
                    <div className={styles.start_remains}>Осталось мест по супер цене: <span>{window.lps_settings.other.count}</span></div>
                    <div className={styles.n_dates}>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/pr2.svg" alt="" />
                            <p>Старт тренировок&nbsp;&mdash; <b>15 октября</b></p>
                        </div>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/pr1.svg" alt="" />
                            <p>Доступ к&nbsp;программе&nbsp;&mdash; <b>6&nbsp;месяцев</b></p>
                        </div>
                    </div>
                    <div className={styles.prices}>
                        <div>6390&nbsp;₽</div>
                        <div>7&nbsp;990&nbsp;₽</div>
                        <span>(от&nbsp;1065&nbsp;₽ в&nbsp;месяц)</span>
                    </div>
                    <div className={styles.start_block_subtitle2}>Успей забрать курс по&nbsp;самой выгодной цене<br/> и&nbsp;получить ВСЕ бонусы</div>
                    <div className={styles.promo_countdown}>
                        <div className={styles.promo_countdown_timer}>
                            {/* <p>Спеццена сгорит через:</p> */}
                            <Countdown date={date} renderer={renderer} />
                        </div>
                    </div>
                    {/* <div className={styles.start_remains}>Осталось мест по&nbsp;суперцене: {window.lps_settings.other.count}</div> */}

                    <div className={styles.select_more}>
                        <p>Плюс бонусы:</p>
                        <div>+ 6&nbsp;гайдов в&nbsp;подарок</div>
                        <div>+ чат с&nbsp;куратором на&nbsp;1&nbsp;месяц</div>
                    </div>

                    <div className="salecoursegym_start">
                        <SalesWidget id={window.lps_settings.forms.coursegym_october_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />
                    </div>

                    <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p>

                    <div className={styles.pay}>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Тинькофф-рассрочка</div>
                                <div className={styles.pay_item_text}>Платите потом&nbsp;&mdash; первый платёж через 30&nbsp;дней <b>от&nbsp;1065&nbsp;₽</b> в&nbsp;месяц. Без переплат, без процентов, до&nbsp;6&nbsp;месяцев. Только для граждан&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>По QR-коду</div>
                                <div className={styles.pay_item_text}>Отсканируйте камерой телефона QR-код и&nbsp;перейдите по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить оплату. Удобно со&nbsp;SberPay.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Долями</div>
                                <div className={styles.pay_item_text}>Разделите платёж на&nbsp;4&nbsp;равные части <b>от&nbsp;1597,5&nbsp;₽</b>. Всего на&nbsp;6&nbsp;недель. Без ожидания одобрения банка, переплат и&nbsp;рисков.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>PayPal</div>
                                <div className={styles.pay_item_text}>Введите только электронный адрес и&nbsp;пароль&nbsp;&mdash; не&nbsp;нужно вводить номер карты. Безопасный способ платы, доступный клиентам банков не&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Картой банка&nbsp;РФ<br/> или зарубежного</div>
                                <div className={styles.pay_item_text}>Можете пользоваться своей картой, где&nbsp;бы вы&nbsp;ни&nbsp;находились. Для оплаты картой иностранного банка, выберите оплату через Stripe.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Наличными в&nbsp;терминалах</div>
                                <div className={styles.pay_item_text}>Оплатите ваш заказ наличными через ближайший терминал. Быстро и&nbsp;удобно, без использования кредитных карт и&nbsp;необходимости раскрывать личные данные.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
        </>
    )
}

export default Start;