import { useRef } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Lazy, Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import styles from './slider2.module.css';

const Slider2 = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.slider2}>
            <div className="container">
                <div className={styles.t1}>Наши программы разработаны<br/> <span>топовыми тренерами</span></div>
                <div className={styles.st1}>с&nbsp;отличным образованием и&nbsp;многолетним опытом</div>

                <div className={styles.block}>
                    <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/arrow.png" alt="" />
                    </div>
                    
                    <Swiper 
                        lazy={true}
                        autoHeight={true}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        loop
                        onSwiper={(swiper) => {
                            setTimeout(() => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current
                            swiper.params.navigation.nextEl = navigationNextRef.current
                            
                            swiper.navigation.destroy()
                            swiper.navigation.init()
                            swiper.navigation.update()
                            })
                        }}
                        slidesPerView={1}
                        modules={[Navigation, Lazy]}
                        className={styles.slider}
                    >
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_1.jpg" alt="" className='swiper-lazy' />
                            </div>
                            <div className={styles.slide_right}>
                                <div className={styles.mob_block}>
                                    <div className={styles.mob_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_1.jpg" alt="" className='swiper-lazy' /></div>
                                    <div className={styles.mob_text}>
                                        <div className={styles.slide_name}>мария андреева</div>
                                        <div className={styles.slide_text}>Специалист по&nbsp;анатомии, психологии и&nbsp;мотивации<br/> для начинающих. Тренирует с&nbsp;2009 года</div>
                                    </div>
                                </div>
                                
                                <div className={styles.slide_info}>
                                    <ul>
                                        <li><b>многочисленные специализированные курсы и&nbsp;сертификаты</b>, включая TRX, BOSU, стретчинг и&nbsp;йогу в&nbsp;воздухе</li>
                                        <li><b>более 50&nbsp;забегов</b>, в&nbsp;том числе международные на&nbsp;10&nbsp;км и&nbsp;полумарафоны</li>
                                    </ul>
                                    <ul>
                                        <li><b>2&nbsp;высших образования</b> (психология, педагогика, физическая культура)</li>
                                        <li><b>КМС по&nbsp;акробатике</b>, 1-й взрослый разряд по&nbsp;бегу, победитель и&nbsp;призёр соревнований</li>
                                    </ul>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_2.jpg" alt="" className='swiper-lazy' />
                            </div>
                            <div className={styles.slide_right}>
                                <div className={styles.mob_block}>
                                    <div className={styles.mob_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_2.jpg" alt="" className='swiper-lazy' /></div>
                                    <div className={styles.mob_text}>
                                        <div className={styles.slide_name}>Юлия власова</div>
                                        <div className={styles.slide_text}>Глубокое знание анатомии, психологии и&nbsp;Inside Flow<br/> (хатха-йога с&nbsp;хореографией и&nbsp;ритмичной музыкой).</div>
                                    </div>
                                </div>
                                <div className={styles.slide_info}>
                                    <ul>
                                        <li><b>высшее педагогическое образование</b> &laquo;Психология и&nbsp;педагогика&raquo;</li>
                                        <li><b>дополнительное образование</b>: Московский университет йоги, Международный Йога Альянс (YTTC), преподаватель Inside Flow Американского Йога Альянса</li>
                                    </ul>
                                    <ul>
                                        <li><b>первый сертифицированный преподаватель по&nbsp;Inside Flow в&nbsp;России</b>, единственный преподаватель России с&nbsp;доступом на&nbsp;Junior Teacher Training от&nbsp;Inside Flow Academy</li>
                                    </ul>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_3.jpg" alt="" className='swiper-lazy' />
                            </div>
                            <div className={styles.slide_right}>
                                <div className={styles.mob_block}>
                                    <div className={styles.mob_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_3.jpg" alt="" className='swiper-lazy' /></div>
                                    <div className={styles.mob_text}>
                                        <div className={styles.slide_name}>Мария филлипова</div>
                                        <div className={styles.slide_text}>Чемпионка России, КМС по&nbsp;лёгкой атлетике и&nbsp;жиму<br/> лёжа, МС&nbsp;по&nbsp;тяжёлой атлетике. Тренирует с&nbsp;2005 года</div>
                                    </div>
                                </div>
                                
                                <div className={styles.slide_info}>
                                    <ul>
                                        <li><b>призёр различных соревнований и&nbsp;турниров</b>, в&nbsp;том числе Гераклион, IDOL Throwdown, International Amateur Crosslifting Grand Prix, Tempesto Cup, Hard Games, Чемпионат Москвы по&nbsp;Стронгмену</li>
                                        <li><b>комплексная подготовка</b> по&nbsp;различным фитнес-направлениям</li>
                                    </ul>
                                    <ul>
                                        <li><b>высшее образование в&nbsp;области физической культуры</b>, сертифицированный инструктор</li>
                                        <li><b>чемпионка Международного Турнира по&nbsp;Кроссфит</b> Siberian Showdown и&nbsp;Crossfit Poison Contagion Республика Беларусь</li>
                                    </ul>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide}>
                            <div className={styles.slide_left}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_4.jpg" alt="" className='swiper-lazy' />
                            </div>
                            <div className={styles.slide_right}>
                                <div className={styles.mob_block}>
                                    <div className={styles.mob_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/person_4.jpg" alt="" className='swiper-lazy' /></div>
                                    <div className={styles.mob_text}>
                                        <div className={styles.slide_name}>Никита москвин</div>
                                        <div className={styles.slide_text}>Бодибилдер, эксперт в&nbsp;подготовке к&nbsp;соревнованиям.<br/> Тренирует с&nbsp;2015 года</div>
                                    </div>
                                </div>
                                
                                <div className={styles.slide_info}>
                                    <ul>
                                        <li><b>высшее образование</b> в&nbsp;области физической культуры (КГУФКСТ)</li>
                                        <li><b>КМС по&nbsp;бодибилдингу</b> и&nbsp;фитнесу</li>
                                        <li><b>многократный чемпион и&nbsp;призёр соревнований по&nbsp;бодибилдингу</b>: Men&rsquo;s Physique, Grand Prix Sochi, Muscle Physique, чемпионаты России и&nbsp;Москвы, топ-6&nbsp;OLYMPIA Amateur Moscow</li>
                                    </ul>
                                    <ul>
                                        <li><b>амбассадор BioTech USA</b>, официальный Атлет Uapparel World</li>
                                        <li><b>дополнительные курсы</b>: биомеханика с&nbsp;ЭМГ, фитнес&nbsp;5D, мифы фитнеса</li>
                                    </ul>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className={styles.slide_last}>
                            <p><b>Ещё 28&nbsp;топовых тренера</b>, с&nbsp;которыми мы&nbsp;разработали уникальные программы, адаптированные под нужды современного человека с&nbsp;использованием последних разработок в&nbsp;сфере фитнеса, спорта и&nbsp;реабилитации</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/persons.jpg" alt="" />
                        </SwiperSlide>
                    </Swiper>

                    <div ref={navigationNextRef} className={styles.slider_btn_next}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/arrow.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider2;