import { useEffect } from 'react';
import styles from './promo.module.css';

const Promo = ({onOpen}) => {

    // useEffect(()=>{
    //     if (window.innerWidth > 575) {
    //         var count = 120
    //     } else {
    //         var count = 46
    //     }
    //     var num = 0
    //     var asd = document.querySelector('.asdasdasdsadasdasd span')
    //     var itt = 0
    //     const intervalId = setInterval(()=>{
    //         if(itt < 3) {
    //             asd.style.cssText += `margin-top: -${num+=count}px;`;
    //             itt += 1
    //         } else {
    //             asd.style.cssText += `margin-top: -0px;`;
    //             itt = 0
    //             num = 0
    //         }
    //     }, 2000)
        
    //     return () => clearInterval(intervalId);
    // }, [])
    


    return (
        <div className={styles.promo}>
            <div className={styles.shape_1}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_shape.svg" alt="" /></div>
            <div className={styles.shape_2}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_shape.svg" alt="" /></div>
            <div className={styles.model_1}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_model_1.png" alt="" /></div>
            <div className={styles.model_2}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_model_2.png" alt="" /></div>
            <div className={styles.promo_logo}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/logo.svg" alt="" />
            </div>
            <div className={styles.promo_pretitle}>домашние тренировки</div>
            <div className={styles.promo_title}>Эффективные тренировки<br/> <span>дома</span> на&nbsp;каждый день 
                {/* <div className='asdasdasdsadasdasd'>
                    <span>стройной?</span>
                    <span>энергичной?</span>
                    <span>уверенной?</span>
                    <span>красивой?</span>
                    <span>стройной?</span>
                </div> */}
            </div>
            <div className={styles.promo_text}>В&nbsp;удобное время. В&nbsp;удобном месте</div>
            <div className={styles.promo_mob}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/promo_model_2.png" alt="" />
            </div>
            {/* <a href="/l/price-new/" target='_blank' className={styles.promo_btn} onClick={()=>{window.yaCounter70253455.reachGoal('do-it_1_начать_тренироваться'); return true;}}>Начать тренироваться</a> */}
            <div className={styles.promo_btn} onClick={()=>{onOpen(); window.yaCounter70253455.reachGoal('authwidget'); return true;}}>Начать тренироваться</div>
            <div className={styles.promo_more}>Попробуй бесплатно</div>
        </div>
    )
}

export default Promo;