import { useRef } from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation} from 'swiper'
import 'swiper/css'
import "swiper/css/grid";
import "swiper/css/navigation";
import styles from './students.module.css';

const Students = () => {
    const items = [
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/9.jpg',
            name: 'Галина',
            weight: '55 → 52',
            chest: '89 → 89',
            waist: '72 → 65',
            hips: '91 → 90',
        },
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/10.jpg',
            name: 'Галина',
            weight: '57,7 → 58',
            chest: '83 → 86',
            waist: '67 → 65',
            hips: '94 → 90',
        },
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/1.jpg',
            name: 'Маргарита',
            weight: '65 → 58',
            chest: '90 → 84',
            waist: '75 → 65',
            hips: '99 → 95',
        },
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/2.jpg',
            name: 'Василиса',
            weight: '102,1 → 89',
            chest: '110 → 106',
            waist: '91 → 80',
            hips: '120 → 112',
        },
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/3.jpg',
            name: 'Екатерина',
            weight: '67,8 → 64',
            chest: '96 → 87',
            waist: '85 → 76',
            hips: '105 → 102',
        },
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/4.jpg',
            name: 'Екатерина',
            weight: '64 → 56,9',
            chest: '87 → 80',
            waist: '68 → 62',
            hips: '104 → 93',
        }, 
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/5.jpg',
            name: 'Ольга',
            weight: '84 → 68',
            chest: '99 → 88',
            waist: '84 → 73',
            hips: '110 → 100',
        }, 
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/6.jpg',
            name: 'Ольга',
            weight: '70 → 60',
            chest: '96 → 93',
            waist: '90 → 76',
            hips: '96 → 94',
        }, 
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/7.jpg',
            name: 'Екатерина',
            weight: '48,5 → 49,6',
            chest: '83 → 85',
            waist: '65 → 62',
            hips: '93 → 93',
        }, 
        {
            image: 'https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/8.jpg',
            name: 'Полина',
            weight: '59 → 56,5',
            chest: '83 → 82',
            waist: '70 → 64',
            hips: '95 → 94',
        },
    ]

    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    
    return (
        <div className={styles.studentres}>
            <div className="container">
                <div className={styles.title}>УЖЕ БОЛЕЕ <span>285&nbsp;000&nbsp;ЧЕЛОВЕК</span> ДОСТИГЛИ<br/> ЖЕЛАЕМОГО РЕЗУЛЬТАТА ВМЕСТЕ С&nbsp;КАТЕЙ</div>
                    
            
                <div className={styles.blockswp}>

                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/arrow.svg" alt="" />
                        </div>
                        
                    
                
                <Swiper 
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    loop
                    onSwiper={(swiper) => {
                        setTimeout(() => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current
                        swiper.params.navigation.nextEl = navigationNextRef.current
                        
                        swiper.navigation.destroy()
                        swiper.navigation.init()
                        swiper.navigation.update()
                        })
                    }}
                    slidesPerView={1}
                    spaceBetween={0}
                    modules={[Navigation]}
                    className={styles.slider}
                    breakpoints={{
                        1200: {
                            slidesPerView: 2.5,
                            spaceBetween: 18
                        },
                    }}
                >
                    {items.map(item => 
                    <SwiperSlide key={item.slide_img} className={styles.slide}>
                        <div className={styles.slide_img}>
                            <img src={item.image} alt="" />
                            <p>{item.name}</p>
                        </div>
                        <div className={styles.slide_info}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i1.png" alt="" /> <p><b>Вес:</b>&nbsp;{item.weight}</p></div>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i3.png" alt="" /> <p><b>Грудь:</b>&nbsp;{item.chest}</p></div>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i2.png" alt="" /> <p><b>Талия:</b>&nbsp;{item.waist}</p></div>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/i4.png" alt="" /> <p><b>Бедра:</b>&nbsp;{item.hips}</p></div>
                        </div>
                    </SwiperSlide>
                    )}

                    {/* <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/arrow.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/arrow.svg" alt="" />
                        </div>
                    </div> */}
                </Swiper>

                <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/arrow.svg" alt="" />
                        </div>

                </div>

                </div>
        </div>
    )
}

export default Students;