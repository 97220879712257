import styles from './unique.module.css';

const Unique = ({ver, onOpen}) => {
    return (
        <div className={styles.unique}>
            <div className="container">
                {/* <div className={styles.t1}><span>104&nbsp;879 тренировок</span> выполнили<br/> клиенты за&nbsp;последние 30&nbsp;дней</div>
                {ver != 2 ?
                <div className={styles.b1}>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Оценка<br/> приложения<br/> <b>4,9 из&nbsp;5</b></p>
                    </div>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Более<br/> <b>5&nbsp;тысяч</b> отзывов</p>
                    </div>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Более<br/> <b>56&nbsp;тысяч</b> клиентов</p>
                    </div>
                </div>
                :
                <div className={styles.b1_2}>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Оценка<br/> приложения<br/> <b>4,9 из&nbsp;5</b></p>
                    </div>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Более<br/> <b>5&nbsp;тысяч</b> отзывов</p>
                    </div>
                    <div className={styles.b1_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/leaves.svg" alt="" />
                        <p>Более<br/> <b>56&nbsp;тысяч</b> клиентов</p>
                    </div>
                </div>
                } */}
                

                {ver != 2 ?
                <>
                <div className={styles.t2}>В&nbsp;чем уникальность GymTeam?</div>
                <div className={styles.b2}>

                    <div className={styles.b2_1}>
                        <div className={styles.b2_info}>
                            <div className={styles.b2_info_title}>Биомеханика<br/> движений</div>
                            <div className={styles.b2_info_text}>Улучшаем мобильность суставов и&nbsp;движение опорно-двигательного аппарата</div>
                        </div>
                    </div>
                    <div className={styles.b2_2}>
                        
                        <div className={styles.b2_3}>

                            <div className={styles.b2_5}>

                                <div className={styles.b2_7}>
                                    <div className={styles.b2_info}>
                                        <div className={styles.b2_info_title}>Структурный<br/> подход</div>
                                        <div className={styles.b2_info_text}>Сначала закладывается основа,<br/> затем усовершенствованные техники<br/> и&nbsp;модификации упражнений</div>
                                    </div>
                                </div>
                                <div className={styles.b2_8}>
                                    <div className={styles.b2_9}>
                                        <div className={styles.b2_info}>
                                            <div className={styles.b2_info_title}>Инновационный<br/> метод</div>
                                            <div className={styles.b2_info_text}>В&nbsp;программы интегрируются последние разработки в&nbsp;сфере реабилитации, спорта и&nbsp;фитнеса</div>
                                        </div>
                                    </div>
                                    <div className={styles.b2_9}>
                                        <div className={styles.b2_info}>
                                            <div className={styles.b2_info_title}>Квалификация<br/> тренеров</div>
                                            <div className={styles.b2_info_text}>Программы разработаны топовыми тренерами с&nbsp;отличным образованием и&nbsp;опытом</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={styles.b2_6}>
                                <div className={styles.b2_info}>
                                    <div className={styles.b2_info_title}>Индивидуальный<br/> подход</div>
                                    <div className={styles.b2_info_text}>Тренер всегда напоминает о&nbsp;важности самочувствия и&nbsp;рекомендует двигаться в&nbsp;своей зоне комфорта</div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.b2_4}>
                            <div className={styles.b2_info}>
                                <div className={styles.b2_info_title}>Разнообразие<br/> программ</div>
                                <div className={styles.b2_info_text}>Функциональные тренировки,<br/> силовые, интервальные, табата<br/> и&nbsp;другие</div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className={styles.b3}>
                    <div className={styles.b3_item}>
                        <div className={styles.b3_item_left}>
                            <div className={styles.b2_9}>
                                        <div className={styles.b2_info}>
                                            <div className={styles.b2_info_title}>Инновационный<br/> метод</div>
                                            <div className={styles.b2_info_text}>В&nbsp;программы интегрируются последние разработки в&nbsp;сфере реабилитации, спорта и&nbsp;фитнеса</div>
                                        </div>
                                    </div>
                                    <div className={styles.b2_9}>
                                        <div className={styles.b2_info}>
                                            <div className={styles.b2_info_title}>Квалификация<br/> тренеров</div>
                                            <div className={styles.b2_info_text}>Программы разработаны топовыми тренерами с&nbsp;отличным образованием и&nbsp;опытом</div>
                                        </div>
                                    </div>
                        </div>
                        <div className={styles.b3_item_right}>
                            <div className={styles.b2_1}>
                                <div className={styles.b2_info}>
                                    <div className={styles.b2_info_title}>Биомеханика<br/> движений</div>
                                    <div className={styles.b2_info_text}>Улучшаем мобильность суставов и&nbsp;движение опорно-двигательного аппарата</div>
                                </div>
                            </div>
                        </div>
                    </div>

                                    <div className={styles.b2_7}>
                                    <div className={styles.b2_info}>
                                        <div className={styles.b2_info_title}>Структурный<br/> подход</div>
                                        <div className={styles.b2_info_text}>Сначала закладывается основа,<br/> затем усовершенствованные техники<br/> и&nbsp;модификации упражнений</div>
                                    </div>
                                </div>
                    <div className={styles.b2_4}>
                            <div className={styles.b2_info}>
                                <div className={styles.b2_info_title}>Разнообразие<br/> программ</div>
                                <div className={styles.b2_info_text}>Функциональные тренировки,<br/> силовые, интервальные, табата<br/> и&nbsp;другие</div>
                            </div>
                        </div>
                        <div className={styles.b2_6}>
                                <div className={styles.b2_info}>
                                    <div className={styles.b2_info_title}>Индивидуальный<br/> подход</div>
                                    <div className={styles.b2_info_text}>Тренер всегда напоминает о&nbsp;важности самочувствия и&nbsp;рекомендует двигаться в&nbsp;своей зоне комфорта</div>
                                </div>
                            </div>
                </div>

                </>
                : null
                }

                {/* <a href="/l/price-new/" target='_blank' className={styles.btn} onClick={()=>{window.yaCounter70253455.reachGoal('do-it_3_начать_тренироваться'); return true;}}>Начать тренироваться</a> */}
                <div className={styles.btn} onClick={()=>{onOpen(); window.yaCounter70253455.reachGoal('authwidget'); return true;}}>Начать тренироваться</div>
                <div className={styles.more}>Попробуй бесплатно</div>

            </div>
        </div>
    )
}

export default Unique;