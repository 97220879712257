import { SalesWidget } from "@com.marathonium/web2-ui";

export const TestWidget = () => {
    
  const onPayment = () => {
    window.location.replace('/l/rasprozharka2024');
  }

    return (
      <div>
          <SalesWidget id={window.lps_settings.forms.rasprozharka2024_3_id} onPaymentSelect={onPayment} buttonText="ОФОРМИТЬ ЗАКАЗ" />
      </div>
    );
};