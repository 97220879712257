import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination, Autoplay, Navigation } from "swiper";

import styles from './reviews.module.css';

const Reviews = () => {
    const reviewsItems = [
        'Неожиданно не только понравилась физическая активность, но и эффект от тренировки сродни психотерапии',
        'было очень круто! не ожидала, что мне понравится я не люблю силовые)) намерена продолжить спасибо тренеру🙌',
        'невероятная легкость после упражнений. Благодарю тренера. Даже жаль, что скоро программу закончу',
        'Супер, нет таких слов, чтобы описать как это приятно, ощущать себя гибкой, не скованной, лёгкой. Комплекс отличный. Продолжаю!!!',
        'прекрасное занятия! Так же как и все которые ведёт этот тренер!',
        'после тренировки ощущается энергия в теле и прилив сил после растяжки расслабление и ощущение лёгкости в теле',
        'новые интересные упражнения! раньше таких нигде не встречала👍',
        'было очень понятно и после тренировки все тело расслабленно',
        'Я получила классные ощущения от упражнений, супер!!! и инструктор понятно интересно объясняет!!!🔥',
        'очень классно после тренировки ощущение лёгкости и прилива энергии',
        'все тренировки бомбические, я полюбила заниматься стретчинг-растяжкой, прям в уовльсьиве, нашла своё в спорте! спасибо',
        'Спасибо большое! Нравятся ваши тренировки, спина давно так себя хорошо не чувствовала))',
        'пилатес открылся с новой, неизвестной мне стороны. не думала, что там столько любви к себе и своему телу. очень понравилось!',
        'огонь 🔥 тренировка! интересно и совсем не скучно, а главное не долго и эффективно! буду продолжать этот курс.',
        'супер !! казалось что легко, но в конце вся потная )',
        'лёгкость в теле и в глазах, расслабление, взгляд стал ровнее и плавнее буду чаще делать этот комплекс',
        'грамотно пробудила тело после сна, ощущения отличные',
        'Тренер просто космический. Такая искренняя любовь ко всей планете. Вызывает добрую улыбку)',
        'мне так хорошо , чувствую спокойствие в душе и хорошую работу в теле, без насилия.',
        'Ощущение кайфа) что я преодолела себя. Ощущение легкости. Стимул появился работать над собой.',
        'очень здорово ! позитивный и очень приятный голос тренера очень бодрит',
        'прилив энергии, вдохновение, ощущение расслабленности, хорошая растяжка и интересные новые упражнения',
        'Шикарная тренировка, упражнения новые, раньше такие нигде не видела, эффект ощущается после первого занятия. Чувствую вдохновение, тренер чудесно обьясняет все мелочи при выполнении упражнения',
        'супер ощущения. такое волшебное внутри состояние. ещё хочу…',
        'Легкость и расслабление в теле. Эмоции успокоились.',
        'Супер! Все понравилось :) Заботливые комментарии и постоянный контроль дыхания сделали эту тренировку идеальной.',
        'Невозможно передать словами!! Это просто фантастика!!🔥🔥🔥💖',
        'каждая тренеровка это нежность и любовь к каждой тестичкии тела',
        'Класс, нравится этот формат тренировок- быстро и эффективно 🔥',
        'Круто круто круто! Одно из самых интересных направлений!',
        'Спасибо! Классные упражнения! Мне все понравилось в курсе',
        'не ожидала от себя, но мне очень понравилось! ощущения полного расслабления и Любви к себе, такая гармония, жду следующую тренировку!',
        '🔥Восторг! впервые приобрела тренировки онлайн, не ожидала, что так эффективно пройдёт это вводное занятие! тренер огонь)',
        'очень классные упражнения. лучше чем в платных программах где я была',
        'Появилась лёгкость и по ощущениям шея вытянулась а плечи развернулись',
        'Супер.Такой классный тренер, мне с ней очень нравится заниматься.Спасибо большое.Миллион поцелуйчиков от каждой клеточки моего тела.Хочу еще!',
        'чувствую легкость и энергию во всем теле! это очень здорово!',
        'Это что-то нереальное, как же это приятно, понятно, эффективно, тренер замечательный. Спасибо вам большое!',
        'шикарно! очень нравится, что упражнения не сложные, но достаточно интенсивные! и без прыжков и насилия над собой)))',
        'благодарю за курс, качественно потянулась. тело стало более расслабленное и растянутое',
        '👍 Мне очень нравятся такие короткие занятия йогой, чувствую после них себя бодро весь день',
        'с каждой тренировкой становится легче и спокойнее дышать',
        'Мне понравилось, чувствую себя с каждым днем все лучше и лучше',
        'Отличные упражнения. эффект виден с первого урока. )',
        'это супер упражнения, я даже помолодела!!!!!!!!!!!!!Спасибо!!!!!!!!!!!!!',
        'Чувствуется лёгкость в теле! Плечи стали более открытыми и не зажатыми!',
        'классный тренер, хорошо все обьясняет, упражнения выполняются легко, приятно на него смотреть, в хорошей форме, и хорошо выглядит.',
        'Супер!!! 30 минут пролетели. Очень хороший темп и классное объяснение. я в восторге! Иду дальше)',
        'Класс! Чувствую бодрость и благодарность тела! А самое главное - улыбаюсь!',
        'В теле появилась легкость. Конечно есть куда расти и гибкость улучшать.'
    ]
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.slider}>
            <div className="container">
                <div className={styles.slider_top}>
                    <div className={styles.slider_top__title}>GYMTEAM&nbsp;— опыт,<br/> которым хочется делиться</div>
                    <div className={styles.slider_nav}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/arrow_icon.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/arrow_icon.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <Swiper
                // slidesPerView={5}
                // spaceBetween={46}
                centeredSlides={true}
                // freeMode={false}
                // grabCursor={true}
                speed={1200}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                pagination={{
                    type: "progressbar",
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    576: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        freeMode: true
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1200: {
                        slidesPerView: 6,
                        spaceBetween: 20
                    },
                }}
                onSwiper={(swiper) => {
                    setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    swiper.params.navigation.nextEl = navigationNextRef.current
                    
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                    })
                }}
                modules={[FreeMode, Pagination, Autoplay, Navigation]}
                className={`swiper_gt ${styles.swiper}`}
            >
                {reviewsItems.map((item, index) => {
                    return (
                        <SwiperSlide key={index} className={styles.swiper_slide}>
                            <div className={'gtsale-reviews_item ' + `ri${index+1}`}>
                                <div className="gtsale-reviews_item__img"></div>
                                <p>{item}</p>
                            </div>
                        </SwiperSlide>
                    )
                })}
                
            </Swiper>

        </div>
    );
};

export default Reviews;