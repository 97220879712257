import { useRef } from 'react';
import styles from './programs.module.css';

const Programs = ({scroll}) => {
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()
    const ref8 = useRef()

    function handleOpen (num) {
        num == 1 && ref1.current.classList.toggle(`active${num}`)
        num == 2 && ref2.current.classList.toggle(`active${num}`)
        num == 3 && ref3.current.classList.toggle(`active${num}`)
        num == 4 && ref4.current.classList.toggle(`active${num}`)
        num == 5 && ref5.current.classList.toggle(`active${num}`)
        num == 6 && ref6.current.classList.toggle(`active${num}`)
        num == 7 && ref7.current.classList.toggle(`active${num}`)
        num == 8 && ref8.current.classList.toggle(`active${num}`)
    }

    return (
        <div className={('scrollhere3 ') + styles.programs}>
            <div className="container">
            <div className={styles.programs_sticky}>
                <div className={styles.programs_sticky_left}>
                    <div className={styles.programs_sticky_block}>
                        <div className={styles.programs_title}>Программа построена в&nbsp;формате<br/> &laquo;повторяй за&nbsp;мной&raquo;</div>
                        <div className={styles.programs_subtitle}><span>Курс состоит из&nbsp;4&nbsp;циклов общей длительностью 4,5&nbsp;месяца.</span><br/> Нагрузка добавляется по&nbsp;нарастающей, а&nbsp;тренировочный план<br/> становится разнообразнее по&nbsp;мере твоего прогресса.</div>
                        {/* <div className={styles.programs_date}><img src="https://gymteam.kinescopecdn.net/img/l/2023/decembergym/programs_1.png" alt="" /> <b>Старт</b>&nbsp;— сразу после оплаты</div>
                        <div className={styles.programs_date}><img src="https://gymteam.kinescopecdn.net/img/l/2023/decembergym/programs_2.png" alt="" /> <b>Длительность</b>&nbsp;— 4,5&nbsp;месяца</div> */}
                    </div>
                </div>
                <div className={styles.programs_sticky_right}>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/program_1.png" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/program_1.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_info}>
                                <div className={styles.programs_info_title}><p>Наработка техники</p> <span>6 недель</span></div>
                                <div className={styles.programs_info_subtitle}>
                                    <p>Поставлена правильная техника выполнения базовых упражнений на&nbsp;минимальных весах. Тело готово к&nbsp;увеличению нагрузки для дальнейшей работы на&nbsp;силу, выносливость и&nbsp;рельеф.</p>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(1)}>График</p>
                                    <div className={styles.programs_info_text_inner} ref={ref1}>
                                        <p>пн&nbsp;— ягодицы, база</p>
                                        <p>ср&nbsp;— верх тела</p>
                                        <p>пт&nbsp;— ягодицы, изоляция</p>
                                    </div>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(2)}>Что внутри:</p>
                                    <div className={styles.programs_info_text_inner} ref={ref2}>
                                        <p>Цикл состоит из&nbsp;6-ти тренировок. Ты делаешь их друг за&nbsp;другом первые 2&nbsp;недели по&nbsp;графику, потом повторяешь цикл на&nbsp;3-й и&nbsp;4-й неделях, а&nbsp;потом на&nbsp;5-й и&nbsp;6-й неделях.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/program_2.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/program_2.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_info}>
                                <div className={styles.programs_info_title}><p>Увеличение силовых показателей</p> <span>6 недель</span></div>
                                <div className={styles.programs_info_subtitle}>
                                    <p>Работа на&nbsp;максимальных весах с&nbsp;минимальным количеством повторений. Прогресс силовых показателей, создание крепкого мышечного каркаса.</p>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(3)}>График</p>
                                    <div className={styles.programs_info_text_inner} ref={ref3}>
                                        <p>пн&nbsp;— ягодицы, база</p>
                                        <p>ср&nbsp;— верх тела</p>
                                        <p>пт&nbsp;— ягодицы, изоляция</p>
                                    </div>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(4)}>Что внутри:</p>
                                    <div className={styles.programs_info_text_inner} ref={ref4}>
                                        <p>Цикл состоит из&nbsp;6-ти тренировок. Ты делаешь их друг за&nbsp;другом первые 2&nbsp;недели по графику, потом повторяешь цикл на&nbsp;3-й и&nbsp;4-й неделях, а&nbsp;потом на&nbsp;5-й и&nbsp;6-й неделях.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/program_3.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/program_3.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_info}>
                                <div className={styles.programs_info_title}><p>Наработка выносливости</p> <span>3 недели</span></div>
                                <div className={styles.programs_info_subtitle}>
                                    <p>Проработаны медленные мышечные волокна, которые обычно тяжело прокачать, но&nbsp;они необходимы для создания красивого рельефа и&nbsp;выносливости.</p>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(5)}>График</p>
                                    <div className={styles.programs_info_text_inner} ref={ref5}>
                                        <p>пн&nbsp;— ягодицы, база</p>
                                        <p>ср&nbsp;— верх тела</p>
                                        <p>пт&nbsp;— ягодицы, изоляция</p>
                                    </div>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(6)}>Что внутри:</p>
                                    <div className={styles.programs_info_text_inner} ref={ref6}>
                                        <p>Цикл состоит из&nbsp;3-х тренировок. Ты делаешь их друг за&nbsp;другом в&nbsp;первую неделю цикла по&nbsp;графику, потом повторяешь цикл на 2-й и 3-й неделе.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_info_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/program_4.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/salecoursegym/program_4.jpg" alt="" />
                            </div>
                            <div className={styles.programs_info_info}>
                                <div className={styles.programs_info_title}><p>Работа над&nbsp;мышечными объёмами</p> <span>3 недели</span></div>
                                <div className={styles.programs_info_subtitle}>
                                    <p>Гармонично развиты все группы мышц: подтянутая спина, рельефный живот, идеальная попа.</p>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(7)}>График</p>
                                    <div className={styles.programs_info_text_inner} ref={ref7}>
                                        <p>пн&nbsp;— ягодицы, база</p>
                                        <p>ср&nbsp;— верх тела</p>
                                        <p>пт&nbsp;— ягодицы, изоляция</p>
                                    </div>
                                </div>
                                <div className={`${styles.programs_info_text} bgnfbjfgnb`}>
                                    <p onClick={()=>handleOpen(8)}>Что внутри:</p>
                                    <div className={styles.programs_info_text_inner} ref={ref8}>
                                        <p>Цикл состоит из&nbsp;3-х тренировок. Ты делаешь их друг за&nbsp;другом в&nbsp;первую неделю цикла по&nbsp;графику, потом повторяешь цикл на&nbsp;2-й и&nbsp;3-й неделе.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
                    <div className={styles.btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Хочу на&nbsp;программу</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Programs;