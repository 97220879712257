import styles from './result.module.css';

const Result = ({scroll}) => {

    return (
        <div className={styles.result}>
            <div className="container">
                <div className={styles.result_title}>А&nbsp;правильная система питания<br/> на&nbsp;курсе поможет добиться<br/> результатов быстрее</div>
                <div className={styles.result_subtitle}>Наши диетологи и&nbsp;нутрициологи разработали детальную<br/> и&nbsp;понятную систему питания специально для тренирующихся<br/> в&nbsp;зале. Мы&nbsp;учли:</div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_number}>01</div>
                        <div className={styles.item_title}>Принципы сбалансированного питания</div>
                        <div className={styles.item_text}>Важно потреблять продукты, обеспечивающие организм белками, углеводами, жирами, витаминами и&nbsp;минералами в&nbsp;нужных пропорциях.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_number}>02</div>
                        <div className={styles.item_title}>Питание до&nbsp;и&nbsp;после тренировок</div>
                        <div className={styles.item_text}>Важно питаться так, чтобы обеспечить себя энергией для тренировки и&nbsp;восстановить мышцы и&nbsp;энергозапас после нее.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_number}>03</div>
                        <div className={styles.item_title}>Рациональные<br/> порции</div>
                        <div className={styles.item_text}>Контроль размеров порций помогает избежать переедания и&nbsp;поддерживать здоровый вес, а&nbsp;также контролировать насыщение.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_number}>04</div>
                        <div className={styles.item_title}>Индивидуальный<br/> подход</div>
                        <div className={styles.item_text}>Каждый человек уникален, поэтому важно адаптировать принципы питания и&nbsp;калораж, учитывая фитнес-цели и&nbsp;потребности организма.</div>
                    </div>
                </div>
            </div>

            <div className={styles.sec2}>
                <div className="container">
                    <div className={styles.mob}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/result_bg_mob.jpg" alt="" />
                    </div>
                    <div className={styles.block2}>
                        <div className={styles.block2_title}>В&nbsp;результате ты&nbsp;создашь красивое и&nbsp;подтянутое тело</div>
                        <div className={styles.block2_subtitle}>Оно будет притягивать восхищенные взгляды</div>
                        <div className={styles.promo_btn} onClick={scroll}><img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" /><p>Записаться на&nbsp;курс</p></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Result;