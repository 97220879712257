import { useEffect, useState } from 'react';
import styles from './preims.module.css';

const Preims = () => {
    const [mobV, setMobV] = useState(false)

    const handleScroll = () => {
        const position = window.pageYOffset;
        const targetBlock = document.querySelector('.target_block');
        let heightDifference = position - targetBlock.offsetTop;

        if(window.innerWidth < 768) {
            // if(heightDifference <= 500) {
            //     document.querySelector('.preim_text_block').style.transform = 'translateY(0)'
            //     document.querySelector('.preim_images_block').style.transform = 'translateY(0)'
            // }
            // if(heightDifference > 500 && heightDifference <= 1000) {
            //     document.querySelector('.preim_text_block').style.transform = 'translateY(-100%)'
            //     document.querySelector('.preim_images_block').style.transform = 'translateY(-348px)'
            // }
            // if(heightDifference > 1000 && heightDifference <= 1500) {
            //     document.querySelector('.preim_text_block').style.transform = 'translateY(-200%)'
            //     document.querySelector('.preim_images_block').style.transform = 'translateY(-696px)'
            // }
            // if(heightDifference > 1500 && heightDifference <= 2000) {
            //     document.querySelector('.preim_text_block').style.transform = 'translateY(-300%)'
            //     document.querySelector('.preim_images_block').style.transform = 'translateY(-1044px)'
            // }
            // if(heightDifference > 2000 && heightDifference <= 2500) {
            //     document.querySelector('.preim_text_block').style.transform = 'translateY(-400%)'
            //     document.querySelector('.preim_images_block').style.transform = 'translateY(-1392px)'
            // }
            // if(heightDifference > 2500) {
            //     document.querySelector('.preim_text_block').style.transform = 'translateY(-500%)'
            //     document.querySelector('.preim_images_block').style.transform = 'translateY(-1740px)'
            // }
        } else {
            if(heightDifference >= 0 && heightDifference <= 3500) {
                if(heightDifference <= 500) {
                    document.querySelector('.preim_text_block').style.transform = 'translateY(0)'
                    document.querySelector('.preim_images_block').style.transform = 'translateY(0)'
                }
                if(heightDifference > 500 && heightDifference <= 1000) {
                    document.querySelector('.preim_text_block').style.transform = 'translateY(-100%)'
                    document.querySelector('.preim_images_block').style.transform = 'translateY(-616px)'
                }
                if(heightDifference > 1000 && heightDifference <= 1500) {
                    document.querySelector('.preim_text_block').style.transform = 'translateY(-200%)'
                    document.querySelector('.preim_images_block').style.transform = 'translateY(-1232px)'
                }
                if(heightDifference > 1500 && heightDifference <= 2000) {
                    document.querySelector('.preim_text_block').style.transform = 'translateY(-300%)'
                    document.querySelector('.preim_images_block').style.transform = 'translateY(-1848px)'
                }
                if(heightDifference > 2000 && heightDifference <= 2500) {
                    document.querySelector('.preim_text_block').style.transform = 'translateY(-400%)'
                    document.querySelector('.preim_images_block').style.transform = 'translateY(-2464px)'
                }
                if(heightDifference > 2500) {
                    document.querySelector('.preim_text_block').style.transform = 'translateY(-500%)'
                    document.querySelector('.preim_images_block').style.transform = 'translateY(-3080px)'
                }
            }
        }
        
    };

    useEffect(() => {
        window.innerWidth < 768 ? setMobV(true) : setMobV(false)
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        
        <div className={`target_block ${styles.preims}`}>
                <div className={styles.preims_block}>
                    <div className={styles.preims_block__left}>
                        {!mobV ?
                            <div className={`preim_images_block ${styles.preims_images}`}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_1n.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_2n.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_3.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_4.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_5.jpg" alt="" />
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_6.jpg" alt="" />
                            </div>
                        :
                        <div className={`preim_images_block ${styles.preims_images}`}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preims_1n.jpg" alt="" />
                            </div>
                        }
                    </div>
                    <div className={styles.preims_block__right}>
                        <div className={`preim_text_block ${styles.preims_info}`}>
                            <div className={styles.preims_info__text}>
                                <div className={styles.preims_info__title}>Пора сфокусироваться на&nbsp;своём теле, чтобы&nbsp;раскрыть внутреннюю энергию</div>
                                <div className={styles.preims_info__subtitle}>Функции GymTeam помогут создать условия<br/> для&nbsp;идеальной тренировки каждый день</div>
                            </div>
                            <div className={styles.preims_info__text}>
                                <div className={styles.preims_info__icon}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preim_icon_1.png" alt="" />
                                </div>
                                <div className={styles.preims_info__title}>Сохраняйте<br/> тренировки на&nbsp;телефон</div>
                                <div className={styles.preims_info__subtitle}>Занимайтесь даже без&nbsp;интернета</div>
                            </div>
                            <div className={styles.preims_info__text}>
                                <div className={styles.preims_info__icon}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preim_icon_2.png" alt="" />
                                </div>
                                <div className={styles.preims_info__title}>Открывайте видео в&nbsp;режиме «картинка в&nbsp;картинке»</div>
                                <div className={styles.preims_info__subtitle}>Оставайтесь на связи во время тренировки</div>
                            </div>
                            <div className={styles.preims_info__text}>
                                <div className={styles.preims_info__icon}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preim_icon_3.png" alt="" />
                                </div>
                                <div className={styles.preims_info__title}>Отмечайте любимые тренировки</div>
                                <div className={styles.preims_info__subtitle}>Cоздавайте свой список понравившихся занятий</div>
                            </div>
                            <div className={styles.preims_info__text}>
                                <div className={styles.preims_info__icon}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preim_icon_4.png" alt="" />
                                </div>
                                <div className={styles.preims_info__title}>Включайте трансляцию<br/> на&nbsp;TV</div>
                                <div className={styles.preims_info__subtitle}>Погружайтесь в&nbsp;мир фитнеса с&nbsp;эффектом присутствия</div>
                            </div>
                            <div className={styles.preims_info__text}>
                                <div className={styles.preims_info__icon}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/preim_icon_5.png" alt="" />
                                </div>
                                <div className={styles.preims_info__title}>Получайте напоминания, когда&nbsp;нужен инвентарь</div>
                                <div className={styles.preims_info__subtitle}>Не отвлекайтесь во&nbsp;время тренировки</div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Preims;