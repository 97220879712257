import React, { useEffect } from 'react';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import styles from './style.module.css';
import './style.css';
import Students from './Students';
import Footer from '../../components/Footer';
import { RedirectPrograms } from '../../components/redirect/RedirectProgram';

export const L2024_08_Vitrina = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    
    
    return (
        <>
            <RedirectPrograms />
            <DocumentTitle value='Все онлайн-тренировки Екатерины Усмановой в GymTeam'/>
            <DocumentDescription value='Все курсы и марафоны Кати Усмановой в GymTeam — программы онлайн-фитнеса для дома и зала. Онлайн-тренировки, чтобы быстро похудеть, накачать ягодицы и пресс, сжечь жир и избавиться от целлюлита.' />
            <div className='vitrina_2024'>
                <div className={styles.promo}>
                    <div className="container">
                        <div className={styles.promo_block}>
                            <div className={styles.promo_block__left}>
                                <div className={styles.promo_block__left_title}>ОНЛАЙН-ТРЕНИРОВКИ ДЛЯ ДОМА И&nbsp;ЗАЛА С&nbsp;КАТЕЙ УСМАНОВОЙ</div>
                                <div className={styles.promo_block__left_subtitle}>Авторские фитнес-программы под разные<br/> цели для любого уровня подготовки</div>
                                <div className={styles.promo_block__left_btn} onClick={scroll}>
                                    <p>Выбрать программу</p>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/arrow.svg" alt="" />
                                </div>
                                <div className={styles.promo_block__left_people}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/2.png" alt="" />
                                    <p>С&nbsp;нами тренируются<br/> уже более <span>270&nbsp;000&nbsp;девушек!</span></p>
                                </div>
                            </div>
                            <div className={styles.promo_block__right}>
                                <div className={styles.promo_block__right_title}>Катя Усманова</div>
                                <div className={styles.promo_block__right_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/promo.png" alt="" /></div>
                                <div className={styles.promo_block__right_info}>
                                    <ul>
                                        <li>Профессиональный фитнес-тренер с&nbsp;опытом 15+&nbsp;лет</li>
                                        <li>Вице-чемпионка мира и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                                        <li>Похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;форму за&nbsp;100 дней после родов</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.promo_block__next} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/arrow2.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'scrollhere ' + styles.programs}>
                    <div className="container">
                        <div className={styles.programs_title}>Выбери любую<br/> из&nbsp;8&nbsp;фитнес-программ под свою цель</div>
                        <div className={styles.programs_block}>
                            <div className={styles.programs_block__left}>
                                <div className={styles.programs_block__left_title}>МАРАФОНЫ С&nbsp;ДОМАШНИМИ ТРЕНИРОВКАМИ</div>
                                <div className={styles.programs_block__left_subtitle}>Если хочешь быстро получить первые результаты</div>
                            </div>
                            <div className={styles.programs_block__right}>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/pr4.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>МАРАФОН</div>
                                        <div className={styles.programs_block__right_item_info_t2}>СТРОЙНОСТЬ 1.0</div>
                                        <div className={`${styles.programs_block__right_item_info_t3} ${styles.programs_block__right_item_info_t3b}`}>Похудеть и&nbsp;заметно улучшить качество тела за&nbsp;короткий срок</div>
                                        <a href="/l/marafonstr1" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_3.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>МАРАФОН</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Упругой попы 1.0</div>
                                        <div className={`${styles.programs_block__right_item_info_t3} ${styles.programs_block__right_item_info_t3b}`}>Подтянуть и&nbsp;укрепить мышцы ягодиц и&nbsp;ног</div>
                                        <a href="/l/marafonpopa1" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/pr3.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>МАРАФОН</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Плоского живота</div>
                                        <div className={styles.programs_block__right_item_info_t3}>Укрепить мышцы пресса, избавиться от&nbsp;выпирающего живота</div>
                                        <a href="/l/marafonabs" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_5.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>МАРАФОН</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Антицеллюлитный</div>
                                        <div className={styles.programs_block__right_item_info_t3}>Избавиться от&nbsp;целлюлита, дряблости кожи и&nbsp;улучшить качество всего тела</div>
                                        <a href="/l/anticellulite" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.programs_block__img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/1.svg" alt="" /></div>
                        </div>

                        <div className={styles.programs_block}>
                            <div className={styles.programs_block__left}>
                                <div className={styles.programs_block__left_title}>КУРСЫ ДЛЯ<br/> ДОМА И&nbsp;ЗАЛА</div>
                                <div className={styles.programs_block__left_subtitle}>Если хочешь комплексно проработать всё тело</div>
                            </div>
                            <div className={styles.programs_block__right}>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/leto24/program_6.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>КУРС</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Жиросжигающий</div>
                                        <div className={styles.programs_block__right_item_info_t3}>Сбросить лишний вес и&nbsp;избавиться от&nbsp;жира в&nbsp;проблемных зонах</div>
                                        <a href="/l/fatburning" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/pr1.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>КУРС</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Для зала</div>
                                        <div className={styles.programs_block__right_item_info_t3}>Создать рельефное и&nbsp;подкачанное тело в&nbsp;тренажёрном зале</div>
                                        <a href="/l/coursegym" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.programs_block__img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/2.svg" alt="" /></div>
                        </div>

                        <div className={styles.programs_block}>
                            <div className={styles.programs_block__left}>
                                <div className={styles.programs_block__left_title}>ПРОГРАММЫ<br/> ДЛЯ БЕРЕМЕННЫХ И&nbsp;МОЛОДЫХ МАМ</div>
                                <div className={styles.programs_block__left_subtitle}>Если хочешь подготовить организм к&nbsp;родам и&nbsp;бережно вернуться в&nbsp;форму после рождения малыша</div>
                            </div>
                            <div className={styles.programs_block__right}>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/pr2.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>МАРАФОН</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Восстановление после родов</div>
                                        <div className={styles.programs_block__right_item_info_t3}>Избавиться от&nbsp;лишних килограммов и&nbsp;восстановить тонус мышц</div>
                                        <a href="/l/afterpregnancy" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                                <div className={styles.programs_block__right_item}>
                                    <div className={styles.programs_block__right_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/program_11.png" alt="" /></div>
                                    <div className={styles.programs_block__right_item_info}>
                                        <div className={styles.programs_block__right_item_info_t1}>КУРС</div>
                                        <div className={styles.programs_block__right_item_info_t2}>Для беременных</div>
                                        <div className={styles.programs_block__right_item_info_t3}>Подготовить тело к&nbsp;легким родам, улучшить самочувствие и&nbsp;поддержать форму</div>
                                        <a href="/l/pregnancy" className={styles.programs_block__right_item_info_btn}>Подробнее</a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.programs_block__img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/3.svg" alt="" /></div>
                        </div>
                    </div>
                </div>

                <Students scroll={scroll} />
                <Footer version={7} isColor={'#fff'}/>
            </div>
        </>
    );
};