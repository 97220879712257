import styles from './gtage.module.css';

const Gtage = ({scroll}) => {
    return (
        <div className={styles.gtage}>
            <div className="container">
                <div className={styles.gtage_block}>
                    <div className={styles.gtage_block__left}>
                        <div className={styles.gtage_pretitle}>Если мне больше 40&nbsp;лет?</div>
                        <div className={styles.gtage_title}>GymTeam подходит<br/> для&nbsp;любого возраста<br/> и&nbsp;уровня подготовки</div>
                        <div className={styles.gtage_text}>
                            <p><strong>Наша цель: помочь вам заниматься регулярно,</strong><br/> чтобы&nbsp;было легко начать и&nbsp;хотелось продолжать,<br/> следить за&nbsp;прогрессом.</p>
                            <p><strong>Выбирайте программы под свои цели,</strong> чтобы<br/>&nbsp;улучшить самочувствие и&nbsp;забыть о&nbsp;возрасте!</p>
                        </div>
                        <div className={styles.gtage_btn} onClick={scroll}>ОФОРМИТЬ ПОДПИСКУ</div>
                    </div>
                    <div className={styles.gtage_block__right}>
                        <div className={styles.gtage_image}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/gymteamsale/gtage_img.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gtage;