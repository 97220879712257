import styles from './plat.module.css';

const Plat = ({version, isColor, isRadius, image}) => {
    return (
        <div className={`${styles.platformgt} ${version == 2 && styles.platform_black} ${version == 3 && styles.platform_gray}` } style={{backgroundColor: isColor && isColor}}>
            <div className="container platform_container">
                <div className={styles.platformgt_block} style={{borderRadius: isRadius && isRadius}}>
                    <div className={styles.platformgt_logo}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_fatburning/platform_logo.svg" alt="" /></div>
                    <div className={styles.platformgt_title}>ТРЕНИРОВКИ ПРОХОДЯТ<br/> НА&nbsp;ПЛАТФОРМЕ GYMTEAM</div>
                    <div className={styles.platformgt_image}>
                        {image ?
                            <>
                                <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/platform.png" alt="" />
                                <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/platform.png" alt="" />
                            </>
                        :
                            <>
                                <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/common/platform_min.png" alt="" />
                                <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/common/platform_min.png" alt="" />
                            </>
                        }
                        
                    </div>
                    <div className={styles.platformgt_subtitle}>Вы сможете настроить тренировочный процесс под&nbsp;себя и&nbsp;заниматься в&nbsp;удовольствие:</div>
                    <div className={styles.platformgt_info}>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>01</div>
                            <p>Скачивать тренировки на&nbsp;телефон, чтобы&nbsp;<strong>заниматься даже&nbsp;без&nbsp;интернета</strong></p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>02</div>
                            <p>Добавлять тренировки в&nbsp;«Избранное» <strong>и&nbsp;возвращаться к&nbsp;понравившимся упражнениям</strong></p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>03</div>
                            <p><strong>Отмечать</strong> пройденные занятия</p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>04</div>
                            <p>Транслировать <strong>видео на&nbsp;TV</strong></p>
                        </div>
                    </div>

                    <div className={styles.t1}>Для тренировок подойдёт любое устройство</div>
                    <div className={styles.b1}>
                        <div className={styles.i1}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/pl_1.svg" alt="" />
                            <p>iPhone, смартфоны<br/> на&nbsp;Android, Huawei устройства</p>
                        </div>
                        <div className={styles.i1}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/pl_2.svg" alt="" />
                            <p>iPad и&nbsp;планшеты</p>
                        </div>
                        <div className={styles.i1}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/pl_3.svg" alt="" />
                            <p>MacBook, ноутбуки,<br/> веб-браузер</p>
                        </div>
                        <div className={styles.i1}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/pl_4.svg" alt="" />
                            <p>Телевизоры<br/> с&nbsp;функцией SmartTV</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plat;