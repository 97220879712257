import { useEffect, useState } from "react";
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import Countdown from 'react-countdown';
import styles from './start2.module.css';

const Start2 = () => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();

    const [date, setDate] = useState(Date.now() + 600000)

    const makeGoal = () => {
        window.yaCounter70253455.reachGoal('onPaymentSelect');
        return true;
    }

    useEffect(() => {
        const onPageLoad = () => {
            setTimeout(() => {
                widget.submit()
            }, 100)
        };
        
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);    

    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                {/* <div>{hourzero}{hours} <span>:</span></div> */}
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };

    return (
        <div className={styles.start}>
            <div className="container">

                <div className={`${styles.block} scrollhere`}>
                    <div className={styles.t1}>Безлимитные<br/> домашние тренировки</div>
                    <div className={styles.info}>
                        <ul>
                            <li>Безлимитные тренировки<br/> экстра-класса</li>
                            <li>Приложение для Android,<br/> iOS и&nbsp;Smart&nbsp;TV</li>
                            <li>Больше 3000 тренировок</li>
                            <li>79&nbsp;программ</li>
                            <li>Новые тренировки каждую<br/> неделю</li>
                            <li>28&nbsp;тренеров</li>
                            <li><b>Персональный план тренировок</b></li>
                        </ul>
                    </div>

                    <div className={styles.countdown}>
                        <p>Скидка <span>60%</span> доступна в&nbsp;течение:</p>
                        <div className={styles.countdown_timer}>
                            <Countdown date={date} renderer={renderer} />
                        </div>
                    </div>

                    <div className={styles.choose}>
                        
                        <div className={`${styles.choose_item1} + ${styles.choose_item} + ${chose == 0 && styles.choose_item_active}`} onClick={()=>{
                            // widget.selectById('950112b9-8f5f-4682-a474-51c1ea7428b4'); 
                            widget.resetOrder();
                            widget.selectByIndex(0);
                            setChose(0)
                            }}>
                            <div className={styles.choose_item_left}>
                                <p>1&nbsp;месяц</p>
                                <p><span>2&nbsp;475&nbsp;₽</span> 990&nbsp;₽</p>
                            </div>
                            <div className={styles.choose_item_right}>
                                <div className={styles.choose_item_right_price}>
                                    <p>82.5&nbsp;₽</p>
                                    <p>33.0&nbsp;₽</p>
                                </div>
                                <p>в&nbsp;день</p>
                            </div>
                        </div>
                        <div className={styles.choose_head}>лучший выбор</div>
                        <div className={`${styles.choose_item2} + ${styles.choose_item} + ${chose == 1 && styles.choose_item_active}`} onClick={()=>{
                            // widget.selectById('eb40634f-e222-456b-bae9-c5a83e7d9b9b'); 
                            widget.resetOrder();
                            widget.selectByIndex(1);
                            setChose(1)
                            }}>
                            <div className={styles.choose_item_left}>
                                <p>3&nbsp;месяца</p>
                                <p><span>6&nbsp;475&nbsp;₽</span> 2&nbsp;590&nbsp;₽</p>
                            </div>
                            <div className={styles.choose_item_right}>
                                <div className={styles.choose_item_right_price}>
                                    <p>71.9&nbsp;₽</p>
                                    <p>28.8&nbsp;₽</p>
                                </div>
                                <p>в&nbsp;день</p>
                            </div>
                        </div>
                        <div className={`${styles.choose_item} + ${chose == 2 && styles.choose_item_active}`} onClick={()=>{
                            // widget.selectById('43ea8159-eccf-4532-99cc-0d75be1fc02e'); 
                            widget.resetOrder();
                            widget.selectByIndex(2);
                            setChose(2)
                            }}>
                            <div className={styles.choose_item_left}>
                                <p>12&nbsp;месяцев</p>
                                <p><span>23&nbsp;700&nbsp;₽</span> 9&nbsp;480&nbsp;₽</p>
                            </div>
                            <div className={styles.choose_item_right}>
                                <div className={styles.choose_item_right_price}>
                                    <p>64.9&nbsp;₽</p>
                                    <p>26.0&nbsp;₽</p>
                                </div>
                                <p>в&nbsp;день</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="doit_start doit2_start">
                        <SalesWidget id={window.lps_settings.forms.doit_2_start} hook={widget} mandatoryFields={'only_phone'} buttonText="ОФОРМИТЬ" onPaymentSelect={makeGoal} />
                    </div>
                </div>

                <div className={styles.block3}>
                    <div className={styles.block3_left}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/gar.png" alt="" />
                    </div>
                    <div className={styles.block3_right}>
                        <p>100% гарантия возврата денег</p>
                        <p>Мы&nbsp;абсолютно уверены в&nbsp;эффективности наших тренировок и&nbsp;гарантируем результат. Если вы&nbsp;не&nbsp;заметите прогресса за&nbsp;30&nbsp;дней, то&nbsp;мы&nbsp;вернем деньги. Нужно выполнить минимум 20&nbsp;тренировок с&nbsp;момента оплаты.</p>
                    </div>
                </div>

                <div className={styles.block2}>
                    <ul>
                        <li><b>Это подписка с&nbsp;автоматическим продлением.</b></li>
                        <li>Специально предложение для новых пользователей действует в&nbsp;течение 7&nbsp;дней с&nbsp;момента оплаты. Вы&nbsp;можете отменить подписку до&nbsp;этой даты без дополнительных затрат.</li>
                        <li>Если вы&nbsp;не&nbsp;отмените подписку, то&nbsp;с&nbsp;вашей карты будет автоматически списываться полная сумма 990&nbsp;₽ каждый месяц до&nbsp;тех пор, пока вы&nbsp;не&nbsp;отмените подписку.</li>
                        <li>Вы&nbsp;можете отменить подписку в&nbsp;любое время через настройки сервиса GymTeam или обратившись в&nbsp;службу поддержки.</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Start2;