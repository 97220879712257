import styles from './footer.module.css';

const Footer = ({version, isColor}) => {
  const year = new Date().getFullYear()
    return (
        <footer className={`${version == 2 || version == 3 || version == 4 || version == 5 || version == 6 ? styles.footer_black : version == 7 ? styles.footer_white : null}`} style={{backgroundColor: isColor && isColor}}>
          <div className="container">
            <div className={styles.footer}>
              <div className={styles.footer_top}>
                <div className={styles.footer_top__left}>
                  {version == 4 || version == 5 || version == 6 ? <div>Поддержка: <a href="mailto:support@gymteam.com" target="_blank" rel='nofollow noreferrer'>supp<div className={styles.email_ch}>!@#$%$^&</div>ort@gym<div className={styles.email_ch}>!@#$%$^&</div>team<div className={styles.email_ch}>!@#$%$^&</div>.com</a></div> : <div>Поддержка: <a href="mailto:support@gymteam.ru" target="_blank" rel='nofollow noreferrer'>supp<div className={styles.email_ch}>!@#$%$^&</div>ort@gym<div className={styles.email_ch}>!@#$%$^&</div>team<div className={styles.email_ch}>!@#$%$^&</div>.ru</a></div> }
                  {/* <a href="https://vk.com/gymteamfit" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon.svg" alt="" /></a> */}
                  <a href="https://vk.com/usmanovateam" target="_blank" rel="noreferrer">
                    {version == 2 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon_red.svg" alt="" /> : version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon_pink.svg" alt="" /> : version == 4 || version == 5 || version == 6 ? null : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/vk.svg" alt="" /> : version == 8 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/vk.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon.svg" alt="" />}
                  </a>
                  <a href="https://t.me/GymTeamSupportBot" target="_blank" rel="noreferrer">
                    {version == 2 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_red.svg" alt="" /> : version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_pink.svg" alt="" /> : version == 4 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_orange.svg" alt="" /> : version == 5 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_pink.svg" alt="" /> : version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_green.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/tg.svg" alt="" /> : version == 8 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/tg.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg" alt="" />}
                  </a>
                  <a href="https://api.whatsapp.com/send?phone=79958871172" target="_blank" rel="noreferrer">
                    {version == 2 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_red.svg" alt="" /> : version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_pink.svg" alt="" /> : version == 4 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_orange.svg" alt="" /> : version == 5 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_pink.svg" alt="" /> : version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_green.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/wa2.svg" alt="" /> : version == 8 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/wa.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg" alt="" />}
                  </a>
                </div>
                <div className={styles.footer_top__right}>
                  <a href="https://apps.apple.com/ru/app/id1533404428" target="_blank" rel="noreferrer">
                    {version == 2 || version == 3 || version == 4 || version == 5 || version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/appstore_icon_white.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/as.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/appstore_icon.svg" alt="" />}
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android&utm_source=gymteam-site&utm_campaign=bange&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1" target="_blank" rel="noreferrer">
                    {version == 2 || version == 3 || version == 4 || version == 5 || version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/play_icon_white.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/pl.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/play_icon.svg" alt="" />}
                  </a>
                  {/* <a href="" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/hw_icon.png" alt="" /></a> */}
                </div>
              </div>
              <div className={styles.footer_bottom}>
                <div className={styles.footer_bottom__left}>
                  <div className={styles.footer_bottom__year}>{version == 4 || version == 5 || version == 6 ? null : 'ООО'} «Онлайн Фитнес», 2020 – {year}.</div>
                  <div className={styles.footer_bottom__policy}>
                    <a href="/l/legal/confidentiality" rel="noreferrer">Политика конфиденциальности</a>
                    <a href="/l/legal/public" rel="noreferrer">Публичная оферта</a>
                    <a href="/l/corporate" rel="noreferrer">Компаниям</a>
                    <a href="https://gymteam.usedocs.com/" target="_blank" rel="noreferrer">FAQ</a>
                  </div>
                </div>
                <div className={styles.footer_other}>
                  <div className={styles.footer_other__icons}>
                    <a href="https://apps.apple.com/ru/app/id1533404428" target="_blank" rel="noreferrer">
                      {version == 2 || version == 3 || version == 4 || version == 5 || version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/appstore_icon2_white.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/as.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/appstore_icon2.svg" alt="" />}
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android&utm_source=gymteam-site&utm_campaign=bange&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pli=1" target="_blank" rel="noreferrer">
                      {version == 2 || version == 3 || version == 4 || version == 5 || version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/play_icon2_white.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/pl.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/play_icon2.svg" alt="" />}
                    </a>
                    {/* <a href="" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/hw_icon.png" alt="" /></a> */}
                  </div>
                  <div className={styles.footer_other__soc}>
                    {/* <a href="https://vk.com/gymteamfit" target="_blank" rel="noreferrer"><img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon.svg" alt="" /></a> */}
                    <a href="https://vk.com/usmanovateam" target="_blank" rel="noreferrer">
                      {version == 2 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon_red.svg" alt="" /> : version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon_pink.svg" alt="" /> : version == 4 || version == 5 || version == 6 ? null : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/vk.svg" alt="" /> : version == 8 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/vk.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/vk_icon.svg" alt="" />}
                    </a>
                    <a href="https://t.me/GymTeamSupportBot" target="_blank" rel="noreferrer">
                      {version == 2 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_red.svg" alt="" /> : version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_pink.svg" alt="" /> : version == 4 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_orange.svg" alt="" /> : version == 5 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_pink.svg" alt="" /> : version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon_green.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/tg.svg" alt="" /> : version == 8 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/tg.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg" alt="" />}
                    </a>
                    <a href="https://api.whatsapp.com/send?phone=79958871172" target="_blank" rel="noreferrer">
                      {version == 2 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_red.svg" alt="" /> : version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_pink.svg" alt="" /> : version == 4 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_orange.svg" alt="" /> : version == 5 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_pink.svg" alt="" /> : version == 6 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon_green.svg" alt="" /> : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/wa2.svg" alt="" /> : version == 8 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/velvet/wa.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg" alt="" />}
                    </a>
                  </div>
                  <div className={styles.footer_other__sk}>
                    <a href="https://sk.ru/" target="_blank" rel="noreferrer">
                      {version == 2 || version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/sk_icon_green.svg" alt="" /> : version == 4 || version == 5 || version == 6 ? null : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/sk.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/sk_icon.svg" alt="" />}
                    </a>
                  </div>
                </div>
                <div className={styles.footer_bottom__sk}>
                  <a href="https://sk.ru/" target="_blank" rel="noreferrer">
                    {version == 2 || version == 3 ? <img src="https://gymteam.kinescopecdn.net/img/l/common/sk_icon_green.svg" alt="" /> : version == 4 || version == 5 || version == 6 ? null : version == 7 ? <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/sk.svg" alt="" /> : <img src="https://gymteam.kinescopecdn.net/img/l/common/sk_icon.svg" alt="" />}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
    );
};

export default Footer;