import { useState } from 'react';
import styles from './startscreen.module.css';

const StartScreen = ({setStartScreen, setActiveScreen}) => {
    const [screen, setScreen] = useState(1)

    return (
        <div className={styles.startscreen}>
            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/startscreen_3.jpg" alt="" />
            <div className={styles.block}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" />
                <div className={styles.t1}>Измени себя за&nbsp;12&nbsp;недель</div>
                <div className={styles.t2}>Получи персональный план тренировок от&nbsp;GymTeam</div>
                <div className={styles.btn} onClick={()=>{setStartScreen(false); setActiveScreen(2)}}>Получить персональный план</div>
            </div> */}
            {screen == 1 ?
            <div className={styles.b1}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/start_1.jpg" alt="" />
                <div className={styles.info}>
                    <div className={styles.t1}>ВЫ&nbsp;СТАВИТЕ ЦЕЛЬ &mdash; МЫ&nbsp;ПОДБИРАЕМ ПРОГРАММУ, КОТОРАЯ ПРИВЕДЁТ ВАС К&nbsp;НЕЙ</div>
                    <div className={styles.t2}>Тренировки <b>под вашу цель</b> с&nbsp;учётом <b>последних научных открытий</b></div>
                    <div className={styles.btn} onClick={()=>{setScreen(2)}}>ДАЛЬШЕ</div>
                </div>
            </div>
            : screen == 2 ?
            <div className={styles.b1}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/start_2.jpg" alt="" />
                <div className={styles.info}>
                    <div className={styles.t1} style={{textAlign: 'center'}}>КАЖДАЯ ТРЕНИРОВКА<br/> БУДЕТ УЧИТЫВАТЬ ВАШИ ОСОБЕННОСТИ</div>
                    <div className={styles.t2} style={{textAlign: 'center'}}>Мы&nbsp;понимаем, что каждый человек уникален, поэтому наш подход основан <b>на&nbsp;учёте ваших целей, физической подготовки, предпочтений и&nbsp;ограничений</b></div>
                    <div className={styles.btn} onClick={()=>{setScreen(3)}}>ДАЛЬШЕ</div>
                </div>
            </div>
            :
            <div className={styles.b1}>
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/start_3.jpg" alt="" />
                <div className={styles.info}>
                    <div className={styles.t1} style={{textAlign: 'center'}}>Узнай, как быстро сможешь изменить себя, тренируясь с&nbsp;приложением GymTeam</div>
                    <div className={styles.t2} style={{textAlign: 'center'}}>Пройди тест и&nbsp;получи <b>персональный план</b> тренировок от&nbsp;GymTeam</div>
                    <div className={styles.btn} onClick={()=>{setStartScreen(false); setActiveScreen(2)}}>ПРОЙТИ ТЕСТ</div>
                </div>
            </div>
            }
            
        </div>
    )
}

export default StartScreen;