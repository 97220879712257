import styles from './gtv_price.module.css';
import './style.css'
import { SubscriptionWidget } from "@com.marathonium/web2-ui";


export const L2023_03_gtv_price = () => {    
    return (
        <div className={styles.gtv_price}>
            <div className={styles.gtv_price_title}>Подписка на GymTeam</div>
            <div className={`${styles.gtv_price_form} gtv_price_form-fixes`}>
                <SubscriptionWidget />
            </div>
        </div>
    );
};