import styles from './last.module.css';

const Last = () => {
    return (
        <div className={styles.last}>
            {/* <div className="container"> */}
            <div className={styles.shape_1}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/shape_3.svg" alt="" /></div>
            <div className={styles.shape_2}><img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/shape_4.svg" alt="" /></div>
            <div className={styles.block}>
                <div className={styles.left}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/doit/last_model.png" alt="" />
                </div>
                <div className={styles.right}>
                    <div className={styles.t1}>Сейчас самый лучший момент начать менять свою жизнь!</div>
                    <div className={styles.st1}>Тебе всегда будет лень идти на&nbsp;тренировку. Но&nbsp;<b>ты&nbsp;никогда не&nbsp;пожалеешь</b>, что потренировалась</div>
                    <a href="/l/price-new/" target='_blank' className={styles.btn} onClick={()=>{window.yaCounter70253455.reachGoal('do-it_5_начинай_тренироваться'); return true;}}>оформить подписку</a>
                    {/* <div className={styles.disc}>Скидка&nbsp;60%</div> */}
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}

export default Last;