import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Unique from './components/Unique';
import Trainings from './components/Trainings';
import Promo2 from './components/Promo2';
import Start from './components/Start';
import Reviews2 from './components/Reviews2';
import Start2 from './components/Start2';
import Faq from './components/Faq';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_07_Doit_start_2 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])
    
    return (
      <>
        <DocumentTitle value=''/>
        <DocumentDescription value='' />
        <div className='doit'>
              <Promo2 />
              <Trainings ver={2} />
              <Reviews2 />
              <Start2 />
              <Faq />
              <Footer version={1} isColor={'#F6F6F6'} />
        </div>
      </>
    );
};