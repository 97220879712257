import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo2 = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promoblock}>
                    <div className={styles.promo_pretitle}>Специальное предложение осени!</div>
                    <div className={styles.promo_title}>Курс для зала</div>
                    <div className={styles.promo_subtitle}>Эффективная программа тренировок<br/> для тренажерного зала от&nbsp;чемпионки<br/> фитнес-бикини Кати Усмановой</div>
                    {/* <div className={styles.promo_subtitle2}>
                        <span>70%</span>
                        <p>Для первых <span>1&nbsp;000</span> купивших действует<br/> наибольшая выгода. Далее цена повысится.</p>
                    </div>                 */}
                    <div className={styles.mob1}>
                        <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_2}>{window.lps_settings.other.count}</div>
                            <div className={styles.promo_remains_1}>осталось мест<br/> по&nbsp;суперцене</div>
                        </div>
                    </div>
                    <div className={styles.btns}>
                        <div className={styles.promo_btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Забрать курс по&nbsp;спеццене</p>
                        </div>
                        {/* <div className={styles.promo_asd}>-65%</div> */}
                        {/* <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_2}>-15%</div>
                            <div className={styles.promo_remains_1}>только до&nbsp;конца дня</div>
                        </div> */}
                        <div className={styles.promo_remains}>
                            <div className={styles.promo_remains_2}>{window.lps_settings.other.count}</div>
                            <div className={styles.promo_remains_1}>осталось мест<br/> по&nbsp;суперцене</div>
                        </div>
                    </div>
                    <div className={styles.st1}>Для первых <span>100</span> купивших<br/> действует наибольшая выгода <span>в&nbsp;20%</span></div>
                    <div className={styles.st2}>Далее цена повысится</div>
                    {/* <div className={styles.promo_countdown}>
                        <p>Специальное предложение сгорит через:</p>
                        <div className={styles.promo_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div> */}
                    {/* <div className={styles.promo_remains}>
                        <div className={styles.promo_remains_1}>Осталось мест<br/> по&nbsp;суперцене:</div>
                        <div className={styles.promo_remains_2}>{window.lps_settings.other.count}</div>
                    </div> */}
                    <div className={styles.promo_imgs}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymoctober/promo.png" alt="" />
                    </div>
                </div>
        </div>
        </div>
    )
}

export default Promo2;