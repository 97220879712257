import { RedirectPrograms } from '../components/redirect/RedirectProgram';
import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import Afterall from './components/Afterall';
import Author from './components/Author';
import Createbody from './components/Createbody';
import Faq from './components/Faq';
import Footer from '../components/Footer';
import Marforme from './components/Marforme';
import Onmarafon from './components/Onmarafon';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Platform from '../components/platform/Platform';
import './style.css';

export const L2022_11_Marafon_str1 = () => {
    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
      <>
      <RedirectPrograms />
      <DocumentTitle value='Стройность 1.0 — марафон Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='Марафон «Стройность 1.0» в GymTeam — программа Екатерины Усмановой на 21 день домашних тренировок. Цель: быстро похудеть, подтянуть проблемные зоны и придать телу рельеф.' />
      
            <div className='marafon_str1'>
                <Promo scroll={scroll} />
                <Createbody />
                <Onmarafon />
                <Whaityou />
                <Platform />
                <Marforme />
                <Studentres scrollStart={scrollStart} />
                <Afterall />
                <Start />
                <Author />
                <Faq scroll={scroll} />
                <Footer />
            </div>
      </>
    );
};