import React, { useEffect } from 'react';
import { AuthWidget } from "@com.marathonium/web2-ui";
import { useDisclosure } from "@chakra-ui/react";

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Unique from './components/Unique';
import Trainings from './components/Trainings';
import Slider1 from './components/Slider1';
import Slider2 from './components/Slider2';
import Reviews from './components/Reviews';
import Subscribe from './components/Subscribe';
import Last from './components/Last';
import Plat from './components/Plat';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_07_Doitreg = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const { isOpen, onClose, onOpen } = useDisclosure();
    
    return (
      <>
        <DocumentTitle value=''/>
        <DocumentDescription value='' />
        <div className='doit'>
              <Promo onOpen={onOpen} />
              <Trainings onOpen={onOpen} />
              <Plat />
              <Unique onOpen={onOpen} />
              <Slider2 />
              <Slider1 />
              <Reviews />
              <Subscribe onOpen={onOpen} />
              <Last onOpen={onOpen} />
              <AuthWidget isOpen={isOpen} onClose={onClose} modal={true} onSuccess={()=>{window.yaCounter70253455.reachGoal('registration'); return true;}} />
              <Footer version={1} isColor={'#F2F2F2'} />
        </div>
      </>
    );
};